<template>
  <div class="content">
    <div class="list">
      <div class="left">
        <div class="title">体重趋势</div>
        <div class="echarts" id="echarts"></div>
      </div>
      <div class="right">
        <div class="title">体重趋势</div>
        <div class="table">
          <!-- <div class="head">
            <div class="flex flex1">测量时间</div>
            <div class="flex">数值(kg)</div>
            <div class="flex">评估</div>
            <div class="flex flex2">操作</div>
          </div>
          <div class="scroll">
            <div v-for="(item, index) in 10" :key="index" class="line">
              <div class="flex flex1">2022/04/06 18:00:00</div>
              <div class="flex">49.2</div>
              <div class="flex green">正常</div>
              <div class="flex flex2">
                <span class="blue">编辑</span>
                <span class="red">删除</span>
              </div>
            </div>
          </div> -->
          <el-table :data="tableData" height="100%" border>
            <el-table-column prop="name" label="测量时间">
            </el-table-column>
            <el-table-column prop="address" label="数值(kg)">
            </el-table-column>
            <el-table-column prop="name" label="评估">
            </el-table-column>
            <el-table-column prop="name" label="操作" width="180">
              <el-button type="primary" size="small">处理</el-button>
              <el-button type="danger" size="small">删除</el-button>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <div class="list">
      <div class="left">
        <div class="title"></div>
        <div class="echarts" id="echarts1"></div>
      </div>
      <div class="right">
        <div class="title">血糖记录</div>
        <div class="table">
          <!-- <div class="head">
            <div class="flex flex1">测量时间</div>
            <div class="flex">数值(kg)</div>
            <div class="flex">评估</div>
            <div class="flex flex2">操作</div>
          </div>
          <div class="scroll">
            <div v-for="(item, index) in 10" :key="index" class="line">
              <div class="flex flex1">2022/04/06 18:00:00</div>
              <div class="flex">49.2</div>
              <div class="flex green" :class="{ red: index == 2, blue: index == 4 }">正常</div>
              <div class="flex flex2">
                <span class="blue">编辑</span>
                <span class="red">删除</span>
              </div>
            </div>
          </div> -->
          <el-table :data="tableData" height="100%" border>
            <el-table-column prop="name" label="测量时间">
            </el-table-column>
            <el-table-column prop="address" label="数值">
            </el-table-column>
            <el-table-column prop="name" label="评估">
            </el-table-column>
            <el-table-column prop="name" label="操作" width="180">
              <el-button type="primary" size="small">处理</el-button>
              <el-button type="danger" size="small">删除</el-button>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <div class="bts">
      <el-button size="small" @click="closePopup">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{}],
      myChart: null,
      myChart1: null
    }
  },
  methods: {
    getresize () {
      this.myChart.resize()
      this.myChart1.resize()
    },
    canvas () {
      var chartDom = document.getElementById('echarts')
      if (!this.myChart) {
        this.myChart = this.$echarts.init(chartDom)
      }
      var option
      option = {
        grid: {
          left: 40,
          right: 30,
          bottom: 30,
          top: 30
        },
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#000',
              fontSize: 15
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '单位:kg',
          nameTextStyle: {
            color: '#000',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#000'
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#000',
              fontSize: 15
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#000'
            }
          }
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            itemStyle: {
              color: '#0077F9' // 改变折线点的颜色
            },
            lineStyle: {
              color: '#0077F9' // 改变折线颜色
            },
            label: {
              show: true,
              textStyle: {
                color: '#000'
              }
            }
          }
        ]
      }

      option && this.myChart.setOption(option)
    },
    canvas1 () {
      var chartDom = document.getElementById('echarts1')
      if (!this.myChart1) {
        this.myChart1 = this.$echarts.init(chartDom)
      }
      var option
      option = {
        grid: {
          left: 40,
          right: 30,
          bottom: 30,
          top: 30
        },
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#000',
              fontSize: 15
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '单位:kg',
          nameTextStyle: {
            color: '#000',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#000',
              fontSize: 15
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          }
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            itemStyle: {
              color: '#fff'// 改变折线点的颜色
            },
            lineStyle: {
              color: '#0077F9' // 改变折线颜色
            },
            label: {
              show: true,
              textStyle: {
                color: '#000'
              }
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
    },
    closePopup () {
      this.$emit('closePopup')
    }
  },
  mounted () {
    this.canvas()
    this.canvas1()
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  }

}
</script>

<style lang="less" scoped>
.content {
  position: relative;
  width: 100%;
  height: calc(100% - 10px);
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #000;
  box-sizing: border-box;

  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 260px;
    margin-bottom: 5px;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 569px;
      height: 260px;

      .echarts {
        width: 569px;
        height: 227px;
        border-right: 1px solid#000;
        box-sizing: border-box;
      }
    }

    .title {
      color: #000;
      font-size: 18px;
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(100% - 669px);
      height: 260px;

      .table {
        overflow: hidden;
        width: 100%;
        height: 236px;

        /deep/.el-table__cell {
          text-align: center;
          color: #000;
        }

        .head {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: 100%;
          height: 34px;
          color: #fff;
          font-size: 16px;
          background-color: #000;
          border-radius: 6px;
        }

        .flex {
          flex: 2;
          text-align: center;
        }

        .flex1 {
          flex: 5;
        }

        .flex2 {
          flex: 3;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }

        .flex3 {
          flex: 2;
        }

        .scroll {
          overflow-y: scroll;
          width: 100%;
          height: calc(100% - 34px);

          &::-webkit-scrollbar {
            display: none
          }
        }

        .line {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          padding: 4px 0;
          color: #000;
          font-size: 16px;

          .green {
            color: #5BE00E;
          }

          .blue {
            color: #378CF2;
            cursor: pointer;
          }

          .red {
            color: #FF0000;
            cursor: pointer;
          }
        }
      }
    }
  }

  .bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  }
}
</style>

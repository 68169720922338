<template>
  <div class="assess">
    <div class="line">
      <div class="left">
        <span class="left-title">您的代谢综合征评估结果为：</span>
        <div class="box">
          <div class="item"></div>
          <div class="item"></div>
          <div class="item"></div>
          <img class="img img2" src="../../img/ssjk/man.png" alt="人物">
        </div>
        <div class="text">
          <span>低位人群</span>
          <span>高危人群</span>
          <span>血脂异常</span>
        </div>
      </div>
      <div class="right">
        <div class="font">国际上对代谢综合征的最新定义：男性腰围≥90cm，女性腰围
          ≥85cm，收缩压≥130mmHg和(或)舒张压≥85mmHg，血清甘
          油三酯≥1.70mmol/L，高密度脂蛋白胆固醇&lt;1.04mmol/L，空
          腹血糖≥6.1mmol/L，5项中具备3项及以上者即为代谢综合征。</div>
        <div class="font">根据您提供的有关信息及临床检查结果，您已达到代谢综合征患
          者的判断标准。</div>
      </div>
    </div>
    <div class="line-title">您的膳食评价结果为：轻度膳食不良</div>
    <div class="line-title">与血脂异常发病相关的危险因素</div>
    <div class="tables">
      <div class="tr">
        <span>分类</span>
        <span>本次</span>
        <span>本次日期</span>
        <span>上次</span>
        <span>上次日期</span>
        <span>变化情况</span>
        <span>参考值</span>
      </div>
      <div class="tr">
        <span>腰围</span>
        <span>38</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>&lt;1.7mmol/L</span>
      </div>
      <div class="tr">
        <span>甘油三脂</span>
        <span>38</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>&lt;1.7mmol/L</span>
      </div>
      <div class="tr">
        <span>高密度脂蛋白胆固醇</span>
        <span>38</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>&lt;1.7mmol/L</span>
      </div>
      <div class="tr">
        <span>收缩压</span>
        <span>38</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>&lt;1.7mmol/L</span>
      </div>
      <div class="tr">
        <span>舒张压</span>
        <span>38</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>&lt;1.7mmol/L</span>
      </div>
      <div class="tr">
        <span>血糖</span>
        <span>38</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
        <span>&lt;1.7mmol/L</span>
      </div>
    </div>
    <div class="line-title1">指导建议</div>
    <div class="div">代谢综合征是指在个体中多种代谢异常情况集结存在的现象， 这些异常包括糖尿病或糖调节受损、高血压、血脂紊乱、全身或腹部肥胖等。 这些代谢异常大多为动脉硬化性心、脑及周围血管病 （简称心血管疾病）的危险因素， 故代谢综合征患者是心血管疾病的高危人群。 根据您当前身体状况与代谢综合征相关的危险因素的评估，近期患代谢综合征的概率相当低，在同龄人中一年内 患代谢综合征的概率低于1.92%，建议你保持当前健康的生活习惯，定期监测身体各项指标。</div>
    <!-- <div class="line-title1">运动建议</div>
    <div class="div">每天坚持30分钟有氧运动</div>
    <div class="line-title1">生活建议</div>
    <div class="div">作息规律，保持积极乐观的心态</div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.assess {
  display: flex;
  flex-direction: column;
  width: 96%;
  margin: 0 auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  div {
    color: #000;
  }

  .line {
    display: flex;
    align-items: center;
    width: 100%;
    height: 352px;
    margin-top: 20px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .left,
    .right {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      position: relative;
      width: 50%;
      height: 340px;

      .left-title {
        position: absolute;
        left: 60px;
        top: 20px;
        color: #000;
        font-size: 18px;
      }

      .box {
        display: flex;
        align-items: flex-end;
        position: relative;
        height: 160px;

        .img {
          position: absolute;
          bottom: -10px;
          left: 75px;
          transform: translateX(-50%);
          width: 60px;
          height: 100px;
        }

        .img1 {
          position: absolute;
          bottom: 40px;
          left: 225px;
        }

        .img2 {
          position: absolute;
          bottom: 90px;
          left: 375px;
        }

        .img3 {
          position: absolute;
          bottom: 140px;
          left: 530px;
        }

        .item {
          width: 150px;
          height: 10px;
          background-color: #00DB00;

          &:nth-child(2) {
            height: 60px;
            background-color: #FFDC35;
          }

          &:nth-child(3) {
            height: 110px;
            background-color: #FF0000;
          }
        }
      }

      .text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 450px;
        margin-top: 7px;

        span {
          flex: 1;
          text-align: center;
          color: #000;
          font-size: 16px;
        }
      }

      .font {
        width: 480px;
        word-break: break-all;
        color: #000;
        font-size: 18px;
        &:nth-child(2) {
          margin-top: 25px;
        }
      }
    }

    .left {
      box-sizing: border-box;
      border-right: 1px solid #000;

    }

    .right {
      padding: 0 19px;
      box-sizing: border-box;
    }
  }

  .line-title {
    display: flex;
    align-items: center;
    margin-top: 28px;
    margin-left: 44px;
    font-size: 18px;
  }

  .tables {
    width: 98%;
    height: 217;
    margin-top: 20px;
    margin-left: 44px;
    border-left: 1px solid #000;
    border-top: 1px solid #000;
    box-sizing: border-box;

    .tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 31px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;
      font-size: 14px;

      span {
        flex: 1;
        height: 31px;
        line-height: 31px;
        text-align: center;
        box-sizing: border-box;
        border-right: 1px solid #000;
      }
    }
  }

  .line-title1 {
    margin-top: 28px;
    margin-left: 54px;
    font-size: 18px;
  }

  .div {
    width: 90%;
    margin: 0 auto;
    padding: 9px 8px;
    border: 1px solid #000;
    font-size: 14px;
  }
}
</style>

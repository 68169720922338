<template>
  <div class="popup">
    <el-form ref="form">
      <div class="tip">编辑签约信息</div>
      <div class="title">居民信息</div>
      <div class="info">
        <div class="item">
          <div class="key">姓名</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div class="key">性别</div>
          <el-select size="small" v-model="region" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="key">出生日期</div>
          <el-date-picker size="small" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="item">
          <div class="key">联系电话</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div class="key">身份证号</div>
          <el-input size="small" class="input"></el-input>
        </div>
        <div class="item item1">
          <div class="key">现居地</div>
          <el-input size="small" class="input1"></el-input>
        </div>
        <div class="item">
          <div class="key">居民标签</div>
          <el-select size="small" v-model="region" placeholder="请选择">
            <el-option label="慢病护理" value="1"></el-option>
            <el-option label="慢病护理" value="2"></el-option>
          </el-select>
        </div>
        <div class="family">
          <div class="news">
            <div class="key">家族变史</div>
            <div class="card">
              <img class="left" src="../../img/ssjk/user.png" alt="头像">
              <div class="right">
                <span>李青</span>
                <span>62岁</span>
                <span>关系</span>
                <span>父母</span>
              </div>
            </div>
            <div class="key"><span class="el-icon-plus"></span><span class="add">关联家庭成员</span></div>
          </div>
        </div>
      </div>

      <div class="title">签约信息</div>
      <div class="info">
        <div class="item">
          <div class="key">签约信息</div>
          <span class="value">88888888</span>
        </div>
        <div class="item">
          <div class="key">签约状态</div>
          <span class="value green">待审核</span>
        </div>
        <div class="item">
          <div class="key">签约机构</div>
          <el-select size="small" v-model="region" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="key">签约团队</div>
          <el-select size="small" v-model="region" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="key">签约医生</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div size="small" class="key">服务包</div>
          <el-select size="small" v-model="region" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="key">签约类型</div>
          <span class="value blue">首次签约</span>
        </div>
        <div class="item">
          <div class="key">签约周期</div>
          <span class="value blue">1年</span>
        </div>
        <div class="item">
          <div class="key">费用</div>
          <div class="money">￥120</div>
        </div>
        <div class="item">
          <div class="key">申请时间</div>
          <span class="value blue">2022/03/22 10:00:00</span>
        </div>
        <div class="item item1">
          <div class="key">生效时间</div>
          <el-date-picker size="small" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>
      <div class="family">
        <div class="news news1">
          <div class="key">签约备注</div>
          <el-input class="textarea" type="textarea" :rows="2">
          </el-input>
        </div>
      </div>
      <div class="bts">
        <!-- <span class="update">保存</span>
        <span @click="closePopup">返回</span> -->
        <el-button type="primary" size="small" @click="closePopup">保存</el-button>
        <el-button size="small" @click="closePopup">返回</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  // name: 'updateSigningPopup',
  data () {
    return {
      region: '',
      lxdh: '',
      type: ['自费'],
      radio: 0
    }
  },
  methods: {
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .tip {
    margin-bottom: 10px;
    color: #000;
    font-size: 20px;
  }

  .title {
    height: 45px;
    line-height: 45px;
    border-top: 1px solid #000;
    box-sizing: border-box;
    color: #000;
    font-size: 18;
  }

  .title1 {
    border: none;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: 600px;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 23%;
      margin-bottom: 20px;
      color: #000;
      font-size: 18px;

      .key {
        width: 77px;
        margin-right: 8px;
        text-align: justify;
        text-align-last: justify;
      }

      .value {
        display: flex;
        align-items: center;
        font-size: 16px;
      }

      .green {
        color: #5BE00E;
      }

      .blue {
        color: #378CF2;
      }

      .key1 {
        width: 90px;
      }

      .key2 {
        width: 100px;
      }

      .key3 {
        width: 120px;
      }

      .img {
        width: 25px;
        height: 25px;
        margin-right: 8px;
      }

      .el-input,
      .el-select {
        width: 151px;
      }

      ::v-deep .el-input__inner::placeholder {
        color: #252523;
      }

      .el-date-picker {
        width: 170px;
      }

      .input {
        width: 211px !important;
      }

      .input1 {
        width: 272px;
      }

      .money {
        color: #FF915C;
        font-size: 16px;
      }
    }

    .item1 {
      width: 48.8%;
    }

    .item2 {
      height: 75px;
    }

    .item3 {
      width: 54%;
    }

    .item4 {
      width: 77%;
    }
  }

  .info1 {
    padding: 20px;
    padding-bottom: 0;
    border-top: 1px solid #000;
  }

  .info2 {
    padding: 20px;
    padding-bottom: 0;
  }

  .family {
    width: 77%;
    height: 90px;

    .title {
      border: none;
    }

    .news {
      display: flex;
      height: 75px;

      .key {
        margin-right: 8px;
        color: #000;
        font-size: 18px;
        text-align: justify;
        text-align-last: justify;

        .add {
          margin-left: 8px;
        }
      }

      .card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 132px;
        height: 75px;
        margin-right: 30px;
        padding: 10px;
        box-sizing: border-box;
        background: linear-gradient(104deg, #0171C4 0%, #024479 100%);
        border-radius: 4px;

        .left {
          width: 25px;
          height: 25px;
        }

        .right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 77px;
          height: 52px;
          color: #fff;
          font-size: 16px;
        }
      }

      .textarea {
        width: 523px;
        height: 66px;
        margin-top: 10px;
        border: 1px solid #000;
        box-sizing: border-box;
      }

      ::v-deep .el-textarea__inner {
        width: 523px;
        height: 66px;
        background-color: transparent;
        border: none;
        padding: 5px;
        box-sizing: border-box;
        color: #fff;
      }
    }

    .news1 {
      flex-direction: column;
      justify-content: center;

      .key {
        width: 77px;
      }
    }
  }

  .el-radio-group {
    margin-left: 10px;
  }

  .bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
  }
}
</style>

<template>
  <div class="write">
    <div class="title">
      <span>目前并发症或合并症情況</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="flex">
        <div class="item">
          <div class="key">药物名称</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div class="key">次/日</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div class="key">用量</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div class="key">单位</div>
          <el-input size="small"></el-input>
        </div>
      </div>
      <div class="btns">
        <el-button size="small">取消</el-button>
        <el-button type="primary" size="small">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: calc(100% - 20px);
    height: 269px;
    margin: 0 auto;
    margin-top: 10px;
    padding: 50px 60px;
    background-color: #fff;
    // background-image: url('../../img/ssjk/writeImg.png');
    // background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 22px;
      right: 30px;
      color: #000;
      font-size: 24px;
      cursor: pointer;
    }

    .flex {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        margin-right: 44px;
        color: #000;
        font-size: 18px;
      }

      .item1 {
        width: 62%;
      }

      .el-input {
        width: 150px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 26px;
      margin: 40px auto 0;
    }
  }
}
</style>

<template>
  <div class="page">
    <div class="view">
      <div class="title">患者信息</div>
      <div class="box">
        <div v-for="(item, index) in info" :key="index" class="flex">
          <div class="key">{{ item.key }}</div>
          <div class="value">{{ item.value }}</div>
        </div>
      </div>
      <div class="title">健康信息</div>
      <div class="info">
        <div class="border">
          <div class="flex width">
            <div class="title">慢性病评估</div>
            <div class="black">健康干预</div>
          </div>
          <div class="flex flex1">
            <div class="title">疾病风险评估</div>
          </div>
          <div class="list">
            <div class="cell">
              <div class="key">高血压风险评估</div>
              <div>2022-07-05</div>
              <div @click="openAssess('xy')" class="black">详情</div>
            </div>
            <div class="cell">
              <div class="key">糖尿病风险评估</div>
              <div>2022-07-05</div>
              <div @click="openAssess('tn')" class="black">详情</div>
            </div>
            <div class="cell">
              <div class="key">血脂异常风险评估</div>
              <div>2022-07-05</div>
              <div @click="openAssess('xz')" class="black">详情</div>
            </div>
          </div>
          <div class="flex flex1">
            <div class="title">疾病管理</div>
          </div>
          <div class="flex flex1 width2">
            <div class="text">高血压</div>
            <div class="red">未进行</div>
            <div class="black black1">高血压评估</div>
          </div>
        </div>
        <div class="border">
          <div class="flex width">
            <div class="title">慢性病评估</div>
            <div class="black">新增评估</div>
          </div>
          <div class="flex flex1">
            <div class="title">健康状态评估</div>
          </div>
          <div class="list list1">
            <div class="cell cell1">
              <div class="key">肥胖症风险评估</div>
              <div class="red">未进行</div>
            </div>
            <div class="cell cell1">
              <div class="key">代谢综合症风险评估</div>
              <div class="red">未进行</div>
            </div>
            <div class="cell cell1">
              <div class="key">生活方式评估</div>
              <div class="red">未进行</div>
            </div>
            <div class="cell cell1">
              <div class="key">睡眠质量评估</div>
              <div class="red">未进行</div>
            </div>
            <div class="cell cell1">
              <div class="key">心理健康评估</div>
              <div class="red">未进行</div>
            </div>
            <div class="cell cell1">
              <div class="key">焦虑评估</div>
              <div class="red">未进行</div>
            </div>
          </div>
        </div>
        <div class="border border1">
          <div class="flex flex3">
            <div class="title">主要健康事件</div>
            <div class="date">
              <span>日期</span>
              <el-date-picker v-model="date" size="mini" type="daterange" range-separator="至" start-placeholder="请选择日期"
                end-placeholder="请选择日期">
              </el-date-picker>
            </div>

          </div>
          <div class="tip">
            <div>事件：创建档案</div>
            <div>发生地：***医院</div>
            <div>处理人：</div>
          </div>
          <div class="icon">
            <div class="date">2022-12-05</div>
            <div class="green"></div>
            <div class="triangle"></div>
          </div>
        </div>
      </div>
      <div class="title">血糖数据</div>
      <div class="news">
        <div class="left">
          <div class="tab">
            <div>血糖趋势</div>
            <div class="btns">
              <div class="btn btn1">餐前</div>
              <div class="btn">餐后</div>
            </div>
          </div>
          <div class="echarts" id="echarts"></div>
        </div>
        <div class="right right1">
          <div class="tab">
            <div>血糖趋势</div>
            <div class="btns">
              <div class="btn btn1">餐前</div>
              <div class="btn">餐后</div>
            </div>
          </div>
          <div class="table table1">
            <!-- <div class="head">
              <div class="flex flex1">测量时间</div>
              <div class="flex">数值（mmol/L)</div>
              <div class="flex flex2">评估</div>
              <div class="flex">操作</div>
            </div>
            <div class="scroll">
              <div class="line">
                <div class="flex flex1">2021/05/09 10:45</div>
                <div class="flex">4.2</div>
                <div class="flex flex2 red">偏高</div>
                <div class="flex flex3">
                  <div class="update">编辑</div>
                  <div class="color">删除</div>
                </div>
              </div>
            </div> -->
            <el-table :data="tableData" height="100%" border>
              <el-table-column prop="date" label="测量时间">
              </el-table-column>
              <el-table-column prop="name" label="数值（mmol/L)">
              </el-table-column>
              <el-table-column prop="address" label="评估">
              </el-table-column>
              <el-table-column label="操作" width="180">
                <el-button type="primary" size="small">编辑</el-button>
                <el-button type="danger" size="small">删除</el-button>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="title">并发症</div>
      <div class="table table2">
        <!-- <div class="head">
          <div class="flex flex1">发生时间</div>
          <div class="flex">疾病名称</div>
          <div class="flex">操作</div>
        </div>
        <div class="scroll">
          <div class="line">
            <div class="flex flex1">2021/05/09 10:45</div>
            <div class="flex">4.2</div>
            <div class="flex flex3">
              <div class="update">编辑</div>
              <div class="color">删除</div>
            </div>
          </div>
          <div class="line line2">暂无消息</div>
        </div> -->
        <el-table :data="tableData" height="100%" border>
          <el-table-column prop="name" label="发生时间">
          </el-table-column>
          <el-table-column prop="address" label="疾病名称">
          </el-table-column>
          <el-table-column label="操作" width="180">
            <el-button type="primary" size="small">编辑</el-button>
            <el-button type="danger" size="small">删除</el-button>
          </el-table-column>
        </el-table>
      </div>
      <div class="title">疾病史</div>
      <div class="table table2">
        <!-- <div class="head">
          <div class="flex flex1">发生时间</div>
          <div class="flex">疾病名称</div>
          <div class="flex">操作</div>
        </div>
        <div class="scroll">
          <div class="line">
          <div class="flex flex1">2021/05/09 10:45</div>
          <div class="flex">4.2</div>
          <div class="flex flex3">
            <div class="update">编辑</div>
            <div class="color">删除</div>
          </div>
        </div>
          <div class="line line2">暂无消息</div>
        </div> -->
        <el-table :data="tableData" height="100%" border>
          <el-table-column prop="name" label="发生时间">
          </el-table-column>
          <el-table-column prop="address" label="疾病名称">
          </el-table-column>
          <el-table-column label="操作" width="180">
            <el-button type="primary" size="small">编辑</el-button>
            <el-button type="danger" size="small">删除</el-button>
          </el-table-column>
        </el-table>
      </div>
      <div class="title">用药记录</div>
      <div class="table table2">
        <!-- <div class="head">
          <div class="flex flex1">科室</div>
          <div class="flex">主治医生</div>
          <div class="flex flex4">给药时间</div>
          <div class="flex flex1">药品类型</div>
          <div class="flex">药品名</div>
        </div>
        <div class="scroll">
          <div class="line line3">
            <div class="flex flex1">内分泌科</div>
            <div class="flex">黄琳</div>
            <div class="flex flex4">2020-04-07 21：00：00</div>
            <div class="flex flex1">口服药</div>
            <div class="flex">格列美脲</div>
          </div>
        </div> -->
        <el-table :data="tableData" height="100%" border>
          <el-table-column prop="name" label="科室">
          </el-table-column>
          <el-table-column prop="address" label="主治医生">
          </el-table-column>
          <el-table-column prop="name" label="给药时间">
          </el-table-column>
          <el-table-column prop="address" label="药品类型">
          </el-table-column>
          <el-table-column prop="name" label="药品名">
          </el-table-column>
        </el-table>
      </div>
      <div class="title">医嘱执行</div>
      <div class="table table2">
        <!-- <div class="head">
          <div class="flex flex4">日期</div>
          <div class="flex flex4">提醒时间</div>
          <div class="flex">执行人</div>
          <div class="flex flex4">给药时间</div>
          <div class="flex flex1">内容</div>
          <div class="flex">状态</div>
          <div class="flex">操作</div>
        </div>
        <div class="scroll">
          <div class="line line3">
            <div class="flex flex4">2020-04-07 21：00：00</div>
            <div class="flex flex4">2020-04-07 21：00：00</div>
            <div class="flex">张三</div>
            <div class="flex flex4">2020-04-07 21：00：00</div>
            <div class="flex flex1">内容</div>
            <div class="flex">未处理</div>
            <div class="flex">处理</div>
          </div>
        </div> -->
        <el-table :data="tableData" height="100%" border>
          <el-table-column prop="name" label="提醒时间">
          </el-table-column>
          <el-table-column prop="address" label="执行人">
          </el-table-column>
          <el-table-column prop="name" label="给药时间">
          </el-table-column>
          <el-table-column prop="address" label="内容">
          </el-table-column>
          <el-table-column prop="name" label="状态">
          </el-table-column>
          <el-table-column prop="name" label="操作">
            <el-button type="primary" size="small">处理</el-button>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="return">
      <el-button type="primary" size="small">返回</el-button>
    </div>
    <!-- 评估弹窗 -->
    <Assess v-if="showAssess" @close="closeAssess"></Assess>
  </div>
</template>

<script>
import Assess from './assessPopup.vue'
export default {
  components: {
    Assess
  },
  data () {
    return {
      tableData: [
        {}
      ],
      info: Object.freeze([
        { key: '身份证', value: '130730199803010816' },
        { key: '姓名', value: '130730199803010816' },
        { key: '手机号', value: '130730199803010816' },
        { key: '性别', value: '女' },
        { key: '身高', value: '164cm' },
        { key: '体重', value: '75kg' },
        { key: 'BMI', value: '14.6' },
        { key: '出生日期', value: '1990-08-03' },
        { key: '现居地', value: '石家庄市桥西区友谊街道256号' },
        { key: '户籍地址', value: '石家庄市桥西区友谊街道256号' },
        { key: '血糖测量次数', value: '2次' },
        { key: '绑定血糖仪', value: '是' },
        { key: '最近测量血糖', value: '' }
      ]),
      date: '',
      myChart: null,
      showAssess: false
    }
  },
  methods: {
    getresize () {
      setTimeout(() => {
        this.myChart.resize()
      }, 100)
    },
    canvas () {
      var chartDom = document.getElementById('echarts')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: 15
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#000'
            }
          }
        },
        yAxis: {
          type: 'value',
          name: 'mmol/L',
          nameTextStyle: {
            color: '#000',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#000'
            }
          },
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: 15
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#000'
            }
          }
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            itemStyle: {
              color: '#000', // 改变折线点的颜色
              lineStyle: {
                color: '#000' // 改变折线颜色
              }
            }
          }
        ]
      }

      option && this.myChart.setOption(option)
    },
    openAssess () {
      this.showAssess = true
    },
    closeAssess () {
      this.showAssess = false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.canvas()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  activated () {
    // console.log('组件激活')
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .view {
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .title {
    color: #000;
    font-size: 20px;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 174px;
    padding-left: 18px;
    box-sizing: border-box;
    margin: 19px 0;

    .flex {
      display: flex;
      align-items: center;
      width: 25%;
      color: #000;
      font-size: 16px;

      .value {
        margin-left: 30px;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    min-width: 850px;
    padding: 10px 0;
    margin-bottom: 20px;

    .border {
      width: 524px;
      height: 280px;
      margin-top: 20px;
      padding: 14px 18px;
      border: 4px solid #000;

      .flex {
        display: flex;
        align-items: center;

        .title {
          color: #000;
          font-size: 16px;
        }

        .black {
          color: #000;
          font-size: 16px;
          cursor: pointer;
        }

        .black1 {
          font-size: 14px;
        }

        .text {
          color: #000;
          font-size: 14px;
        }

        .red {
          margin-left: 50px;
          color: #FF0000;
          font-size: 14px;
        }

        .date {
          color: #000;
          font-size: 16px;

          span {
            margin-right: 10px;
          }
        }
      }

      .flex1 {
        margin-top: 20px;
      }

      .width {
        width: 346px;
        justify-content: space-between;
      }

      .width2 {
        width: 410px;
        justify-content: space-between;
      }

      .list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 80%;
        height: 80px;
        margin-top: 20px;

        .cell {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          color: #000;
          font-size: 14px;

          .black {
            cursor: pointer;
            color: #267DBA;
            font-size: 14px;
          }

          .key {
            // width: 156px;
          }
        }

      }

      .list1 {
        width: 80%;
        height: 160px;

        .cell1 {
          width: 100%;
        }

        .red {
          color: #FF0000;
          font-size: 14px;
        }
      }
    }

    .border1 {
      width: 70%;
      min-width: 640px;
      height: 247px;

      .flex3 {
        justify-content: space-evenly;
        width: 80%;
        height: 30px;
      }

      .tip {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 200px;
        height: 78px;
        padding: 2px 5px;
        margin-top: 4px;
        background-color: #000;
        color: #FFFFFF;
        font-size: 14px;
      }

      .icon {
        position: relative;
        display: flex;
        align-items: center;
        width: 88%;
        height: 59px;
        margin-top: 29px;
        margin-left: 80px;

        .date {
          width: 143px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          color: #333333;
          font-size: 16px;
          font-weight: 600;
          background-color: #FFFFFF;
        }

        .green {
          width: calc(100% - 170px);
          height: 36px;
          background-color: #75AB39;
        }

        .triangle {
          position: absolute;
          right: -29px;
          top: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 29px;
          border-color: transparent transparent #75AB39 transparent;
          transform: rotate(90deg);
        }
      }
    }
  }

  .news {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 99%;
    height: 431px;
    margin-top: 8px;

    .left,
    .right {
      width: 48%;
      height: 100%;
      padding-right: 17px;
      box-sizing: border-box;

      .tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        height: 26px;
        font-size: 14px;

        .btns {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 26px;
          color: #FFFFFF;
          font-size: 14px;

          .btn {
            height: 26px;
            line-height: 26px;
            margin-left: 10px;
            padding: 0 20px;
            text-align: center;
            background-color: #33455C;
            cursor: pointer;
          }

          .btn1 {
            color: #01EEFD;
            background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
          }
        }
      }

      .echarts {
        width: 100%;
        height: 5rem;
      }
    }

    .right1 {
      width: 50%;
      height: 100%;
    }
  }

  .table {
    width: 100%;
    margin-top: 10px;
    // border: #000 4px solid;
    // box-sizing: border-box;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 40px;
      background-color: #000;
      color: #fff;
      font-size: 14px;
    }

    .flex {
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      text-align: center;
    }

    .flex1 {
      width: 142px;
    }

    .flex2 {
      width: 40px;
    }

    .flex3 {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }

    .flex4 {
      width: 220px;
    }

    .scroll {
      width: 100%;
      height: calc(100% - 40px);

      .line {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 60px;
        color: #000;
        font-size: 14px;

        .color {
          color: #FF0000;
          font-size: 14px;
        }

        .update,
        .color {
          cursor: pointer;
        }
      }

      .line2 {
        justify-content: center;
      }

      .line3,
      .line2 {
        color: #000;
      }
    }
  }

  .table1 {
    height: 398px;
  }

  .table2 {
    height: 120px;
    margin-bottom: 20px;
  }

  .return {
    height: 26px;
  }
}

.page&::-webkit-scrollbar {
  display: none;
}
</style>

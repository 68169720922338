<template>
  <div class="popup">
    <el-form ref="form">
      <img class="icon" src="../../img/ssjk/status.png" alt="状态">
      <div class="tip">签约详情</div>
      <div class="title">居民信息</div>
      <div class="info">
        <div class="item">
          <div class="key">姓名</div>
          <span>李明明</span>
        </div>
        <div class="item">
          <div class="key">性别</div>
          <span>男</span>
        </div>
        <div class="item">
          <div class="key">年龄</div>
          <span>40</span>
        </div>
        <div class="item">
          <div class="key">联系电话</div>
          <span>15803339264</span>
        </div>
        <div class="item">
          <div class="key">现居地</div>
          <span>河北石家庄桥西区友谊街道202号</span>
        </div>
        <div class="item">
          <div class="key">居民标签</div>
          <div class="label">
            <span>高血压</span>
            <span>糖尿病</span>
          </div>
        </div>
      </div>
      <div class="family">
        <div class="news">
          <div class="key">家族变史</div>
          <div @click="openResidentDetails" class="card">
            <img class="left" src="../../img/ssjk/user.png" alt="头像">
            <div class="right">
              <span>李青</span>
              <span>62岁</span>
              <span>关系</span>
              <span>父母</span>
            </div>
          </div>
          <div class="key"><span class="el-icon-plus"></span><span class="add">关联家庭成员</span></div>
        </div>
      </div>

      <div class="title">签约信息</div>
      <div class="info">
        <div class="item item1">
          <div class="key">服务编号</div>
          <div class="value">2091900120622</div>
        </div>
        <div class="item item1">
          <div class="key">服务状态</div>
          <div class="value">已取消</div>
        </div>
        <div class="item item1">
          <div class="key">服务机构</div>
          <div class="value">桥西区社区服务中心</div>
        </div>
        <div class="item item1">
          <div class="key">服务团队</div>
          <div class="value">李明明团队</div>
        </div>
        <div class="item item1">
          <div class="key">服务医生</div>
          <div class="value">李明明</div>
        </div>
        <div class="item item1">
          <div class="key">服务包</div>
          <div class="value">老年人服务包</div>
        </div>
        <div class="item item1">
          <div class="key">服务项目</div>
          <div class="value">高血压随访（剩余2次）</div>
        </div>
        <div class="item item1">
          <div class="key">服务来源</div>
          <div class="value">居民申请</div>
        </div>
        <div class="item item1">
          <div class="key">服务地点</div>
          <div class="value">机构门诊</div>
        </div>
        <div class="item item1">
          <div class="key">预约时间</div>
          <div class="value purple">2022/03/22 10:00:00</div>
        </div>
        <div class="item item1">
          <div class="key">提交时间</div>
          <div class="value purple">2022/03/22 10:00:00</div>
        </div>
        <div class="item item1">
          <div class="key">审核时间</div>
          <div class="value purple">2022/03/22 10:00:00</div>
        </div>
        <div class="item item1">
          <div class="key">审核人</div>
          <div class="value">李明明</div>
        </div>
        <div class="item item1">
          <div class="key">取消时间</div>
          <div class="value purple">2022/03/22 10:00:00</div>
        </div>
        <div class="item item1">
          <div class="key">取消原因</div>
          <div class="value">设备微信，本周暂停服务</div>
        </div>
        <div class="item item1">
          <div class="key">服务备注</div>
          <div class="value">上次的药已经吃完了！</div>
        </div>
      </div>
      <div class="bts">
        <!-- <span @click="openUpdateSigning" class="update">续约</span>
        <span @click="closePopup">返回</span> -->
        <el-button type="primary" size="small" @click="openUpdateSigning">续约</el-button>
        <el-button size="small" @click="closePopup">返回</el-button>
      </div>
    </el-form>
    <ResidentDetails @closePopup="closeResidentDetails" v-if="showResidentDetails"></ResidentDetails>
    <UpdateSigning v-if="showUpdateSigning" @closePopup="closeUpdateSigning"></UpdateSigning>
  </div>
</template>

<script>
import ResidentDetails from './residentDetailsPopup.vue'
import UpdateSigning from './updateSigningPopup'
export default {
  components: {
    ResidentDetails,
    UpdateSigning
  },
  data () {
    return {
      region: '',
      showResidentDetails: false,
      showUpdateSigning: false
    }
  },
  methods: {
    openInfo () {
      this.$emit('openResidentDetails')
    },
    closePopup () {
      this.$emit('closePopup')
    },
    openResidentDetails () {
      this.showResidentDetails = true
    },
    closeResidentDetails () {
      this.showResidentDetails = false
    },
    openUpdateSigning () {
      this.showUpdateSigning = true
    },
    closeUpdateSigning () {
      this.showUpdateSigning = false
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .icon {
    position: absolute;
    top: 10px;
    left: 140px;
    width: 82px;
    height: 66px;
  }

  .tip {
    margin-bottom: 10px;
    color: #000;
    font-size: 20px;
  }

  .title {
    height: 45px;
    line-height: 45px;
    border-top: 1px solid #000;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 33%;
      margin-bottom: 20px;
      color: #000;
      font-size: 16px;

      .key {
        width: 76px;
        margin-right: 8px;
        font-size: 18px;
        text-align: justify;
        text-align-last: justify;
      }

      .label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 116px;
        height: 22px;

        span {
          width: 51px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          color: #fff;
          font-size: 16px;
          background-color: #0170C2;
        }
      }
    }

    .item1 {
      width: 24%;
    }
  }

  .family {
    height: 95px;
    padding: 0 20px;
    box-sizing: border-box;

    .title {
      border: none;
    }

    .news {
      display: flex;
      height: 75px;

      .key {
        margin-right: 30px;
        color: #000;
        font-size: 16px;
        text-align: justify;
        text-align-last: justify;

        .add {
          margin-left: 10px;
        }
      }

      .card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 132px;
        height: 75px;
        margin-right: 30px;
        padding: 10px;
        box-sizing: border-box;
        background: linear-gradient(104deg, #0171C4 0%, #024479 100%);
        border-radius: 4px;

        .left {
          width: 25px;
          height: 25px;
        }

        .right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 77px;
          height: 52px;
          color: #fff;
          font-size: 16px;
        }
      }

      .textarea {
        width: 523px;
        height: 66px;
        margin-top: 10px;
        border: 2px solid #000;
        box-sizing: border-box;
      }

      ::v-deep .el-textarea__inner {
        width: 523px;
        height: 66px;
        background-color: transparent;
        border: none;
        padding: 5px;
        box-sizing: border-box;
        color: #fff;
      }
    }

    .news1 {
      flex-direction: column;
      justify-content: center;

      .key {
        width: 72px;
      }
    }
  }

  .bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
  }
}
</style>

<template>
  <div class="page">
    <el-button type="primary" size="small" @click="openAddAssess">新增</el-button>
    <div class="box">
      <el-table :data="tableData" height="200" border>
        <el-table-column prop="text" label="评估名称">
        </el-table-column>
        <el-table-column prop="name" label="评估时间">
        </el-table-column>
        <el-table-column prop="address" label="操作" width="240">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="openAssess(scope.row.index)">查看</el-button>
            <el-button type="primary" size="small" @click="openAddAssess(scope.row.index)">编辑</el-button>
            <el-button type="danger" size="small">删除</el-button>
            </template>
        </el-table-column>
      </el-table>
      <!-- <div class="line line1">
        <div class="item">评估名称</div>
        <div class="item">评估时间</div>
        <div class="item">操作</div>
      </div>
      <div class="line line2">
        <div class="item">高血压评估风险</div>
        <div class="item">2022-03-03  12：00：00</div>
        <div class="item item1">
          <div @click="openAssess(0)" class="btn">查看</div>
          <div @click="openAddAssess(0)" class="btn btn1">编辑</div>
          <div class="btn red">删除</div>
        </div>
      </div>
      <div class="line line2">
        <div class="item">糖尿病风险评估</div>
        <div class="item">2022-03-03  12：00：00</div>
        <div class="item item1">
          <div @click="openAssess(1)" class="btn">查看</div>
          <div @click="openAddAssess(1)" class="btn btn1">编辑</div>
          <div class="btn red">删除</div>
        </div>
      </div>
      <div class="line line2">
        <div class="item">肥胖症评估</div>
        <div class="item">2022-03-03  12：00：00</div>
        <div class="item item1">
          <div @click="openAssess(2)" class="btn">查看</div>
          <div @click="openAddAssess(2)" class="btn btn1">编辑</div>
          <div class="btn red">删除</div>
        </div>
      </div>
      <div class="line line2">
        <div class="item">血脂异常风险评估</div>
        <div class="item">2022-03-03  12：00：00</div>
        <div class="item item1">
          <div @click="openAssess(3)" class="btn">查看</div>
          <div @click="openAddAssess(3)" class="btn btn1">编辑</div>
          <div class="btn red">删除</div>
        </div>
      </div>
      <div class="line line2">
        <div class="item">代谢综合征风险评估</div>
        <div class="item">2022-03-03  12：00：00</div>
        <div class="item item1">
          <div @click="openAssess(4)" class="btn">查看</div>
          <div @click="openAddAssess(4)" class="btn btn1">编辑</div>
          <div class="btn red">删除</div>
        </div>
      </div>
      <div class="line line2">
        <div class="item">自我评估</div>
        <div class="item">2022-03-03  12：00：00</div>
        <div class="item item1">
          <div @click="openAssess(5)" class="btn">查看</div>
          <div @click="openAddAssess(5)" class="btn btn1">编辑</div>
          <div class="btn red">删除</div>
        </div>
      </div> -->
    </div>
    <el-button size="small" class="return">返回</el-button>
    <!-- 评估弹窗 -->
    <Assess v-if="showAssess" @close="closeAssess" :showIndex="tabIndex"></Assess>
    <!-- 新增 编辑 -->
    <AddAssess v-if="showAddAssess" @close="closeAddAssess"></AddAssess>
  </div>
</template>

<script>
import Assess from '../basicInfo/assessPopup.vue'
import AddAssess from './addAssess.vue'
export default {
  components: {
    Assess,
    AddAssess
  },
  data () {
    return {
      tabIndex: 0,
      tableData: [
        { text: '高血压评估风险', index: 0 },
        { text: '糖尿病风险评估', index: 1 },
        { text: '肥胖症评估', index: 2 },
        { text: '血脂异常风险评估', index: 3 },
        { text: '代谢综合征风险评估', index: 4 },
        { text: '自我评估', index: 5 }
      ],
      showAssess: false,
      showAddAssess: false
    }
  },
  methods: {
    openAssess (index) {
      this.tabIndex = index
      this.showAssess = true
    },
    closeAssess () {
      this.showAssess = false
    },
    openAddAssess () {
      this.showAddAssess = true
    },
    closeAddAssess () {
      this.showAddAssess = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #000;
      font-size: 14px;

      .item {
        flex: 1;
        text-align: center;
      }

      .item1 {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          cursor: pointer;
          color: #01EEFD;
          font-size: 14px;
        }

        .btn1 {
          margin-left: 30px;
          margin-right: 30px;
        }

        .red {
          color: #FF0000;
        }
      }
    }

    .line1 {
      height: 40px;
      line-height: 40px;
      background-color: #024276;
      color: #fff;
    }

    .line2 {
      margin-top: 10px;
    }
  }

  .return {
    position: absolute;
    bottom: 30px;
  }
}
</style>

<template>
  <div class="page">
    <div class="box">
      <div @click="switchTab(index)" class="item" :class="{ itme1: index == tabIndex }" v-for="(item, index) in list" :key="index">{{ item }}</div>
    </div>
    <div class="bts">
      <!-- <span class="update">返回</span> -->
      <el-button size="small">返回</el-button>
    </div>
    <!-- 血压 -->
    <XyMonitor v-if="showXyMonitor" @close="closeXyMonitor"></XyMonitor>
    <!-- 血糖 -->
    <XtMonitor v-if="showXtMonitor" @close="closeXtMonitor"></XtMonitor>
    <!-- 血氧 -->
    <XyangMonitor v-if="showXyangMonitor" @close="closeXyangMonitor"></XyangMonitor>
    <!-- 心率 -->
    <XlMonitor v-if="showXlMonitor" @close="closeXlMonitor"></XlMonitor>
    <!-- 手表运动 -->
    <YdMonitor v-if="showYdMonitor" @close="closeYdMonitor"></YdMonitor>
    <!-- 体重 -->
    <TzMonitor v-if="showTzMonitor" @close="closeTzMonitor"></TzMonitor>
    <!-- 体温 -->
    <TwMonitor v-if="showTwMonitor" @close="closeTwMonitor"></TwMonitor>
    <!-- 身高 -->
    <SgMonitor v-if="showSgMonitor" @close="closeSgMonitor"></SgMonitor>
    <!-- 血脂 -->
    <XzMonitor v-if="showXzMonitor" @close="closeXzMonitor"></XzMonitor>
    <!-- 血尿 -->
    <XnMonitor v-if="showXnMonitor" @close="closeXnMonitor"></XnMonitor>
    <!-- 生化 -->
    <ShMonitor v-if="showShMonitor" @close="closeShMonitor"></ShMonitor>
    <!-- 糖化血红蛋白 -->
    <ThMonitor v-if="showThMonitor" @close="closeThMonitor"></ThMonitor>
    <!-- 血常规 -->
    <XcMonitor v-if="showXcMonitor" @close="closeXcMonitor"></XcMonitor>
    <!-- 人体成分 -->
    <RtMonitor v-if="showRtMonitor" @close="closeRtMonitor"></RtMonitor>
    <!-- 尿液分析 -->
    <NyMonitor v-if="showNyMonitor" @close="closeNyMonitor"></NyMonitor>
    <!-- 精神压力分析 -->
    <JsMonitor v-if="showJsMonitor" @close="closeJsMonitor"></JsMonitor>
    <!-- 动脉硬化 -->
    <DmMonitor v-if="showDmMonitor" @close="closeDmMonitor"></DmMonitor>
    <!-- 骨密度分析 -->
    <GmMonitor v-if="showGmMonitor" @close="closeGmMonitor"></GmMonitor>
    <!-- 心血管分析 -->
    <XxMonitor v-if="showXxMonitor" @close="closeXxMonitor"></XxMonitor>
    <!-- 心电 -->
    <XdMonitor v-if="showXdMonitor" @close="closeXdMonitor"></XdMonitor>
    <!-- 胎心 -->
    <TxMonitor v-if="showTxMonitor" @close="closeTxMonitor"></TxMonitor>
    <!-- 中医体质 -->
    <ZyMonitor v-if="showZyMonitor" @close="closeZyMonitor"></ZyMonitor>
  </div>
</template>

<script>
import XyMonitor from './xyMonitor.vue'
import XtMonitor from './xtMonitor.vue'
import XyangMonitor from './xyaMonitor.vue'
import XlMonitor from './xlMonitor.vue'
import YdMonitor from './ydMonitor.vue'
import TzMonitor from './tzMonitor.vue'
import TwMonitor from './twMonitor.vue'
import SgMonitor from './sgMonitor.vue'
import XzMonitor from './xuezhiMonitor.vue'
import XnMonitor from './xnMonitor.vue'
import ShMonitor from './shMonitor.vue'
import ThMonitor from './thMonitor.vue'
import XcMonitor from './xcMonitor.vue'
import RtMonitor from './rtMonitor.vue'
import NyMonitor from './nyMonitor.vue'
import JsMonitor from './jsMonitor.vue'
import DmMonitor from './dmMonitor.vue'
import GmMonitor from './gmMonitor.vue'
import XxMonitor from './xxMonitor.vue'
import XdMonitor from './xdMonitor.vue'
import TxMonitor from './txMonitor.vue'
import ZyMonitor from './zyMonitor.vue'
export default {
  components: {
    XyMonitor,
    XtMonitor,
    XyangMonitor,
    XlMonitor,
    YdMonitor,
    TzMonitor,
    TwMonitor,
    SgMonitor,
    XzMonitor,
    XnMonitor,
    ShMonitor,
    ThMonitor,
    XcMonitor,
    RtMonitor,
    NyMonitor,
    JsMonitor,
    DmMonitor,
    GmMonitor,
    XxMonitor,
    XdMonitor,
    TxMonitor,
    ZyMonitor
  },
  data () {
    return {
      tabIndex: 0,
      list: Object.freeze(['血压', '血糖', '血氧', '心率', '手表运动', '体重', '体温', '身高', '血脂', '血尿酸', '血生化', '糖化血红蛋白', '血常规', '人体成分', '尿液分析', '精神压力分析', '动脉硬化分析', '骨密度分析', '心血管分析', '心电', '胎心', '中医体质']),
      showXyMonitor: false,
      showXtMonitor: false,
      showXyangMonitor: false,
      showXlMonitor: false,
      showYdMonitor: false,
      showTzMonitor: false,
      showTwMonitor: false,
      showSgMonitor: false,
      showXzMonitor: false,
      showXnMonitor: false,
      showShMonitor: false,
      showThMonitor: false,
      showXcMonitor: false,
      showRtMonitor: false,
      showNyMonitor: false,
      showJsMonitor: false,
      showDmMonitor: false,
      showGmMonitor: false,
      showXxMonitor: false,
      showXdMonitor: false,
      showTxMonitor: false,
      showZyMonitor: false
    }
  },
  methods: {
    switchTab (index) {
      this.tabIndex = index
      const list = ['showXyMonitor', 'showXtMonitor', 'showXyangMonitor', 'showXlMonitor', 'showYdMonitor', 'showTzMonitor', 'showTwMonitor', 'showSgMonitor', 'showXzMonitor', 'showXnMonitor', 'showShMonitor', 'showThMonitor', 'showXcMonitor', 'showRtMonitor', 'showNyMonitor', 'showJsMonitor', 'showDmMonitor', 'showGmMonitor', 'showXxMonitor', 'showXdMonitor', 'showTxMonitor', 'showZyMonitor']
      this.$data[list[index]] = true
    },
    closeXyMonitor () {
      this.showXyMonitor = false
    },
    closeXtMonitor () {
      this.showXtMonitor = false
    },
    closeXyangMonitor () {
      this.showXyangMonitor = false
    },
    closeXlMonitor () {
      this.showXlMonitor = false
    },
    closeYdMonitor () {
      this.showYdMonitor = false
    },
    closeTzMonitor () {
      this.showTzMonitor = false
    },
    closeTwMonitor () {
      this.showTwMonitor = false
    },
    closeSgMonitor () {
      this.showSgMonitor = false
    },
    closeXzMonitor () {
      this.showXzMonitor = false
    },
    closeXnMonitor () {
      this.showXnMonitor = false
    },
    closeShMonitor () {
      this.showShMonitor = false
    },
    closeThMonitor () {
      this.showThMonitor = false
    },
    closeXcMonitor () {
      this.showXcMonitor = false
    },
    closeRtMonitor () {
      this.showRtMonitor = false
    },
    closeNyMonitor () {
      this.showNyMonitor = false
    },
    closeJsMonitor () {
      this.showJsMonitor = false
    },
    closeDmMonitor () {
      this.showDmMonitor = false
    },
    closeGmMonitor () {
      this.showGmMonitor = false
    },
    closeXxMonitor () {
      this.showXxMonitor = false
    },
    closeXdMonitor () {
      this.showXdMonitor = false
    },
    closeTxMonitor () {
      this.showTxMonitor = false
    },
    closeZyMonitor () {
      this.showZyMonitor = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: calc(100% - 30px);

  .box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1120px;
    height: 510px;
    margin: 20px auto 0;
    padding: 0 30px;
    box-sizing: border-box;

    .item {
      width: 220px;
      height: 60px;
      margin: 0 20px;
      line-height: 60px;
      text-align: center;
      color: #Fff;
      font-size: 20px;
      background-image: url('../../img/ssjk/item.png');
      background-size: 100% 100%;
      cursor: pointer;
    }

    .itme1 {
      background-image: url('../../img/ssjk/itemSelect.png');
    }
  }

  .bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    height: 40px;
    margin-left: 18px;
  }
}
</style>

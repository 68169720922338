<template>
  <div class="monitoring">
    <div @click="close" class="close">
      <span class="el-icon-close"></span>
    </div>
    <div class="monitor-top">
      <div class="left">
        <div class="title">您的人体成分数据结果为：</div>
        <div class="title title1">本次测量结果</div>
        <div class="title title3">
          <span>体重：kg</span>
          <span>脂肪：%</span>
          <span>骨量：kg</span>
          <span>肌肉：kg</span>
          <span>内脏脂肪：%</span>
          <span>BMI：</span>
          <span>体重：kg</span>
          <span>体重：kg</span>
          <span>体重：kg</span>
        </div>
        <div class="title title2">评估建议</div>
        <!-- <div class="title">您本次测量的身高正常。请注意身体健康</div> -->
      </div>
    </div>
    <div class="news">
      <div class="news-right right1">
        <div class="tab">
          <div>人体成分记录</div>
          <!-- <div class="btns">
            <div class="btn">输入血常规</div>
            <div class="btn">查询</div>
          </div> -->
        </div>
        <div class="table">
          <!-- <div class="head">
            <div class="flex flex1">测量时间</div>
            <div class="flex flex6">体重(kg)</div>
            <div class="flex flex6">脂肪(%)</div>
            <div class="flex flex6">骨量(kg)</div>
            <div class="flex flex6">肌肉(kg)</div>
            <div class="flex flex6">内脏脂肪(%)</div>
            <div class="flex flex6">BMI</div>
            <div class="flex flex6">水分(%)</div>
            <div class="flex flex6">热量(千焦)</div>
            <div class="flex flex2">操作</div>
          </div>
          <div class="scroll">
            <div class="line">
              <div class="flex flex1">2021/05/09 10:45</div>
              <div class="flex flex6">体重(kg)</div>
              <div class="flex flex6">脂肪(%)</div>
              <div class="flex flex6">骨量(kg)</div>
              <div class="flex flex6">肌肉(kg)</div>
              <div class="flex flex6">内脏脂肪(%)</div>
              <div class="flex flex6">BMI</div>
              <div class="flex flex6">水分(%)</div>
              <div class="flex flex6">热量(千焦)</div>
              <div class="flex flex2">
                <div class="color">删除</div>
              </div>
            </div>
          </div> -->

          <el-table :data="tableData" height="100%" border>
            <el-table-column prop="date" label="测量时间">
            </el-table-column>
            <el-table-column prop="name" label="体重(kg)">
            </el-table-column>
            <el-table-column prop="name" label="脂肪(%)">
            </el-table-column>
            <el-table-column prop="name" label="骨量(kg)">
            </el-table-column>
            <el-table-column prop="name" label="肌肉(kg)">
            </el-table-column>
            <el-table-column prop="name" label="内脏脂肪(%)">
            </el-table-column>
            <el-table-column prop="name" label="BMI">
            </el-table-column>
            <el-table-column prop="name" label="水分(%)">
            </el-table-column>
            <el-table-column prop="name" label="热量(千焦)">
            </el-table-column>
            <el-table-column prop="address" label="操作" width="120">
              <el-button type="danger" size="small">删除</el-button>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 血常规输入 -->
    <WriteXc v-if="showWriteXc" @closePopup="closeWrite"></WriteXc>
  </div>
</template>

<script>
import WriteXc from './writeXc.vue'
export default {
  components: {
    WriteXc
  },
  data () {
    return {
      myChart: null,
      data: '',
      tableData: [{}],
      showWriteXc: false
    }
  },
  mounted () {
    // this.canvas()
  },
  methods: {
    openWrite () {
      this.showWriteXc = true
    },
    closeWrite () {
      this.showWriteXc = false
    },
    close () {
      this.$emit('close')
    },
    canvas () {
      // console.log('--------------------')
      const chartDom = document.getElementById('echarts')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#000',
              fontSize: 15
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '单位：cm',
          nameTextStyle: {
            color: '#000',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#000',
              fontSize: 15
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          }
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            itemStyle: {
              normal: {
                color: '#88F8FF', // 改变折线点的颜色
                lineStyle: {
                  color: '#000' // 改变折线颜色
                }
              }
            },
            label: {
              show: true,
              textStyle: {
                color: '#000'
              }
            }
          }
        ]
      }

      option && this.myChart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.monitoring {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #000;
  box-sizing: border-box;

  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #000;
    font-size: 20px;
    cursor: pointer;
  }

  .monitor-top {
    display: flex;
    align-items: center;
    width: 100%;
    height: 238px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .left {
      width: 100%;
      height: 238px;
      padding: 34px;
      box-sizing: border-box;
      box-sizing: border-box;
      color: #000;
      font-size: 18px;

      .title1 {
        margin-top: 16px;
      }

      .title2 {
        margin-top: 34px;
      }

      .title3 {
        width: 100%;
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .news {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 99%;
    height: calc(100% - 340px);
    margin: 0 auto;
    margin-top: 20px;

    .news-left,
    .news-right {
      width: 843px;
      height: 100%;
      padding-right: 17px;
      box-sizing: border-box;

      .echarts {
        width: 100%;
        height: 100%;
      }
    }

    .news-right {
      width: 100%;
    }

    .tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000;
      height: 26px;
      margin-top: 10px;
      font-size: 18px;

      .btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 222px;
        height: 26px;
        color: #FFFFFF;
        font-size: 14px;

        .btn {
          cursor: pointer;
          height: 26px;
          line-height: 26px;
          padding: 0 7px;
          text-align: center;
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }

    .table {
      width: 100%;
      height: 100%;
      margin-top: 10px;

      /deep/.el-table__cell {
        text-align: center;
        color: #000;
      }

      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        background-color: #000;
        color: #fff;
        font-size: 14px;
      }

      .flex {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 120px;
        height: 40px;
        line-height: 40px;
        text-align: center;
      }

      .flex1 {
        width: 142px;
      }

      .flex2 {
        width: 40px;
      }

      .flex3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .flex4 {
        width: 220px;
      }

      .flex5 {
        width: 68px;
      }

      .flex6 {
        width: 110px;
      }

      .scroll {
        width: 100%;
        height: calc(100% - 40px);

        .line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          color: #000;
          font-size: 14px;

          .color {
            color: #FF0000;
            font-size: 14px;
            cursor: pointer;
          }

          .update,
          .color {
            width: 40px;
          }
        }

        .line2 {
          justify-content: center;
        }

        .line3,
        .line2 {
          color: #fff;
        }
      }
    }
  }
}
</style>

<template>
  <div class="monitoring">
    <div @click="close" class="close">
      <span class="el-icon-close"></span>
    </div>
    <div class="monitor-top">
      <div class="left">
        <div class="title">您的血压评估结果为：</div>
        <div class="title title1">本次测量结果</div>
        <div class="title">收缩压：120 mmHg</div>
        <div class="title">舒张压：80 mmHg</div>
        <div class="title">心率：105 bpm</div>
        <div class="title">测量时间：2021-06-29 08:29:26</div>
        <div class="title title2">评估建议</div>
        <div class="title">您本次测量的血压正常。请注意身体健康</div>
      </div>
      <div class="right">
        <div class="title">信息图</div>
        <div class="unit">收缩压(mmHg)</div>
        <div class="chart">
          <img class="img" src="../../img/ssjk/chart.png" alt="图表">
          <div class="tip">
            <div class="line">
              <div class="div"></div>
              <span class="font">三级高血压</span>
            </div>
            <div class="line">
              <div class="div font1"></div>
              <span class="font">二级高血压</span>
            </div>
            <div class="line">
              <div class="div font2"></div>
              <span class="font">一级高血压</span>
            </div>
            <div class="line">
              <div class="div font3"></div>
              <span class="font">正常高值</span>
            </div>
            <div class="line">
              <div class="div font4"></div>
              <span class="font">理想血压</span>
            </div>
            <div class="line">
              <div class="div font5"></div>
              <span class="font">低血压</span>
            </div>
            <div class="line">
              <div class="div font6"></div>
              <span class="font">单纯收缩期高血压</span>
            </div>
          </div>
        </div>
        <div class="bottom">
          <span>60</span>
          <span>80</span>
          <span>90</span>
          <span>100</span>
          <span class="span">舒张压（mmHg）</span>
        </div>
      </div>
    </div>
    <div class="news">
      <div class="news-left">
        <div class="tab">
          <span>血压趋势</span>
        </div>
        <div class="echarts" id="echarts"></div>
      </div>
      <div class="news-right right1">
        <div class="tab">
          <div>血压记录</div>
          <div class="btns">
            <el-button type="primary" size="small" @click="openWritePopup">输入血压</el-button>
            <el-button type="primary" size="small" @click="openQueryXy">查询</el-button>
            <!-- <div @click="openWritePopup" class="btn">输入血压</div>
            <div @click="openQueryXy" class="btn">查询</div> -->
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" height="100%" border>
            <el-table-column prop="date" label="测量时间">
            </el-table-column>
            <el-table-column prop="name" label="数值（mmol/L)">
            </el-table-column>
            <el-table-column prop="address" label="评估">
            </el-table-column>
            <el-table-column prop="address" label="数据来源">
            </el-table-column>
            <el-table-column prop="address" label="操作">
              <el-button type="danger" size="small">删除</el-button>
            </el-table-column>
          </el-table>
          <!-- <div class="head">
            <div class="flex flex1">测量时间</div>
            <div class="flex">数值（mmol/L)</div>
            <div class="flex flex2">评估</div>
            <div class="flex">数据来源</div>
            <div class="flex flex2">操作</div>
          </div>
          <div class="scroll">
            <div class="line">
              <div class="flex flex1">2021/05/09 10:45</div>
              <div class="flex">4.2</div>
              <div class="flex flex2 red">偏高</div>
              <div class="flex">电子血压计</div>
              <div class="flex flex2">
                <div class="color">删除</div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 血压输入 -->
    <WriteXy v-if="showWritePopup" @closePopup="closeWritePopup"></WriteXy>
    <!-- 血压查询 -->
    <QueryXy v-if="showQueryXy" @closePopup="closeQueryXy"></QueryXy>
  </div>
</template>

<script>
import WriteXy from './writeXy.vue'
import QueryXy from './queryXy.vue'
export default {
  components: {
    WriteXy,
    QueryXy
  },
  data () {
    return {
      myChart: null,
      data: '',
      tableData: [{}],
      showWritePopup: false,
      showQueryXy: false
    }
  },
  mounted () {
    this.canvas()
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      this.myChart.resize()
    },
    openWritePopup () {
      this.showWritePopup = true
    },
    closeWritePopup () {
      this.showWritePopup = false
    },
    openQueryXy () {
      this.showQueryXy = true
    },
    closeQueryXy () {
      this.showQueryXy = false
    },
    close () {
      this.$emit('close')
    },
    canvas () {
      const chartDom = document.getElementById('echarts')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#000',
              fontSize: 15
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#000'
            }
          }
        },
        yAxis: {
          type: 'value',
          name: 'mmol/L',
          nameTextStyle: {
            color: '#000',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#000'
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#000',
              fontSize: 15
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#000'
            }
          }
        },
        legend: {
          icon: 'circle',
          align: 'right',
          data: ['收缩压', '舒张压'],
          textStyle: {
            color: '#000'
          },
          left: 1
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            name: '收缩压',
            type: 'line',
            stack: 'Total',
            symbol: 'circle',
            itemStyle: {
              color: '#88F8FF' // 改变折线点的颜色
            },
            lineStyle: {
              color: '#88F8FF' // 改变折线颜色
            },
            symbolSize: 8,
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: '舒张压',
            type: 'line',
            stack: 'Total',
            symbol: 'circle',
            itemStyle: {
              color: '#FF6227' // 改变折线点的颜色
            },
            lineStyle: {
              color: '#FF6227' // 改变折线颜色
            },
            // lineStyle: {
            //   normal: {
            //     color: '#FF6227', // 折线的颜色
            //     width: '2'// 折线粗细
            //   }
            // },
            symbolSize: 8,
            data: [120, 132, 101, 134, 90, 230, 210]
          }
        ]
      }

      option && this.myChart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.monitoring {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 10px 20px;
  border: 1px solid #000;
  box-sizing: border-box;

  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #000;
    font-size: 20px;
    cursor: pointer;
  }

  .monitor-top {
    display: flex;
    align-items: center;
    width: 100%;
    height: 340px;
    padding-bottom: 10px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .left,
    .right {
      height: 320px;
    }

    .left {
      width: 50%;
      height: 322px;
      padding: 34px;
      box-sizing: border-box;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 18px;

      .title1 {
        margin-top: 16px;
      }

      .title2 {
        margin-top: 34px;
      }
    }

    .right {
      width: 50%;
      height: 352px;
      padding: 11px 0;
      padding-left: 63px;
      box-sizing: border-box;
      color: #000;
      font-size: 18px;

      .unit {
        margin-top: 10px;
        margin-bottom: 21px;
        color: #000;
        font-size: 16px;
      }

      .chart {
        display: flex;
        align-items: center;
        height: 204px;

        .img {
          width: 322px;
          height: 204px;
        }

        .tip {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 204px;
          margin-left: 46px;

          .line {
            display: flex;
            align-items: center;

            .div {
              width: 16px;
              height: 16px;
              background-color: #E62E29;
            }

            .font {
              margin-left: 10px;
              color: #000;
              font-size: 16px;
            }

            .font1 {
              background-color: #EF5450;
            }

            .font2 {
              background-color: #F27744;
            }

            .font3 {
              background-color: #1CC434;
            }

            .font4 {
              background-color: #93FF92;
            }

            .font5 {
              background-color: #29EBC9;
            }

            .font6 {
              background-color: #FFB435;
            }
          }
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 15px;
        color: #000;
        font-size: 16px;
        padding-right: 30px;
        box-sizing: border-box;

        span {
          margin-left: 30px;
        }
      }
    }
  }

  .news {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 99%;
    height: calc(100% - 340px);

    .news-left,
    .news-right {
      width: 50%;
      height: 100%;
      padding-right: 17px;
      box-sizing: border-box;

      .echarts {
        width: 100%;
        height: 100%;
      }
    }

    .tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000;
      height: 26px;
      margin-top: 10px;
      font-size: 18px;

      .btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 26px;
        color: #FFFFFF;
        font-size: 14px;

        .btn {
          cursor: pointer;
          height: 26px;
          line-height: 26px;
          margin-left: 20px;
          padding: 0 10px;
          text-align: center;
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }

    .table {
      width: 100%;
      height: calc(100% - 40px);
      margin-top: 10px;
      box-sizing: border-box;

      /deep/.el-table__cell {
        text-align: center;
        color: #000;
      }

      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: #024276;
        color: #fff;
        font-size: 14px;
      }

      .flex {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-content: center;
        justify-content: center;
        width: 120px;
        height: 40px;
        line-height: 40px;
        text-align: center;
      }

      .flex1 {
        width: 142px;
      }

      .flex2 {
        width: 40px;
      }

      .flex3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .flex4 {
        width: 220px;
      }

      .scroll {
        width: 100%;
        height: calc(100% - 40px);

        .line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          color: #000;
          font-size: 14px;

          .color {
            color: #FF0000;
            font-size: 14px;
          }

          .update,
          .color {
            width: 40px;
          }
        }

        .line2 {
          justify-content: center;
        }

        .line3,
        .line2 {
          color: #fff;
        }
      }
    }
  }
}
</style>

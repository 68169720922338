<template>
  <div class="chat">
    <div class="left">
      <div @click="closePopup" class="title">
        <span>消息管理</span>
      </div>
      <div class="scroll">
        <div class="list" v-for="(item, index) in 30" :key="index">
          <img src="../../img/ssjk/user.png" alt="头像">
          <div class="info">
            <div class="name">
              <span>韩正义</span>
              <span class="time">08:00</span>
            </div>
            <div class="news">请您按时吃药！请您按时吃药！</div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="title">
        <span>韩正义</span>
        <span @click="(showDrawer = !showDrawer)" class="open">···</span>
      </div>
      <div class="box">
        <transition name="bounce">
          <div v-if="showDrawer" class="drawer">
            <div class="top">
              <img src="../../img/ssjk/user.png" alt="头像">
              <div class="user-info">
                <span class="name">韩正义</span>
                <span>查看资料</span>
              </div>
            </div>
            <div class="line">
              <span class="margin-right">好友备注</span>
              <el-input placeholder="请输入备注信息"></el-input>
            </div>
            <div class="line">
              <span class="margin-right">消息置顶</span>
              <el-switch active-text="启用" inactive-text="关闭" :width="50" v-model="isSwitch" active-color="#0BBB0B"
                inactive-color="#ADADAD">
              </el-switch>
            </div>
            <div class="line">
              <span class="margin-right">消息免打扰</span>
              <el-switch active-text="启用" inactive-text="关闭" :width="50" v-model="isSwitch" active-color="#0BBB0B"
                inactive-color="#ADADAD">
              </el-switch>
            </div>
          </div>
        </transition>

        <div class="scroll">
          <div v-for="(item, index) in 10" :key="index">
            <div class="user-left">
              <img src="../../img/ssjk/user.png" alt="头像">
              <div class="content margin-left">医生你好，这周六有时间做随访吗？</div>
            </div>
            <div class="user-rgiht">
              <img class="margin-left" src="../../img/ssjk/user.png" alt="头像">
              <div class="content">医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？</div>
            </div>
          </div>
        </div>

        <div class="input">
          <el-input v-model="textarea" type="textarea" :rows="4" resize='none' placeholder="请输入"></el-input>
          <div class="bottom">
            <div class="bottom-left">
              <span class="el-icon-microphone"></span>
              <span class="el-icon-picture"></span>
            </div>
            <el-button size="small" type="primary">发送</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      textarea: '',
      showDrawer: false,
      isSwitch: false
    }
  },
  methods: {
    closePopup () {
      this.$emit('closePopup')
    },
    handleClose () { }
  }
}
</script>

<style lang="less" scoped>
.chat {
  overflow: hidden;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .left {
    width: 244px;
    height: 100%;
    background-color: #fff;

    .title {
      position: relative;
      width: 100%;
      height: 50px;
      line-height: 50px;
      color: #333333;
      font-size: 17px;
      font-weight: 600;
      padding-left: 20px;

      &::before {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 4px;
        height: 18px;
        background-color: #366FF7;
        border-radius: 2px;
      }
    }

    .scroll {
      overflow-y: scroll;
      width: 214px;
      height: calc(100% - 60px);
      margin: 0 auto;

      &::-webkit-scrollbar {
        width: 0;
      }

      .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 62px;
        padding: 0 13px;
        border-bottom: 1px solid #F7F7F7;
        box-sizing: border-box;

        img {
          width: 32px;
          height: 32px;
        }

        .info {
          width: 140px;
          height: 42px;

          .name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            color: #2A374D;
            font-size: 16px;
          }

          .time,
          .news {
            color: #A1A6BB;
            font-size: 14px;
          }

          .news {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .right {
    width: calc(100% - 244px);
    height: 100%;
    padding-top: 55px;
    padding-right: 115px;
    box-sizing: border-box;
    background-color: #fff;

    .title {
      position: relative;
      width: 100%;
      height: 44px;
      line-height: 44px;
      text-align: center;
      color: #333333;
      font-size: 16px;
      font-weight: 600;
      border-top: 1px solid #F7F7F7;
      border-bottom: 1px solid #F7F7F7;
      box-sizing: border-box;

      .open {
        position: absolute;
        right: 29px;
        height: 40px;
        line-height: 40px;
        color: #ADADAD;
        font-size: 16px;
      }
    }

    .box {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: calc(100% - 44px);
      background-color: #EAEAEA;

      .drawer {
        position: absolute;
        right: 4px;
        top: 4px;
        width: 234px;
        height: calc(100% - 170px);
        background-color: #fff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
        border-radius: 6px;
        padding: 22px 18px;
        box-sizing: border-box;

        .top {
          display: flex;
          align-items: center;
          height: 40px;

          img {
            width: 40px;
            height: 40px;
          }

          .user-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 40px;
            margin-left: 9px;
            color: #378BF3;
            font-size: 14px;

            .name {
              color: #2A374D;
              font-size: 18px;
            }
          }
        }

        .line {
          display: flex;
          align-items: center;
          margin-top: 10px;
          color: #A1A6BB;
          font-size: 14px;

          .margin-right {
            margin-right: 8px;
          }

          .el-input {
            width: 95px;

            /deep/ .el-input__inner {
              width: 100%;
              height: 24px;
              line-height: 24px;
              padding: 0;
              text-align: center;
              border-color: transparent transparent #ECECEC transparent;
              font-size: 12px;
            }
          }

          ::v-deep .el-switch__label {
            position: absolute;
            display: none;
            color: #fff;
          }

          /*打开时文字位置设置*/
          ::v-deep .el-switch__label--right {
            z-index: 1;
            left: 4px;
            margin: 0 !important;
          }

          /*关闭时文字位置设置*/
          ::v-deep .el-switch__label--left {
            z-index: 1;
            right: 4px;
          }

          /*显示文字*/
          ::v-deep .el-switch__label.is-active {
            display: block;
          }

          ::v-deep .el-switch .el-switch__core,
          .el-switch .el-switch__label {
            width: 55px;
          }

          ::v-deep .el-switch__label * {
            font-size: 11px !important;
          }
        }
      }

      .bounce-enter-active {
        animation: bounce-in .5s;
      }

      .bounce-leave-active {
        animation: bounce-in .5s reverse;
      }

      @keyframes bounce-in {
        0% {
          transform: translateX(234px);
        }

        100% {
          transform: translateX(0);
        }
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        padding: 0 60px;
        box-sizing: border-box;
        height: calc(100% - 160px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .user-left,
        .user-rgiht {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100px;
        }

        .user-rgiht {
          flex-direction: row-reverse;
        }

        .content {
          max-width: 540px;
          padding: 5px 11px;
          background-color: #fff;
          border-radius: 16px;
          color: #2A374D;
          font-size: 16px;

          img {
            width: 32px;
            height: 32px;
          }
        }

        .margin-left {
          margin-left: 10px;
        }
      }

      .input {
        width: 100%;
        height: 158px;
        padding: 10px 20px;
        box-sizing: border-box;

        .el-input {
          width: 100%;
          height: 86px;

        }

        /deep/.el-textarea__inner::-webkit-scrollbar {
          width: 0;
        }

        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 30px;
          margin-top: 10px;

          .bottom-left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100px;
            height: 30px;
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>

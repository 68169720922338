<template>
  <div class="popup">
    <div class="scroll">
      <div class="head">查看详情</div>
      <div class="title">基本信息</div>
      <div class="content">
        <div class="item">
          <div class="key">姓名</div>
          <div class="value">李明明</div>
        </div>
        <div class="item">
          <div class="key">性别</div>
          <div class="value">男</div>
        </div>
        <div class="item">
          <div class="key">年龄</div>
          <div class="value">40</div>
        </div>
        <div class="item">
          <div class="key">联系电话</div>
          <div class="value">15803339264</div>
        </div>
        <div class="item">
          <div class="key">住址</div>
          <div class="value">河北石家庄桥西区友谊街道202号</div>
        </div>
        <div class="item">
          <div class="key">会员分组</div>
          <div class="value">慢性病人群</div>
        </div>
        <div class="item">
          <div class="key">主管医师</div>
          <div class="value">李四</div>
        </div>
        <div class="item">
          <div class="key">状态</div>
          <div class="value">正常</div>
        </div>
        <div class="item">
          <div class="key">所属门店</div>
          <div class="value">六里屯医院</div>
        </div>
        <div class="item item1">
          <div class="key">添加时间</div>
          <div class="value">2022-10-19</div>
        </div>
      </div>
      <div class="title">评估结果</div>
      <div class="tab">
        <div v-for="(item, index) in tabs" :key="index" @click="switchClick(index)" class="item">
          <span>{{ item }}</span>
          <span :class="{ border: tabIndex === index }"></span>
        </div>
      </div>
      <AssessXy v-if="tabIndex === 0"></AssessXy>
      <AssessTn v-else-if="tabIndex === 1"></AssessTn>
      <AssessFp v-else-if="tabIndex === 2"></AssessFp>
      <AssessXz v-else-if="tabIndex === 3"></AssessXz>
      <AssessDx v-else-if="tabIndex === 4"></AssessDx>
      <AssessZw v-else-if="tabIndex === 5"></AssessZw>
    </div>
    <div class="return">
      <el-button @click="closePopup">返回</el-button>
    </div>
  </div>
</template>

<script>
import AssessXy from './assessXy.vue'
import AssessTn from './assessTn.vue'
import AssessFp from './assessFp.vue'
import AssessXz from './assessXz.vue'
import AssessDx from './assessDx.vue'
import AssessZw from './assessZw.vue'
export default {
  props: {
    showIndex: {
      type: Number,
      default: 0
    }
  },
  components: {
    AssessXy,
    AssessTn,
    AssessFp,
    AssessXz,
    AssessDx,
    AssessZw
  },
  data () {
    return {
      tabIndex: 0,
      tabs: ['高血压风险评估', '糖尿病风险评估', '肥胖症评估', '血脂异常评估', '代谢综合症风险评估', '自我评估']
    }
  },
  created () {
    this.tabIndex = this.showIndex
  },
  methods: {
    switchClick (index) {
      this.tabIndex = index
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
      width: 0;
    }

    div {
      color: #000;
    }

    .head {
      font-size: 20px;
    }

    .title {
      width: 96%;
      height: 47px;
      line-height: 47px;
      margin: 0 auto;
      margin-top: 10px;
      padding: 0 18px;
      box-sizing: border-box;
      font-size: 18px;
      border-bottom: 1px solid #000;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 22px;
      padding: 0 40px;

      .item {
        display: flex;
        align-items: center;
        width: 25%;
        margin-bottom: .125rem;
        font-size: 16px;

        .key {
          width: 70px;
          margin-right: 18px;
          text-align-last: justify;
        }

        .value {
          width: calc(100% - 88px);
        }
      }

      .item1 {
        width: 75%;
      }
    }

    .tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 96%;
      height: 34px;
      margin: 0 auto;
      margin-top: 14px;
      padding: 0 10px;
      box-sizing: border-box;
      font-size: 18px;
      cursor: pointer;

      .item {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        height: 34px;

        .border {
          width: 70%;
          height: 3px;
          background-color: #000;
        }
      }
    }

    .tab-content {
      display: flex;
      position: relative;
      width: 96%;
      // height: calc(100% - 360px);
      margin: 0 auto;
      margin-top: 25px;
    }
  }

  .return {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 50px;

    .el-button {
      width: 71px;
      height: 26px;
      line-height: 26px;
      padding: 0;
      text-align: center;
      background-color: #D2D2D2;
      color: #fff;
      font-size: 14px;
      border: none;
      border-radius: 0;
    }
  }
}
</style>

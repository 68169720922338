<template>
  <div class="assess">
    <div class="line">
      <div class="left">
        <div class="table-left">
          <span>极高风险</span>
          <span>高风险</span>
          <span>中等风险</span>
          <span>低风险</span>
          <span>极低风险</span>
          <span></span>
        </div>
        <div class="table-right">
          <div class="table-line"></div>
          <div class="table-line"></div>
          <div class="table-line"></div>
          <div class="table-line"></div>
          <div class="table-line border"></div>
          <div class="table-line none">
            <span>风险等级</span>
            <span>当前风险</span>
            <span>理想风险</span>
          </div>
          <div class="position">
            <div class="column">
              <div class="item"></div>
              <div class="item"></div>
              <div class="item"></div>
              <div class="item"></div>
              <div class="item"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">自我评估风险</div>
        <div class="title1">您患高血压的风险等级</div>
        <div class="info">您患高血压的风险等级：</div>
        <div class="info">当前风险：</div>
        <div class="info">理想风险：</div>
      </div>
    </div>
    <div class="line-title">与高血压发病相关的危险因素</div>
    <div class="tables">
      <div class="tr">
        <span>危险因素</span>
        <span>本次</span>
        <span>本次日期</span>
        <span>上次</span>
        <span>上次周期</span>
        <span>变化情况</span>
        <span>参考值</span>
      </div>
      <div class="tr">
        <span>收缩压</span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span>&lt;140mmHg</span>
      </div>
      <div class="tr">
        <span>舒张压</span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span>&lt;80mmHg</span>
      </div>
      <div class="tr">
        <span>脉搏</span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span>&lt;90次/分</span>
      </div>
      <div class="tr">
        <span>BMI</span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span>18.5&lt;bmi&lt;24</span>
      </div>
      <div class="tr">
        <span>吸烟状况</span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="tr">
        <span>高血压家族史</span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="line-title">改善以下因素降低高血压的发病风险</div>
    <div class="line-title1">饮食建议</div>
    <div class="div">多吃蔬菜水果</div>
    <div class="line-title1">运动建议</div>
    <div class="div">每天坚持30分钟有氧运动</div>
    <div class="line-title1">生活建议</div>
    <div class="div">作息规律，保持积极乐观的心态</div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.assess {
  display: flex;
  flex-direction: column;
  width: 96%;
  margin: 0 auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  div {
    color: #000;
  }

  .line {
    display: flex;
    align-items: center;
    width: 100%;
    height: 352px;
    margin-top: 20px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .left,
    .right {
      width: 50%;
      height: 340px;
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      border-right: 1px solid #000;

      .table-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 264px;
        font-size: 16px;

        span {
          height: 44px;
          line-height: 44px;
        }
      }

      .table-right {
        position: relative;
        width: 311px;
        height: 264px;
        margin-left: 6px;

        .table-line {
          width: 100%;
          height: 44px;
          border: 1px #000 solid;
          box-sizing: border-box;
          border-bottom: none;
        }

        .border {
          border: 1px #000 solid;
        }

        .none {
          display: flex;
          justify-content: space-between;
          border: none;
        }

        .position {
          position: absolute;
          top: 0;
          left: 0;
          width: 311px;
          height: 220px;

          .column {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: space-between;
            width: 40px;
            height: 100%;

            .item {
              width: 100%;
              height: 20%;
              border: 1px #000 solid;
              box-sizing: border-box;
              background-color: #00DB00;
              border-right: none;
              border-bottom: none;
            }

            .item:nth-child(1) {
              border-bottom: 1px #000 solid;
            }

            .item:nth-child(2) {
              background-color: #97CBFF;
            }

            .item:nth-child(3) {
              background-color: #FFDC35;
            }

            .item:nth-child(4) {
              background-color: #FF9224;
            }

            .item:nth-child(5) {
              background-color: #E8290C;
            }
          }
        }
      }
    }

    .right {
      padding: 0 19px;
      box-sizing: border-box;

      .title {
        margin-top: 11px;
        font-size: 18px;
      }

      .title1 {
        margin-top: 7px;
        font-size: 16px;
      }

      .info {
        width: 380px;
        height: 38px;
        line-height: 38px;
        padding: 0 8px;
        margin-top: 10px;
        margin-left: 10px;
        font-size: 14px;
        border: 1px solid #000;
        box-sizing: border-box;
      }
    }
  }

  .line-title {
    display: flex;
    align-items: center;
    margin-top: 28px;
    margin-left: 44px;
    font-size: 18px;
  }

  .tables {
    width: 98%;
    height: 217px;
    margin-top: 20px;
    margin-left: 44px;
    border-left: 1px solid #000;
    border-top: 1px solid #000;
    box-sizing: border-box;

    .tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 31px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;
      font-size: 14px;

      span {
        flex: 1;
        height: 31px;
        line-height: 31px;
        text-align: center;
        box-sizing: border-box;
        border-right: 1px solid #000;
      }
    }
  }

  .line-title1 {
    margin-top: 28px;
    margin-left: 54px;
    font-size: 18px;
  }

  .div {
    width: 90%;
    height: 80px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 9px 8px;
    box-sizing: border-box;
    border: 1px solid #000;
    font-size: 14px;
  }
}
</style>

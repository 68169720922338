<template>
  <div class="popup">
    <el-form ref="form">
      <div class="tip">完善档案信息</div>
      <div class="scroll">
        <div class="title">居民信息</div>
        <div class="info">
          <div class="item">
            <div class="key">服务编号</div>
            <div class="value">2091900120622</div>
          </div>
          <div class="item">
            <div class="key">姓名</div>
            <el-input size="small"></el-input>
          </div>
          <div class="item item1">
            <div class="key">居民头像</div>
            <div class="value">
              <img class="img" src="../../img/ssjk/user.png" alt="头像">
              <div class="el-icon-plus"></div>
              <div>点击上传</div>
            </div>
          </div>
          <div class="item item1">
            <div class="key">联系电话</div>
            <el-input size="small" v-model="lxdh"></el-input>
          </div>
          <div class="item">
            <div class="key">身份证号</div>
            <el-input size="small" class="input"></el-input>
          </div>
          <div class="item">
            <div class="key">居民标签</div>
            <el-select size="small" v-model="region" placeholder="请选择">
              <el-option label="慢病护理" value="1"></el-option>
              <el-option label="慢病护理" value="2"></el-option>
            </el-select>
          </div>
          <div class="item item1">
            <div class="key">现居地</div>
            <el-input size="small" placeholder="请输入" class="input1"></el-input>
          </div>
          <div class="item item1">
            <div class="key">行政区划</div>
            <el-input size="small" placeholder="请输入" class="input1"></el-input>
          </div>
          <div class="item item2">
            <div class="key">户籍地址</div>
            <el-input size="small" class="input"></el-input>
          </div>
          <div class="family">
            <div class="news">
              <div class="key">家族变史</div>
              <div class="card">
                <img class="left" src="../../img/ssjk/user.png" alt="头像">
                <div class="right">
                  <span>李青</span>
                  <span>62岁</span>
                  <span>关系</span>
                  <span>父母</span>
                </div>
              </div>
              <div class="key"><span class="el-icon-plus"></span><span class="add">关联家庭成员</span></div>
            </div>
          </div>
        </div>
        <div class="info info1">
          <div class="item">
            <div class="key">性别</div>
            <el-select size="small" v-model="region" placeholder="请选择">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
            </el-select>
          </div>
          <div class="item">
            <div class="key">出生日期</div>
            <el-date-picker size="small" type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="item item1">
            <div class="key">籍贯</div>
            <el-input size="small" placeholder="请输入" class="input"></el-input>
          </div>
          <div class="item item1">
            <div class="key">民族</div>
            <el-select size="small" v-model="region" placeholder="请选择">
              <el-option label="汉" value="1"></el-option>
              <el-option label="其他" value="2"></el-option>
            </el-select>
          </div>
          <div class="item">
            <div class="key">文化程度</div>
            <el-select size="small" v-model="region" placeholder="请选择">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
            </el-select>
          </div>
          <div class="item">
            <div class="key">婚姻状况</div>
            <el-select size="small" v-model="region" placeholder="请选择">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
            </el-select>
          </div>
          <div class="item item1">
            <div class="key">职业</div>
            <el-input size="small" placeholder="请输入" class="input"></el-input>
          </div>
          <div class="item item1">
            <div class="key">工作单位</div>
            <el-input size="small" placeholder="请输入" class="input1"></el-input>
          </div>
          <div class="item">
            <div class="key2">紧急联系人</div>
            <el-input size="small" placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <div class="key2">联系人电话</div>
            <el-input size="small" placeholder="请输入"></el-input>
          </div>
          <div class="item item3">
            <div class="key">登陆密码</div>
            <el-input size="small" placeholder="8为以上数字和字母组合" class="input1"></el-input>
          </div>
        </div>

        <div class="info info1">
          <div class="item">
            <div class="key">户籍类型</div>
            <el-select size="small" v-model="region" placeholder="请选择">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
            </el-select>
          </div>
          <div class="item item4">
            <div class="key key3">定点医疗单位</div>
            <el-input size="small" placeholder="请输入" class="input1"></el-input>
          </div>
          <div class="item item4">
            <div class="key">费用类型</div>
            <el-checkbox-group v-model="type" text-color="#000" fill="#000">
              <el-checkbox lass="myRedCheckBox" text-color="#000" fill="#000" label="自费"></el-checkbox>
              <el-checkbox text-color="#000" fill="#000" label="社会医疗保险"></el-checkbox>
              <el-checkbox text-color="#000" fill="#000" label="商业保险"></el-checkbox>
              <el-checkbox text-color="#000" fill="#000" label="新农合"></el-checkbox>
              <el-checkbox text-color="#000" fill="#000" label="其他"></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="title">健康信息</div>
        <div class="info info2">
          <div class="item">
            <div>身体：</div>
            <el-input size="small"></el-input>
            <span> kg</span>
          </div>
          <div class="item">
            <div>体重：</div>
            <el-input size="small"></el-input>
            <span> kg</span>
          </div>
          <div class="item">
            <div class="key">血型：</div>
            <el-select size="small" v-model="region" placeholder="请选择">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
            </el-select>
          </div>
          <div class="item">
            <div>RH阴性：</div>
            <el-select size="small" v-model="region" placeholder="请选择">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
            </el-select>
          </div>
          <div class="item">
            <div>既往史</div>
            <el-radio-group v-model="radio">
              <el-radio :label="0">有</el-radio>
              <el-radio :label="1">无</el-radio>
            </el-radio-group>
          </div>
          <div class="item">
            <div>过敏史</div>
            <el-radio-group v-model="radio">
              <el-radio :label="0">有</el-radio>
              <el-radio :label="1">无</el-radio>
            </el-radio-group>
          </div>
          <div class="item">
            <div>就诊史</div>
            <el-radio-group v-model="radio">
              <el-radio :label="0">有</el-radio>
              <el-radio :label="1">无</el-radio>
            </el-radio-group>
          </div>
          <div class="item">
            <div>家族病史</div>
            <el-radio-group v-model="radio">
              <el-radio :label="0">有</el-radio>
              <el-radio :label="1">无</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="bts">
        <el-button type="primary" size="small">保存</el-button>
        <el-button size="small" @click="closePopup">返回</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  // name: 'updateSigningPopup',
  data () {
    return {
      region: '',
      lxdh: '',
      type: ['自费'],
      radio: 0
    }
  },
  methods: {
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .tip {
    margin-bottom: 10px;
    color: #000;
    font-size: 20px;
  }

  .title {
    height: 45px;
    line-height: 45px;
    border-top: 1px solid #000;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
  }

  .title1 {
    border: none;
  }

  .el-form {
    height: 100%;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 23%;
      margin-bottom: 20px;
      color: #000;
      font-size: 18px;

      .key {
        width: 77px;
        margin-right: 8px;
        text-align: justify;
        text-align-last: justify;
      }

      .value {
        display: flex;
        align-items: center;
        font-size: 16px;
      }

      .key1 {
        width: 90px;
      }

      .key2 {
        width: 100px;
      }

      .key3 {
        width: 120px;
      }

      .img {
        width: 25px;
        height: 25px;
        margin-right: 8px;
      }

      .el-input,
      .el-select {
        width: 151px;
      }

      ::v-deep .el-input__inner::placeholder {
        color: #252523;
      }

      .el-date-picker {
        width: 170px;
      }

      .input {
        width: 211px !important;
      }

      .input1 {
        width: 272px;
      }

      .money {
        color: #FF915C;
        font-size: 16px;
      }
    }

    .item1 {
      width: 27%;
    }

    .item2 {
      height: 75px;
    }

    .item3 {
      width: 54%;
    }

    .item4 {
      width: 77%;
    }
  }

  .info1 {
    padding: 20px;
    padding-bottom: 0;
    border-top: 1px solid #000;
  }

  .info2 {
    padding: 20px;
    padding-bottom: 0;
  }

  .family {
    width: 77%;
    height: 75px;

    .title {
      border: none;
    }

    .news {
      display: flex;
      height: 75px;

      .key {
        margin-right: 8px;
        color: #000;
        font-size: 18px;
        text-align: justify;
        text-align-last: justify;

        .add {
          margin-left: 8px;
        }
      }

      .card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 132px;
        height: 75px;
        margin-right: 30px;
        padding: 0 20px;
        background: linear-gradient(104deg, #0171C4 0%, #024479 100%);
        border-radius: 4px;

        .left {
          width: 25px;
          height: 25px;
        }

        .right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 77px;
          height: 52px;
          color: #fff;
          font-size: 16px;
        }
      }

      .textarea {
        width: 523px;
        height: 66px;
        margin-top: 10px;
        border: 2px solid #000;
        box-sizing: border-box;
      }

      ::v-deep .el-textarea__inner {
        width: 523px;
        height: 66px;
        background-color: transparent;
        border: none;
        padding: 5px;
        box-sizing: border-box;
        color: #fff;
      }
    }

    .news1 {
      flex-direction: column;
      justify-content: center;

      .key {
        width: 72px;
      }
    }
  }

  .el-radio-group {
    margin-left: 10px;
  }

  .bts {
    position: absolute;
    bottom: 30px;
    display: flex;
    align-items: center;
    width: 200px;
    height: 26px;
    margin-left: 18px;

    span {
      height: 26px;
      line-height: 26px;
      padding: 0 18px;
      color: #fff;
      font-size: 14px;
      background-color: #D5D5D5;
    }

    .update {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>

<template>
  <div class="visit">
    <div class="srcoll">
      <div class="tab">
        <span @click="tabClick(0)" class="item" :class="{ item1: tabIndex === 0 }">高血压</span>
        <span @click="tabClick(1)" class="item" :class="{ item1: tabIndex === 1 }">糖尿病</span>
      </div>
      <template v-if="tabIndex === 0">
        <div class="content">
          <div class="content-tab">
            <span class="content-item" :class="{ 'content-item1': xyIndex === 0 }" @click="tabXyClick(0)">高血压管理卡</span>
            <span class="content-item" :class="{ 'content-item1': xyIndex === 1 }" @click="tabXyClick(1)">高血压随访记录</span>
          </div>
          <el-form v-if="xyIndex === 0">
            <div class="title">
              <span>患病情况</span>
            </div>
            <div class="line">
              <span class="key">高血压类型</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">原发性</el-radio>
                <el-radio label="1">继发性</el-radio>
                <el-radio label="2">不详</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">血压</span>
              <el-input class="width" size="small"></el-input>
              <span class="icon1">/</span>
              <el-input class="width" size="small"></el-input>
              <span class="icon">mmHg</span>
            </div>
            <div class="line">
              <span class="key">确诊医院</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">确诊医院</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">确诊时间</span>
              <el-date-picker v-model="date" size="small" class="width1" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="line">
              <span class="key">家族史</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">个人史</span>
              <el-input class="width1" size="small"></el-input>
            </div>
            <div class="line line1">
              <span class="key">过敏史</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="title title1">
              <span>高血压并发症情况</span>
            </div>
            <div class="line">
              <span class="key">脑血管疾病</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">心脏疾病</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">肾脏疾病</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">血管疾病</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">重度高血压性视网膜病变</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">生活自理能力</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">完全自理</el-radio>
                <el-radio label="1">部分自理</el-radio>
                <el-radio label="2">完全不能自理</el-radio>
              </el-radio-group>
            </div>
            <div class="title title1">
              <span>生活习惯</span>
            </div>
            <div class="line">
              <span class="key">吸烟状况</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">吸烟</el-radio>
                <el-radio label="1">戒烟</el-radio>
                <el-radio label="2">不吸</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">饮酒频率</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">偶尔</el-radio>
                <el-radio label="1">经常</el-radio>
                <el-radio label="2">不饮</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">体力活动</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">规律</el-radio>
                <el-radio label="1">偶尔</el-radio>
                <el-radio label="2">不锻炼</el-radio>
              </el-radio-group>
            </div>
            <div class="title title1">
              <span>最近一次检查结果</span>
            </div>
            <div class="line line2">
              <span class="key">身高</span>
              <el-input size="small"></el-input>
              <span class="icon">cm</span>
            </div>
            <div class="line line2">
              <span class="key">体重</span>
              <el-input size="small"></el-input>
              <span class="icon">kg</span>
            </div>
            <div class="line line2">
              <span class="key">脉搏</span>
              <el-input size="small"></el-input>
              <span class="icon">次/分</span>
            </div>
            <div class="line line2">
              <span class="key">心率</span>
              <el-input size="small"></el-input>
              <span class="icon">次/分</span>
            </div>
            <div class="line line2">
              <span class="key">左侧血压</span>
              <el-input size="small"></el-input>
              <span class="icon">mmHg</span>
            </div>
            <div class="line line2">
              <span class="key">右侧血压</span>
              <el-input size="small"></el-input>
              <span class="icon">mmHg</span>
            </div>
            <div class="line line2">
              <span class="key">空腹血氧</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line2">
              <span class="key">餐后血糖</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line3">
              <span class="key">高密度脂蛋白胆固醇</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line3">
              <span class="key">低密度脂蛋白胆固醇</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line2">
              <span class="key">甘油三脂</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line2">
              <span class="key">总胆固醇</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line3">
              <span class="key">尿微量白蛋白</span>
              <el-input size="small"></el-input>
              <span class="icon">mg/dL</span>
            </div>
            <div class="line line3">
              <span class="key">心电图检查结果</span>
              <el-input size="small"></el-input>
              <span class="icon">mg/dL</span>
            </div>
            <div class="line line2">
              <span class="key">其他</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="btns">
              <el-button type="primary" size="small">保存</el-button>
              <el-button type="danger" size="small">清除</el-button>
            </div>
          </el-form>
        </div>
        <div v-if="xyIndex === 0" class="add">
          <span>用药名称及用量</span>
          <el-button type="primary" size="small">新增</el-button>
        </div>
        <div v-if="xyIndex === 0" class="table">
          <!-- <div class="tr tr1">
            <span class="td">序号</span>
            <span class="td">药物名称</span>
            <span class="td">用法</span>
            <span class="td">用量</span>
            <span class="td">单位</span>
            <span class="td">操作</span>
          </div>
          <div class="tr">
            <span class="td">00001</span>
            <span class="td">拜新同</span>
            <span class="td">每日一次</span>
            <span class="td">一次30mg</span>
            <span class="td">mg</span>
            <span class="td">
              <span class="blue">编辑</span>
              <span class="red">删除</span>
            </span>
          </div> -->
          <el-table :data="tableData" height="200" border>
            <el-table-column prop="address" label="序号">
            </el-table-column>
            <el-table-column prop="name" label="药物名称">
            </el-table-column>
            <el-table-column prop="name" label="用法">
            </el-table-column>
            <el-table-column prop="name" label="用量">
            </el-table-column>
            <el-table-column prop="name" label="单位">
            </el-table-column>
            <el-table-column prop="address" label="操作" width="180">
              <el-button type="primary" size="small">编辑</el-button>
              <el-button type="danger" size="small">删除</el-button>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="xyIndex === 1" class="add">
          <el-button type="primary" size="small" @click="openAddVisit">新增</el-button>
        </div>
        <div v-if="xyIndex === 1" class="table">
          <!-- <div class="tr tr1">
            <span class="td">序号</span>
            <span class="td">随访类型</span>
            <span class="td">随访时间</span>
            <span class="td">随访方式</span>
            <span class="td">症状</span>
            <span class="td">操作</span>
          </div>
          <div class="tr">
            <span class="td">00001</span>
            <span class="td">正常随访</span>
            <span class="td">2022-03-04</span>
            <span class="td">电话随访</span>
            <span class="td">头疼、头晕</span>
            <span class="td">
              <span class="blue">编辑</span>
              <span class="red">删除</span>
            </span>
          </div> -->
          <el-table :data="tableData" height="200" border>
            <el-table-column prop="address" label="序号">
            </el-table-column>
            <el-table-column prop="name" label="随访类型">
            </el-table-column>
            <el-table-column prop="name" label="随访时间">
            </el-table-column>
            <el-table-column prop="name" label="随访方式">
            </el-table-column>
            <el-table-column prop="name" label="症状">
            </el-table-column>
            <el-table-column prop="address" label="操作" width="180">
              <el-button type="primary" size="small">编辑</el-button>
              <el-button type="danger" size="small">删除</el-button>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <template v-else-if="tabIndex === 1">
        <div class="content">
          <div class="content-tab">
            <span class="content-item" :class="{ 'content-item1': xtIndex === 0 }" @click="tabXtClick(0)">糖尿病管理卡</span>
            <span class="content-item" :class="{ 'content-item1': xtIndex === 1 }" @click="tabXtClick(1)">糖尿病随访记录</span>
          </div>
          <el-form v-if="xtIndex === 0">
            <div class="title">
              <span>一般信息</span>
            </div>
            <div class="line">
              <span class="key">定点医疗单位</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">个人史</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">现病史</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">糖尿病家族史</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">过敏史</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line line3">
              <span class="key">确诊单位</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">确诊方式</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">确诊时间</span>
              <el-date-picker v-model="date" size="small" class="width1" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="line">
              <span class="key">高血压</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">视网膜病变</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line line4">
              <span class="key">糖尿病足</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">糖尿病肾病</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">糖尿病神经病变</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">高血脂</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line line4">
              <span class="key">冠心病</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line line3">
              <span class="key">既往空腹血糖</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line5">
              <span class="key">既往餐后2小时血糖</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line5">
              <span class="key">既往糖化血红蛋白</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line">
              <span class="key">吸烟</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">饮酒</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">脑血管疾病</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">心脏疾病</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">家族史</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">肾脏疾病</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">血管疾病</span>
              <el-input size="small"></el-input>
            </div>
            <div class="line">
              <span class="key">重度高血压性视网膜病变</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="title title1">
              <span>最近一次检查结果</span>
            </div>
            <div class="line line3">
              <span class="key">身高</span>
              <el-input size="small"></el-input>
              <span class="icon">cm</span>
            </div>
            <div class="line line3">
              <span class="key">体重</span>
              <el-input size="small"></el-input>
              <span class="icon">次/分</span>
            </div>
            <div class="line line3">
              <span class="key">脉搏</span>
              <el-input size="small"></el-input>
              <span class="icon">次/分</span>
            </div>
            <div class="line line3">
              <span class="key">心率</span>
              <el-input size="small"></el-input>
              <span class="icon">次/分</span>
            </div>
            <div class="line line3">
              <span class="key">左侧血压</span>
              <el-input size="small"></el-input>
              <span class="icon">mmHg</span>
            </div>
            <div class="line line3">
              <span class="key">右侧血压</span>
              <el-input size="small"></el-input>
              <span class="icon">mmHg</span>
            </div>
            <div class="line line3">
              <span class="key">空腹血氧</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line3">
              <span class="key">餐后血糖</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line5">
              <span class="key">高密度脂蛋白胆固醇</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line5">
              <span class="key">低密度脂蛋白胆固醇</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line2">
              <span class="key">甘油三脂</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line2">
              <span class="key">总胆固醇</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line line2">
              <span class="key">尿微量白蛋白</span>
              <el-input size="small"></el-input>
              <span class="icon">mg/dL</span>
            </div>
            <div class="line line5">
              <span class="key">心电图检查结果</span>
              <el-input size="small"></el-input>
              <span class="icon">mg/dL</span>
            </div>
            <div class="line line3">
              <span class="key">其他</span>
              <el-input size="small"></el-input>
              <span class="icon">mmol/L</span>
            </div>
            <div class="line">
              <span class="key">视网膜病变</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">神经病变</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="title title1">
              <span>近期药物治疗情况</span>
            </div>
            <div class="line">
              <span class="key">饮食治疗</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">体力活动</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">口服降糖药</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">胰岛素</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">戒烟</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">限酒</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="line">
              <span class="key">是否终止管理</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
            </div>
            <div class="btns">
              <el-button>保存</el-button>
              <el-button>清除</el-button>
            </div>
          </el-form>
        </div>
        <div v-if="xtIndex === 0" class="add">
          <span>目前并发症或合并症情況</span>
          <el-button type="primary" size="small" @click="openWriteMq">新增</el-button>
        </div>
        <div v-if="xtIndex === 0" class="table">
          <!-- <div class="tr tr1">
            <span class="td">序号</span>
            <span class="td">疾病类型</span>
            <span class="td">诊断时间</span>
            <span class="td">治疗方式</span>
            <span class="td">操作</span>
          </div>
          <div class="tr">
            <span class="td">00001</span>
            <span class="td">1型糖尿病</span>
            <span class="td">2022-03-03</span>
            <span class="td">用药</span>
            <span class="td">
              <span class="blue">编辑</span>
              <span class="red">删除</span>
            </span>
          </div> -->
          <el-table :data="tableData" height="200" border>
            <el-table-column prop="address" label="序号">
            </el-table-column>
            <el-table-column prop="name" label="疾病类型">
            </el-table-column>
            <el-table-column prop="name" label="诊断时间">
            </el-table-column>
            <el-table-column prop="name" label="治疗方式">
            </el-table-column>
            <el-table-column prop="address" label="操作" width="180">
              <el-button type="primary" size="small">编辑</el-button>
              <el-button type="danger" size="small">删除</el-button>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="xtIndex === 0" class="add">
          <span>用药名称及用量</span>
          <el-button type="primary" size="small" @click="openWriteYy">新增</el-button>
        </div>
        <div v-if="xtIndex === 0" class="table">
          <!-- <div class="tr tr1">
            <span class="td">序号</span>
            <span class="td">药物名称</span>
            <span class="td">用法</span>
            <span class="td">用量</span>
            <span class="td">单位</span>
            <span class="td">操作</span>
          </div>
          <div class="tr">
            <span class="td">00001</span>
            <span class="td">拜新同</span>
            <span class="td">每日一次</span>
            <span class="td">一次30mg</span>
            <span class="td">mg</span>
            <span class="td">
              <span class="blue">编辑</span>
              <span class="red">删除</span>
            </span>
          </div> -->
          <el-table :data="tableData" height="200" border>
            <el-table-column prop="address" label="序号">
            </el-table-column>
            <el-table-column prop="name" label="药物名称">
            </el-table-column>
            <el-table-column prop="name" label="用法">
            </el-table-column>
            <el-table-column prop="name" label="用量">
            </el-table-column>
            <el-table-column prop="name" label="单位">
            </el-table-column>
            <el-table-column prop="address" label="操作" width="180">
              <el-button type="primary" size="small">编辑</el-button>
              <el-button type="danger" size="small">删除</el-button>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="xtIndex === 1" class="add">
          <el-button type="primary" size="small">新增</el-button>
        </div>
        <div v-if="xtIndex === 1" class="table">
          <!-- <div class="tr tr1">
            <span class="td">序号</span>
            <span class="td">随访类型</span>
            <span class="td">随访时间</span>
            <span class="td">随访方式</span>
            <span class="td">症状</span>
            <span class="td">操作</span>
          </div>
          <div class="tr">
            <span class="td blue">00001</span>
            <span class="td blue">正常随访</span>
            <span class="td blue">2022-03-04</span>
            <span class="td blue">电话随访</span>
            <span class="td blue">头疼、头晕</span>
            <span class="td blue">
              <span class="blue">编辑</span>
              <span class="red">删除</span>
            </span>
          </div> -->
          <el-table :data="tableData" height="200" border>
            <el-table-column prop="address" label="序号">
            </el-table-column>
            <el-table-column prop="name" label="随访类型">
            </el-table-column>
            <el-table-column prop="name" label="随访时间">
            </el-table-column>
            <el-table-column prop="name" label="随访方式">
            </el-table-column>
            <el-table-column prop="name" label="症状">
            </el-table-column>
            <el-table-column prop="address" label="操作" width="180">
              <el-button type="primary" size="small">编辑</el-button>
              <el-button type="danger" size="small">删除</el-button>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <div class="return" :class="{ 'return1': tabIndex === 0 && xyIndex === 1 || tabIndex === 1 && xtIndex === 1 }">
        <el-button size="small">返回</el-button>
      </div>
    </div>
    <!-- 目前并发症或合并症情況 -->
    <WriteMq v-if="showWriteMq" @closePopup="closeWriteMq"></WriteMq>
    <!-- 用药名称及用量 -->
    <WriteYy v-if="showWriteYy" @closePopup="closeWriteYy"></WriteYy>
    <!-- 新增随访 -->
    <AddVisit v-if="showAddVisit" @closePopup="closeAddVisit"></AddVisit>
  </div>
</template>

<script>
import WriteMq from './writeMq.vue'
import WriteYy from './writeYy.vue'
import AddVisit from './addVisit.vue'
export default {
  components: {
    WriteMq,
    WriteYy,
    AddVisit
  },
  data () {
    return {
      tabIndex: 0,
      xyIndex: 0,
      xtIndex: 0,
      radio: '0',
      date: '',
      tableData: [{}],
      showWriteMq: false,
      showWriteYy: false,
      showAddVisit: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    tabXyClick (index) {
      this.xyIndex = index
    },
    tabXtClick (index) {
      this.xtIndex = index
    },
    openWriteMq () {
      this.showWriteMq = true
    },
    closeWriteMq () {
      this.showWriteMq = false
    },
    openWriteYy () {
      this.showWriteYy = true
    },
    closeWriteYy () {
      this.showWriteYy = false
    },
    openAddVisit () {
      this.showAddVisit = true
    },
    closeAddVisit () {
      this.showAddVisit = false
    }
  }
}
</script>

<style lang="less" scoped>
.visit {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .srcoll {
    overflow-y: scroll;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .tab {
    position: sticky;
    top: 0;
    z-index: 88;
    display: flex;
    width: 100%;
    height: 60px;
    background-color: #fff;

    .item {
      height: 40px;
      line-height: 40px;
      margin-right: 40px;
      padding: 0 10px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      background-color: rgb(173, 175, 177);
      cursor: pointer;
    }

    .item1 {
      background-color: rgb(64, 158, 255);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .content-tab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;

      .content-item {
        height: 30px;
        line-height: 30px;
        margin-left: 8px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: rgb(173, 175, 177);
        cursor: pointer;
      }

      .content-item1 {
        background-color: rgb(64, 158, 255);
      }
    }

    .el-form {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px 10px;
      box-sizing: border-box;

      .title {
        width: 100%;
        color: #000;
        font-size: 20px;
      }

      .line {
        display: flex;
        align-items: center;
        margin-top: 20px;
        padding-left: 10px;
        box-sizing: border-box;
        color: #000;
        font-size: 18px;

        .key {
          margin-right: 8px;
        }

        .el-radio {
          font-size: 18px;
        }

        // /deep/.el-radio__input.is-checked+.el-radio__label {
        //   color: #fff;
        // }

        // /deep/.el-radio__inner {
        //   width: 18px;
        //   height: 18px;
        //   background-color: transparent;
        //   border-color: #FFFFFF;
        // }

        // /deep/.el-radio__input.is-checked .el-radio__inner {
        //   width: 18px;
        //   height: 18px;
        //   border-color: #000;
        //   background-color: #000;
        // }

        // /deep/.el-radio__inner::after {
        //   content: '✓';
        //   width: 18px;
        //   height: 18px;
        //   line-height: 18px;
        //   text-align: center;
        //   font-size: 10px;
        //   background-color: transparent;
        // }

        .el-input {
          width: 100px;
        }

        /deep/.el-input__inner {
          border-radius: 0;
        }

        .width {
          width: 50px;
        }

        .width1 {
          width: 130px;
        }

        .icon {
          margin-left: 10px;
        }

        .icon1 {
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      .line1 {
        width: 82%;
      }

      .line2 {
        width: 21%;
      }

      .line3 {
        width: 23%;
      }

      .line4 {
        width: 40%;
      }

      .line5 {
        width: 26%;
      }

      .title1 {
        margin-top: 20px;
      }

      .btns {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 50%;
        height: 60px;
        margin: 0 auto;
      }
    }
  }

  .add {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 46px;
    color: #000;
    font-size: 16px;
  }

  .table {
    display: flex;
    flex-direction: column;
    width: 100%;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      color: #000;
      font-size: 14px;

      .td {
        flex: 1;
        text-align: center;
        cursor: pointer;
      }

      .blue {
        color: #000;
      }

      .red {
        margin-left: 30px;
        color: #FF0000;
      }
    }

    .tr1 {
      height: 40px;
      background-color: #024276;
      color: #fff;
    }
  }

  .return {
    position: sticky;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #fff;
  }

  .return1 {
    position: absolute;
  }
}
</style>

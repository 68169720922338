<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-01-18 17:54:21
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-02-19 17:08:24
 * @FilePath: \visions\src\views\zhyl\monitoring\healthReport\seePopup.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="see">
    <div @click="closePopup" class="close">
      <span class="el-icon-close"></span>
    </div>
    <div class="box"></div>
  </div>
</template>

<script>
export default {
  methods: {
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.see {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  // background-color: rgba(1, 124, 219, .27);
  // background: url('../../img/ssjk/background.png') no-repeat;
  // background-size: 100% 100%;

  .close {
    position: absolute;
    right: 30px;
    top: 20px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    // width: 0;
    // height: 0;
    // border-width: 20px;
    // border-style: solid;
    // border-color: transparent #2661AB transparent  transparent;
  }

  .box {
    width: 768px;
    height: 90%;
    background-color: #fff;
  }
}
</style>

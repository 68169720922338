<template>
  <div class="popup">
    <el-form ref="form" class="form">
      <div class="tip">处方详情</div>
      <div class="title">居民信息</div>
      <div class="info">
        <div class="item">
          <div class="key">居民编号</div>
          <span>2091900120622</span>
        </div>
        <div class="item">
          <div class="key">姓名</div>
          <span>李明明</span>
        </div>
        <div class="item">
          <div class="key">性别</div>
          <span>男</span>
        </div>
        <div class="item">
          <div class="key">年龄</div>
          <span>40</span>
        </div>
        <div class="item">
          <div class="key">联系方式</div>
          <span>15803339264</span>
        </div>
        <div class="item">
          <div class="key">会员分组</div>
          <span>慢性病人群</span>
        </div>
        <div class="item">
          <div class="key">主管医生</div>
          <span>张三</span>
        </div>
        <div class="item">
          <div class="key">状态</div>
          <span>正常</span>
        </div>
        <div class="item">
          <div class="key">住址</div>
          <span>北京市朝阳区</span>
        </div>
        <div class="item">
          <div class="key">所属门店</div>
          <span>六里屯社区医院</span>
        </div>
        <div class="item item1">
          <div class="key">添加时间</div>
          <span>2020-02-07 21：00：00</span>
        </div>
      </div>
      <div class="title">理疗处方</div>
      <div class="box">
        <!-- <div class="line line1">
          <div class="item">项目名称</div>
          <div class="item">仪器名称</div>
          <div class="item">方法</div>
          <div class="item">部位</div>
          <div class="item">频率</div>
          <div class="item">影响</div>
        </div>
        <div class="scroll">
          <div v-for="(item, index) in 10" :key="index" class="line line2">
            <div class="item">项目名称</div>
            <div class="item">仪器名称</div>
            <div class="item">方法</div>
            <div class="item">部位</div>
            <div class="item">频率</div>
            <div class="item">影响</div>
          </div>
        </div> -->
        <el-table :data="tableData" height="200" border>
          <el-table-column prop="address" label="项目名称">
          </el-table-column>
          <el-table-column prop="name" label="仪器名称">
          </el-table-column>
          <el-table-column prop="address" label="方法">
          </el-table-column>
          <el-table-column prop="name" label="部位">
          </el-table-column>
          <el-table-column prop="address" label="频率">
          </el-table-column>
          <el-table-column prop="name" label="影响">
          </el-table-column>
        </el-table>
      </div>
      <div class="title">饮食处方</div>
      <div class="box">
        <!-- <div class="line line1">
          <div class="item">餐次</div>
          <div class="item">食物分类</div>
          <div class="item">食物名称</div>
          <div class="item">重量(g）</div>
          <div class="item">热量(kCal) </div>
          <div class="item">碳水化合物(g)</div>
          <div class="item">脂肪(g)</div>
          <div class="item">天数</div>
        </div>
        <div class="scroll">
          <div v-for="(item, index) in 10" :key="index" class="line line2">
            <div class="item">餐次</div>
            <div class="item">食物分类</div>
            <div class="item">食物名称</div>
            <div class="item">重量(g）</div>
            <div class="item">热量(kCal) </div>
            <div class="item">碳水化合物(g)</div>
            <div class="item">脂肪(g)</div>
            <div class="item">天数</div>
          </div>
        </div> -->
        <el-table :data="tableData" height="200" border>
          <el-table-column prop="address" label="餐次">
          </el-table-column>
          <el-table-column prop="name" label="食物分类">
          </el-table-column>
          <el-table-column prop="address" label="食物名称">
          </el-table-column>
          <el-table-column prop="name" label="重量(g）">
          </el-table-column>
          <el-table-column prop="address" label="热量(kCal)">
          </el-table-column>
          <el-table-column prop="name" label="碳水化合物(g)">
          </el-table-column>
          <el-table-column prop="name" label="脂肪(g)">
          </el-table-column>
          <el-table-column prop="address" label="天数">
          </el-table-column>
        </el-table>
      </div>
    </el-form>
    <div class="bts">
      <el-button size="small" @click="closePopup">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: []
    }
  },
  methods: {
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .form {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 40px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .icon {
    position: absolute;
    top: 10px;
    left: 140px;
    width: 82px;
    height: 66px;
  }

  .tip {
    padding-left: 18px;
    margin-bottom: 10px;
    box-sizing: border-box;
    color: #000;
    font-size: 20px;
  }

  .title {
    height: 45px;
    line-height: 45px;
    border-top: 1px solid #000;
    padding-left: 10px;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 25%;
      margin-bottom: 20px;
      color: #000;
      font-size: 16px;

      .key {
        width: 76px;
        margin-right: 8px;
        font-size: 18px;
        text-align: justify;
        text-align-last: justify;
      }
    }

    .item1 {
      width: 50%;
    }
  }

  .box {
    width: 100%;
    // height: 91px;
    margin-top: 10px;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .scroll {
      overflow-y: scroll;
      max-height: 100px;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #000;
      font-size: 14px;

      .item {
        flex: 1;
        text-align: center;
      }
    }

    .line1 {
      height: 40px;
      line-height: 40px;
      background-color: #000;
      color: #fff;
    }

    .line2 {
      height: 40px;
      line-height: 40px;
    }
  }

  .bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #fff;
  }
}
</style>

<template>
  <div class="page">
    <div class="title">居民信息</div>
    <div class="info">
      <div class="item">
        <div class="key">居民信息</div>
        <div class="value">20200908900</div>
      </div>
      <div class="item item1">
        <div class="key">姓名</div>
        <div class="value">李明明</div>
      </div>
      <div class="item">
        <div class="key">头像</div>
        <img class="img" src="../../img/ssjk/user.png" alt="头像">
      </div>
      <div class="item">
        <div class="key">身份证号</div>
        <div class="value">130730199803010808</div>
      </div>

      <div class="item">
        <div class="key">联系电话</div>
        <div class="value">15803339264</div>
      </div>
      <div class="item item1">
        <div class="key">现居地</div>
        <div class="value">河北石家庄桥西区友谊街道202号</div>
      </div>
      <div class="item">
        <div class="key">登录密码</div>
        <div class="value">ha138900</div>
      </div>
      <div class="item">
        <div class="key">行政区划</div>
        <div class="value">130730199803010808</div>
      </div>

      <div class="item">
        <div class="key">居民标签</div>
        <div class="label">
          <span>高血压</span>
          <span>糖尿病</span>
        </div>
      </div>
      <div class="item item1">
        <div class="key">性别</div>
        <div class="value">女</div>
      </div>
      <div class="item">
        <div class="key">出生日期</div>
        <div class="value">1970-09-08</div>
      </div>
      <div class="item">
        <div class="key">籍贯</div>
        <div class="value">北京</div>
      </div>

      <div class="item">
        <div class="key">民族</div>
        <div class="value">汉</div>
      </div>
      <div class="item item1">
        <div class="key">文化程度</div>
        <div class="value">本科</div>
      </div>
      <div class="item">
        <div class="key">婚姻状况</div>
        <div class="value">已婚</div>
      </div>
      <div class="item">
        <div class="key">职位</div>
        <div class="value">公务员</div>
      </div>

      <div class="item">
        <div class="key">工作单位</div>
        <div class="value">无</div>
      </div>
      <div class="item item1">
        <div class="key key1">紧急联系人</div>
        <div class="value">无</div>
      </div>
      <div class="item">
        <div class="key key1">联系人电话</div>
        <div class="value">无</div>
      </div>
      <div class="item">
        <div class="key">户籍类型</div>
        <div class="value">城镇</div>
      </div>

      <div class="item">
        <div class="key">费用类型</div>
        <div class="value">自费</div>
      </div>
      <div class="item item1">
        <div class="key key2">定点医疗单位</div>
        <div class="value">怀柔区第一人民医院</div>
      </div>
      <div class="item">
        <div class="key">来源</div>
        <div class="value">用户注册</div>
      </div>
    </div>

    <div class="title">健康信息</div>
    <div class="info info1">
      <div class="item">
        <div class="key">身高</div>
        <div class="value">168cm</div>
      </div>
      <div class="item">
        <div class="key">体重</div>
        <div class="value">49kg</div>
      </div>
      <div class="item">
        <div class="key">血型</div>
        <div class="value">A性</div>
      </div>
      <div class="item">
        <div class="key">RH阴性</div>
        <div class="value">否</div>
      </div>
      <div class="item">
        <div class="key">过敏史</div>
        <div class="value">无</div>
      </div>
      <div class="item">
        <div class="key">既往史</div>
        <div class="value">无</div>
      </div>
      <div class="item">
        <div class="key">就诊史</div>
        <div class="value">无</div>
      </div>
      <div class="item">
        <div class="key">家族变史</div>
        <div class="value">无</div>
      </div>
    </div>
    <div class="family">
      <div class="title">家庭成员</div>
      <div class="news">
        <div class="key">家族变史</div>
        <div @click="openResidentDetails" class="card">
          <img class="left" src="../../img/ssjk/user.png" alt="头像">
          <div class="right">
            <span>李青</span>
            <span>62岁</span>
            <span>关系</span>
            <span>父母</span>
          </div>
        </div>
        <div class="key">+ 关联家庭成员</div>
      </div>
    </div>
    <div class="bts">
      <el-button type="primary" size="small" @click="openImproveArchives">编辑档案信息</el-button>
      <el-button size="small">取消</el-button>
      <!-- <span @click="openImproveArchives" class="update">编辑档案信息</span>
      <span>取消</span> -->
    </div>
    <ResidentDetails @closePopup="closeResidentDetails" v-if="showResidentDetails"></ResidentDetails>
    <ImproveArchives v-if="showImproveArchives" @closePopup="closeImproveArchives"></ImproveArchives>
  </div>
</template>

<script>
import ResidentDetails from '../signingRecord/residentDetailsPopup.vue'
import ImproveArchives from '../signingRecord/improveArchivesPopup.vue'
export default {
  components: {
    ResidentDetails,
    ImproveArchives
  },
  data () {
    return {
      showResidentDetails: false,
      showImproveArchives: false
    }
  },
  methods: {
    openResidentDetails () {
      this.showResidentDetails = true
    },
    closeResidentDetails () {
      this.showResidentDetails = false
    },
    openImproveArchives () {
      this.showImproveArchives = true
    },
    closeImproveArchives () {
      this.showImproveArchives = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;

  .title {
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
  }

  .info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 250px;
    margin-top: 10px;
    padding: 0 18px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 21%;
      color: #000;
      font-size: 16px;

      .key {
        letter-spacing: normal;
        width: 70px;
        margin-right: 30px;
        text-align: justify;
        text-align-last: justify;
        white-space: nowrap;
      }

      .value {
        white-space: pre;
      }

      .key1 {
        width: 90px;
      }

      .key2 {
        width: 110px;
      }

      .img {
        width: 25px;
        height: 25px;
      }

      .label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 116px;
        height: 22px;

        span {
          height: 22px;
          line-height: 22px;
          margin-right: 5px;
          padding: 0 4px;
          text-align: center;
          color: #fff;
          font-size: 16px;
          background-color: #0170C2;
          white-space: nowrap;
        }
      }
    }

    .item1 {
      width: 30%;
    }
  }

  .info1 {
    height: 60px;
  }

  .family {
    height: 120px;
    padding: 0 18px;
    box-sizing: border-box;

    .title {
      border: none;
    }

    .news {
      display: flex;
      height: 75px;

      .key {
        margin-right: 30px;
        color: #000;
        font-size: 16px;
        text-align: justify;
        text-align-last: justify;
      }

      .card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 132px;
        height: 75px;
        margin-right: 30px;
        padding: 10px;
        box-sizing: border-box;
        background: linear-gradient(104deg, #0171C4 0%, #024479 100%);
        border-radius: 4px;
        cursor: pointer;

        .left {
          width: 25px;
          height: 25px;
        }

        .right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 77px;
          height: 52px;
          color: #fff;
          font-size: 16px;
        }
      }
    }
  }

  .bts {
    position: absolute;
    left: 0;
    bottom: 40px;
    display: flex;
    align-items: center;
    height: 26px;

    span {
      height: 26px;
      line-height: 26px;
      margin-right: 10px;
      padding: 0 18px;
      color: #fff;
      font-size: 14px;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .update {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>

<template>
  <div class="popup">
    <div class="tab">
      <span class="item" :class="{ item1: tabIndex === index }" v-for="(item, index) in tabs" :key="index"
        @click="switchClick(index)">{{ item }}</span>
    </div>
    <div v-show="tabIndex === 0" class="scroll">
      <div class="title">基本信息</div>
      <div class="info">
        <div class="item">
          <div class="key">性别</div>
          <el-radio-group v-model="radio">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
            <el-radio :label="3">保密</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <div class="key">年龄</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div class="key">身高</div>
          <el-input size="small"></el-input>
          <span class="unit">cm</span>
        </div>
        <div class="item">
          <div class="key">手机号码</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div class="key">会员分组</div>
          <el-select size="small" v-model="region" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="key">主管医生</div>
          <el-select size="small" v-model="region" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="key">住址</div>
          <el-input placeholder="请输入" size="small" class="input"></el-input>
        </div>
        <div class="item">
          <div class="key">门店</div>
          <el-input size="small" placeholder="请输入"></el-input>
        </div>
        <div class="item">
          <div class="key">状态</div>
          <el-radio-group v-model="radio">
            <el-radio :label="0">正常</el-radio>
            <el-radio :label="1">注销</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div v-show="tabIndex === 1" class="scroll">
      <div class="title">基本信息</div>
      <div class="info">
        <div class="item item1">
          <div class="key">性别</div>
          <el-radio-group v-model="radio">
            <el-radio :label="0">男</el-radio>
            <el-radio :label="1">女</el-radio>
            <el-radio :label="1">保密</el-radio>
          </el-radio-group>
        </div>
        <div class="item item1">
          <div class="key">年龄</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item item1">
          <div class="key">身高</div>
          <el-input size="small"></el-input>
          <span class="unit">cm（厘米)</span>
        </div>
        <div class="item item1">
          <div class="key">体重</div>
          <el-input size="small"></el-input>
          <span class="unit">kg（公斤)</span>
        </div>
        <div class="item item1">
          <div class="key">腰围</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item item1">
          <div class="key">血型</div>
          <el-input size="small"></el-input>
        </div>
      </div>
      <div class="title">监测数据</div>
      <div class="info">
        <div class="item item1">
          <span>高压（收缩压）</span>
          <el-input size="small"></el-input>
          <span class="unit">mmHg</span>
        </div>
        <div class="item item1">
          <span>低压（舒张压）</span>
          <el-input size="small"></el-input>
          <span class="unit">mmHg</span>
        </div>
        <div class="item item1">
          <div class="key">心率</div>
          <el-input size="small"></el-input>
        </div>
      </div>
      <div class="title">生活方式与疾病信息</div>
      <div class="info">
        <div class="item">
          <div class="h6">1.您吸烟吗？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">吸烟</el-radio>
            <el-radio :label="1">不吸烟</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <div class="h6">2.您睡觉打鼾吗？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">打鼾</el-radio>
            <el-radio :label="1">不打鼾</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <div class="h6">3.您有高尿酸血症吗？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">有</el-radio>
            <el-radio :label="1">没有</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <div class="h6">4.您的直系亲属或者其他亲友是否曾经患过高血压吗？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是（父母、兄弟、姐妹或者子女）</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <div class="h6">5.您有高脂（甘油三脂）血症状吗？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">无</el-radio>
            <el-radio :label="1">有</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div v-show="tabIndex === 2" class="scroll">
      <div class="title">基本信息</div>
      <div class="info">
        <div class="item item1">
          <div class="key">性别</div>
          <el-radio-group v-model="radio">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
            <el-radio :label="3">保密</el-radio>
          </el-radio-group>
        </div>
        <div class="item item1">
          <div class="key">年龄</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item item1">
          <div class="key">身高</div>
          <el-input size="small"></el-input>
          <span class="unit">cm（厘米)</span>
        </div>
        <div class="item item1">
          <div class="key">体重</div>
          <el-input size="small"></el-input>
          <span class="unit">kg（公斤)</span>
        </div>
        <div class="item item1">
          <div class="key">腰围</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item item1">
          <div class="key">血型</div>
          <el-input size="small"></el-input>
        </div>
      </div>
      <div class="title">监测数据</div>
      <div class="info">
        <div class="item item1">
          <div class="key">血糖</div>
          <el-input size="small"></el-input>
          <span class="unit">mmHg</span>
        </div>
      </div>
      <div class="title">生活方式与疾病信息</div>
      <div class="info">
        <div class="item">
          <div class="h6">1.您是否曾经定期服用过抗高血压药物？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <div class="h6">2.您平均每周锻炼的次数？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">≤2次</el-radio>
            <el-radio :label="1">3-4次</el-radio>
            <el-radio :label="2">≥5次</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <div class="h6">3.平均每次锻炼的时间是多少分钟？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">≤20</el-radio>
            <el-radio :label="1">21-40</el-radio>
            <el-radio :label="2">41-60</el-radio>
            <el-radio :label="3">≥60</el-radio>
          </el-radio-group>
        </div>
        <div class="item item2">
          <div class="h6">4.您多久吃一次蔬菜、水果或者坚果？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">每天</el-radio>
            <el-radio :label="1">不是每天</el-radio>
          </el-radio-group>
        </div>
        <div class="item item2">
          <div class="h6">5.的直系亲属或者其他豪成是否曾经过糖尿病（1型或者2型）？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是（父母、兄弟、姐妹或子女）</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div v-show="tabIndex === 3" class="scroll">
      <div class="title">基本信息</div>
      <div class="info">
        <div class="item item1">
          <div class="key">性别</div>
          <el-radio-group v-model="radio">
            <el-radio :label="0">男</el-radio>
            <el-radio :label="1">女</el-radio>
            <el-radio :label="1">保密</el-radio>
          </el-radio-group>
        </div>
        <div class="item item1">
          <div class="key">年龄</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item item1">
          <div class="key">身高</div>
          <el-input size="small"></el-input>
          <span class="unit">cm（厘米)</span>
        </div>
        <div class="item item1">
          <div class="key">体重</div>
          <el-input size="small"></el-input>
          <span class="unit">kg（公斤)</span>
        </div>
        <div class="item item3">
          <div class="key">腰围</div>
          <el-input size="small"></el-input>
        </div>
      </div>
    </div>
    <div v-show="tabIndex === 4" class="scroll">
      <div class="title">基本信息</div>
      <div class="info">
        <div class="item item1">
          <div class="key">性别</div>
          <el-radio-group v-model="radio">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
            <el-radio :label="3">保密</el-radio>
          </el-radio-group>
        </div>
        <div class="item item1">
          <div class="key">年龄</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item item1">
          <div class="key">身高</div>
          <el-input size="small"></el-input>
          <span class="unit">cm（厘米)</span>
        </div>
        <div class="item item1">
          <div class="key">体重</div>
          <el-input size="small"></el-input>
          <span class="unit">kg（公斤)</span>
        </div>
        <div class="item item3">
          <div class="key">高血压</div>
          <el-radio-group v-model="radio">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="title">监测数据</div>
      <div class="info">
        <div class="item item1">
          <span class="key3">甘油三酯</span>
          <el-input size="small"></el-input>
          <span class="unit">mmol/L</span>
        </div>
        <div class="item item1">
          <span class="key3">总胆固醇</span>
          <el-input size="small"></el-input>
          <span class="unit">mmol/L</span>
        </div>
        <div class="item item1">
          <span class="key3">低密度脂蛋白胆固醇</span>
          <el-input size="small"></el-input>
          <span class="unit">mmol/L</span>
        </div>
        <div class="item item1">
          <span class="key3">高密度脂蛋白胆固醇</span>
          <el-input size="small"></el-input>
          <span class="unit">mmol/L</span>
        </div>
        <div class="item item1">
          <span class="key3">高压（收缩压）</span>
          <el-input size="small"></el-input>
          <span class="unit">mmHg</span>
        </div>
        <div class="item item1">
          <span class="key3">低压（舒张压）</span>
          <el-input size="small"></el-input>
          <span class="unit">mmHg</span>
        </div>
      </div>
      <div class="title">生活方式与疾病信息</div>
      <div class="info">
        <div class="item">
          <div class="h6">1.您最近1周是否吃肉？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <div class="h6">2.您吃肉种类？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">瘦肉</el-radio>
            <el-radio :label="1">肥瘦肉</el-radio>
            <el-radio :label="2">肥肉</el-radio>
            <el-radio :label="3">内脏</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <div class="h6">3.您近1周吃蛋数量？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">0~3个/周</el-radio>
            <el-radio :label="1">4~7个/周</el-radio>
            <el-radio :label="2">7个以上/周</el-radio>
          </el-radio-group>
        </div>
        <div class="item item2">
          <div class="h6">4.您近1周吃煎炸食品数量（油饼、油条、炸糕等）？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">未吃</el-radio>
            <el-radio :label="1">1~4次/周</el-radio>
            <el-radio :label="2">5~7次/周</el-radio>
            <el-radio :label="3">7个以上/周</el-radio>
          </el-radio-group>
        </div>
        <div class="item item2">
          <div class="h6">5.您近1周吃奶油糕点的次数？</div>
        </div>
        <div class="item item2">
          <el-radio-group v-model="radio">
            <el-radio :label="0">未吃</el-radio>
            <el-radio :label="1">1~4个/周</el-radio>
            <el-radio :label="2">5~7个/周</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div v-show="tabIndex === 5" class="scroll">
      <div class="title">基本信息</div>
      <div class="info">
        <div class="item item1">
          <div class="key">性别</div>
          <el-radio-group v-model="radio">
            <el-radio :label="0">男</el-radio>
            <el-radio :label="1">女</el-radio>
            <el-radio :label="2">保密</el-radio>
          </el-radio-group>
        </div>
        <div class="item item1">
          <div class="key">年龄</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item item1">
          <div class="key">身高</div>
          <el-input size="small"></el-input>
          <span class="unit">cm（厘米)</span>
        </div>
        <div class="item item1">
          <div class="key">体重</div>
          <el-input size="small"></el-input>
          <span class="unit">kg（公斤)</span>
        </div>
        <div class="item item3">
          <div class="key">腰围</div>
          <el-input size="small"></el-input>
        </div>
      </div>
      <div class="title">监测数据</div>
      <div class="info">
        <div class="item item1">
          <div class="key">甘油三酯</div>
          <el-input size="small"></el-input>
          <span class="unit">mmol/L</span>
        </div>
        <div class="item item1">
          <div class="key">血糖</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item item1">
          <span class="key3">高密度脂蛋白胆固醇</span>
          <el-input size="small"></el-input>
          <span class="unit">mmol/L</span>
        </div>
        <div class="item item1">
          <span class="key3">高压（收缩压）</span>
          <el-input size="small"></el-input>
          <span class="unit">mmHg</span>
        </div>
        <div class="item item3">
          <span class="key3">低压（舒张压）</span>
          <el-input size="small"></el-input>
          <span class="unit">mmHg</span>
        </div>
        <div class="item item1">
          <span>您是否患有糖尿病？</span>
          <el-radio-group v-model="radio">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </div>
        <div class="item item3">
          <span>您是否患有高血压？</span>
          <el-radio-group v-model="radio">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div v-show="tabIndex === 6" class="scroll">
      <SelfAssess v-if="showSelfAssess" @close="closeSelfAssess"></SelfAssess>
    </div>
    <div class="bts" v-show="tabIndex === 0">
      <el-button type="primary" size="small" @click="closePopup">保存</el-button>
      <el-button size="small" @click="closePopup">返回</el-button>
      <!-- <span class="update" @click="closePopup">保存</span>
      <span @click="closePopup">返回</span> -->
    </div>
    <div class="bts" v-show="tabIndex !== 0 && tabIndex !== 6">
      <el-button type="primary" size="small" @click="openPopup">开始评估</el-button>
      <el-button size="small" @click="closePopup">返回</el-button>
      <!-- <span class="update" @click="openPopup">开始评估</span>
      <span @click="closePopup">返回</span> -->
    </div>
    <!-- 高血压开始评估 -->
    <AssessXy v-if="showAssessXy" @close="closeAssessXy"></AssessXy>
    <!-- 糖尿病开始评估 -->
    <AssessTn v-if="showAssessTn" @close="closeAssessTn"></AssessTn>
    <!-- 肥胖开始评估 -->
    <AssessFp v-if="showAssessFp" @close="closeAssessFp"></AssessFp>
    <!-- 血脂开始评估 -->
    <AssessXz v-if="showAssessXz" @close="closeAssessXz"></AssessXz>
    <!-- 代谢开始评估 -->
    <AssessDx v-if="showAssessDx" @close="closeAssessDx"></AssessDx>
  </div>
</template>

<script>
import AssessXy from './assessXyPopup.vue'
import AssessTn from './assessTnPopup.vue'
import AssessFp from './assessFpPopup.vue'
import AssessXz from './assessXzPopup.vue'
import AssessDx from './assessDxPopup.vue'
import SelfAssess from './selfAssess.vue'
export default {
  components: {
    AssessXy,
    AssessTn,
    AssessFp,
    AssessXz,
    AssessDx,
    SelfAssess
  },
  data () {
    return {
      tabIndex: 0,
      tabs: ['基本信息', '高血压风险评估', '糖尿病风险评估', '肥胖症评估', '血脂异常评估', '代谢综合症风险评估', '自我评估'],
      radio: 0,
      region: 0,
      showAssessXy: false,
      showAssessTn: false,
      showAssessFp: false,
      showAssessXz: false,
      showAssessDx: false,
      showSelfAssess: false
    }
  },
  methods: {
    switchClick (index) {
      this.tabIndex = index
      if (index === 6) {
        this.showSelfAssess = true
      }
    },
    openPopup () {
      if (this.tabIndex === 1) {
        this.showAssessXy = true
      } else if (this.tabIndex === 2) {
        this.showAssessTn = true
      } else if (this.tabIndex === 3) {
        this.showAssessFp = true
      } else if (this.tabIndex === 4) {
        this.showAssessXz = true
      } else if (this.tabIndex === 5) {
        this.showAssessDx = true
      }
    },
    closeAssessXy () {
      this.showAssessXy = false
    },
    closeAssessTn () {
      this.showAssessTn = false
    },
    closeAssessFp () {
      this.showAssessFp = false
    },
    closeAssessXz () {
      this.showAssessXz = false
    },
    closeAssessDx () {
      this.showAssessDx = false
    },
    closeSelfAssess () {
      this.closePopup()
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 41px;

    .item {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 180px;
      height: 41px;
      line-height: 41px;
      margin-right: 20px;
      text-align: center;
      background-color: #D2D2D2;
      color: #fff;
      font-size: 18px;
      white-space: nowrap;
      cursor: pointer;
    }

    .item1 {
      background-color: #000;
    }
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 90px);
    margin-top: 10px;
    &::-webkit-scrollbar {
      width: 0;
    }

    .title {
      width: 100%;
      height: 46px;
      line-height: 46px;
      padding-left: 18px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 20px;
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 20px;
      padding-top: 18px;
      box-sizing: border-box;

      .item {
        display: flex;
        align-items: center;
        width: 25%;
        margin-bottom: 18px;
        color: #000;
        font-size: 16px;

        .key {
          width: 78px;
          padding-right: 30px;
          font-size: 18px;
          text-align-last: justify;
          white-space: nowrap;
        }

        .key1 {
          width: 90px;
        }

        .key2 {
          width: 110px;
        }

        .key3 {
          margin-right: 5px;
        }

        .img {
          width: 25px;
          height: 25px;
        }

        .el-input,
        .el-select {
          width: 151px;
        }

        ::v-deep .el-input__inner::placeholder {
          color: #252523;
        }

        .el-date-picker {
          width: 170px;
        }

        .input {
          width: 211px !important;
        }

        .input1 {
          width: 272px;
        }

        ::v-deep.el-radio__label {
          font-size: 18px;
        }

        .unit {
          margin-left: 5px;
        }
      }

      .item1 {
        width: 33%;
      }

      .item2 {
        width: 100%;
      }

      .item3 {
        width: 66.5%;
      }

      .h6 {
        color: #000;
        font-size: 16px;
      }
    }
  }

  .bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    height: 26px;
    margin-left: 18px;

    span {
      height: 26px;
      line-height: 26px;
      padding: 0 18px;
      color: #fff;
      font-size: 14px;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .update {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>

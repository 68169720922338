<template>
  <div class="page">
    <el-button type="primary" size="small" @click="openAddIntervene">新增</el-button>
    <div class="box">
      <!-- <div class="line line1">
        <div class="item">干预名称</div>
        <div class="item">评估时间</div>
        <div class="item">操作</div>
      </div>
      <div class="line line2">
        <div class="item">干预名称</div>
        <div class="item">2022-03-03  12：00：00</div>
        <div class="item item1">
          <div @click="openSeeIntervene" class="btn">查看</div>
          <div class="btn btn1">编辑</div>
          <div class="btn red">删除</div>
        </div>

      </div> -->
      <el-table :data="tableData" height="200" border>
        <el-table-column prop="address" label="干预名称">
        </el-table-column>
        <el-table-column prop="name" label="评估时间">
        </el-table-column>
        <el-table-column prop="address" label="操作" width="240">
          <el-button type="primary" size="small">查看</el-button>
          <el-button type="primary" size="small">编辑</el-button>
          <el-button type="danger" size="small">删除</el-button>
        </el-table-column>
      </el-table>
    </div>
    <el-button size="small" class="return">返回</el-button>
    <!-- 干预详情 -->
    <SeeIntervene v-if="showSeeIntervene" @closePopup="closeSeeIntervene"></SeeIntervene>
    <!-- 新增干预 -->
    <AddIntervene v-if="showAddIntervene" @closePopup="closeAddIntervene"></AddIntervene>
  </div>
</template>

<script>
import SeeIntervene from './seeIntervene.vue'
import AddIntervene from './addIntervene.vue'
export default {
  components: {
    SeeIntervene,
    AddIntervene
  },
  data () {
    return {
      tableData: [{}],
      showSeeIntervene: true,
      showAddIntervene: false
    }
  },
  methods: {
    openSeeIntervene () {
      this.showSeeIntervene = true
    },
    closeSeeIntervene () {
      this.showSeeIntervene = false
    },
    openAddIntervene () {
      this.showAddIntervene = true
    },
    closeAddIntervene () {
      this.showAddIntervene = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;

  .box {
    width: 100%;
    height: 91px;
    margin-top: 10px;

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #000;
      font-size: 14px;

      .item {
        flex: 1;
        text-align: center;
      }

      .item1 {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          color: #01EEFD;
          font-size: 14px;
          cursor: pointer;
        }

        .btn1 {
          margin-left: 30px;
          margin-right: 30px;
        }

        .red {
          color: #FF0000;
        }
      }
    }

    .line1 {
      height: 40px;
      line-height: 40px;
      background-color: #000;
      color: #fff;
    }

    .line2 {
      margin-top: 10px;
    }
  }

  .return {
    position: absolute;
    bottom: 30px;
  }
}</style>

<template>
  <div class="write">
    <div class="head">
      <span>新增随访</span>
    </div>

    <div @click="closePopup" class="close">
      <span class="el-icon-close"></span>
    </div>
    <div class="content">
      <el-form>
        <div class="title">
          <span>一般信息</span>
        </div>
        <div class="line">
          <span class="key">随访日期</span>
          <el-date-picker v-model="date" size="small" class="width1" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="line">
          <span class="key">随访方式</span>
          <el-radio-group v-model="radio">
            <el-radio label="0">门诊</el-radio>
            <el-radio label="1">家庭</el-radio>
            <el-radio label="1">电话</el-radio>
          </el-radio-group>
        </div>
        <div class="line">
          <span class="key">是否失访</span>
          <el-radio-group v-model="radio">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
          </el-radio-group>
        </div>
        <div class="line">
          <span class="key">随访类型</span>
          <el-radio-group v-model="radio">
            <el-radio label="0">正常随访</el-radio>
            <el-radio label="1">二次随访</el-radio>
          </el-radio-group>
        </div>
        <div class="line">
          <span class="key">症状</span>
          <el-input size="small"></el-input>
        </div>
        <div class="title title1">
          <span>症状</span>
        </div>
        <div class="line line4">
          <span class="key">症状</span>
          <el-input size="small"></el-input>
          <span class="icon">mmHg</span>
          <span class="icon1">/</span>
          <el-input size="small"></el-input>
          <span class="icon">mmHg</span>
        </div>
        <div class="line line3">
          <span class="key">身高</span>
          <el-input size="small"></el-input>
          <span class="icon">cm</span>
        </div>
        <div class="line line3">
          <span class="key">体重</span>
          <el-input size="small"></el-input>
          <span class="icon">kg</span>
        </div>
        <div class="line line3">
          <span class="key">心率</span>
          <el-input size="small"></el-input>
          <span class="icon">次/分钟</span>
        </div>
        <div class="line line4">
          <span class="key">症状</span>
          <el-input size="small"></el-input>
          <span class="icon1">/</span>
          <el-input size="small"></el-input>
          <span class="icon">kg/m2</span>
        </div>
        <div class="line line3">
          <span class="key">其他</span>
          <el-input size="small"></el-input>
        </div>
        <div class="title title1">
          <span>近期药物治疗情况</span>
        </div>
        <div class="line">
          <span class="key">日吸烟量</span>
          <el-input size="small"></el-input>
        </div>
        <div class="line">
          <span class="key">日饮酒量</span>
          <el-input size="small"></el-input>
        </div>
        <div class="line">
          <span class="key">每周锻炼</span>
          <el-input size="small"></el-input>
        </div>
        <div class="line">
          <span class="key key1">每次锻炼时间</span>
          <el-input size="small"></el-input>
        </div>
        <div class="line">
          <span class="key">摄盐情况</span>
          <el-radio-group v-model="radio">
            <el-radio label="0">轻</el-radio>
            <el-radio label="1">中</el-radio>
            <el-radio label="2">重</el-radio>
          </el-radio-group>
        </div>
        <div class="line">
          <span class="key">心理调整</span>
          <el-radio-group v-model="radio">
            <el-radio label="0">良好</el-radio>
            <el-radio label="1">一般</el-radio>
            <el-radio label="2">差</el-radio>
          </el-radio-group>
        </div>
        <div class="line">
          <span class="key">遵义情况</span>
          <el-radio-group v-model="radio">
            <el-radio label="0">良好</el-radio>
            <el-radio label="1">一般</el-radio>
          </el-radio-group>
        </div>
        <div class="title title1">
          <span>其他情况</span>
        </div>
        <div class="line">
          <span class="key key2">服药依从性</span>
          <el-radio-group v-model="radio">
            <el-radio label="0">规律</el-radio>
            <el-radio label="1">间断</el-radio>
            <el-radio label="2">不服药</el-radio>
          </el-radio-group>
        </div>
        <div class="line">
          <span class="key key1">药物不良翻译</span>
          <el-radio-group v-model="radio">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>
        <div class="line line4">
          <span class="key key1">此次随访分类</span>
          <el-radio-group v-model="radio">
            <el-radio label="0">控制满意</el-radio>
            <el-radio label="1">控制不满意</el-radio>
            <el-radio label="2">不良反应</el-radio>
            <el-radio label="3">并发症</el-radio>
          </el-radio-group>
        </div>
        <div class="line">
          <span class="key">转诊原因</span>
          <el-input size="small"></el-input>
        </div>
        <div class="line">
          <span class="key key1">机构及科别</span>
          <el-input size="small"></el-input>
        </div>
        <div class="line line4">
          <span class="key key1">下次随访时间</span>
          <el-date-picker v-model="date" size="small" class="width1" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="btns">
          <el-button type="primary" size="small">保存</el-button>
          <el-button type="danger" size="small">清除</el-button>
        </div>
      </el-form>
      <div class="add">
          <span>用药名称及用量</span>
          <el-button type="primary" size="small">新增</el-button>
        </div>
        <div class="table">
          <!-- <div class="tr tr1">
            <span class="td">序号</span>
            <span class="td">药物名称</span>
            <span class="td">用法</span>
            <span class="td">用量</span>
            <span class="td">单位</span>
            <span class="td">操作</span>
          </div>
          <div class="tr">
            <span class="td">00001</span>
            <span class="td">拜新同</span>
            <span class="td">每日一次</span>
            <span class="td">一次30mg</span>
            <span class="td">mg</span>
            <span class="td">
              <span class="blue">编辑</span>
              <span class="red">删除</span>
            </span>
          </div> -->
          <el-table :data="tableData" height="200" border>
            <el-table-column prop="address" label="序号">
            </el-table-column>
            <el-table-column prop="name" label="药物名称">
            </el-table-column>
            <el-table-column prop="name" label="用法">
            </el-table-column>
            <el-table-column prop="name" label="用量">
            </el-table-column>
            <el-table-column prop="name" label="单位">
            </el-table-column>
            <el-table-column prop="address" label="操作" width="180">
              <el-button type="primary" size="small">编辑</el-button>
              <el-button type="danger" size="small">删除</el-button>
            </el-table-column>
          </el-table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '0',
      date: '',
      tableData: [{}]
    }
  },
  methods: {
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .head {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 30px;
    z-index: 888;
    color: #000;
    font-size: 24px;
  }

  .content {
    position: relative;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 700px;
    margin-top: 20px;
    padding: 0 40px;
    box-sizing: border-box;
    // background-image: url('../../img/ssjk/background3.png');
    // background-size: 100% 100%;
    // background-repeat: no-repeat;

    &::-webkit-scrollbar {
      width: 0;
    }

    .content-tab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      background-color: #024276;

      .content-item {
        width: 108px;
        height: 30px;
        line-height: 30px;
        margin-left: 8px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #737373;
      }

      .content-item1 {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }

    .el-form {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px 10px;
      box-sizing: border-box;

      .title {
        width: 100%;
        color: #000;
        font-size: 20px;
      }

      .line {
        display: flex;
        align-items: center;
        width: 25%;
        margin-top: 20px;
        padding-left: 10px;
        box-sizing: border-box;
        color: #000;
        font-size: 18px;

        .key {
          width: 76px;
          text-align-last: justify;
          margin-right: 8px;
        }

        .key1 {
          width: 120px;
        }

        .key2 {
          width: 100px;
        }

        // .el-radio {
        //   margin-right: 10px;
        //   color: #fff;
        //   font-size: 18px;
        // }

        // /deep/.el-radio__input.is-checked+.el-radio__label {
        //   color: #fff;
        // }

        // /deep/.el-radio__inner {
        //   width: 18px;
        //   height: 18px;
        //   background-color: transparent;
        //   border-color: #FFFFFF;
        // }

        // /deep/.el-radio__input.is-checked .el-radio__inner {
        //   width: 18px;
        //   height: 18px;
        //   border-color: #000;
        //   background-color: #000;
        // }

        // /deep/.el-radio__inner::after {
        //   content: '✓';
        //   width: 18px;
        //   height: 18px;
        //   line-height: 18px;
        //   text-align: center;
        //   font-size: 10px;
        //   background-color: transparent;
        // }

        .el-input {
          width: 100px;
        }

        /deep/.el-input__inner {
          border-radius: 0;
        }

        .width {
          width: 50px;
        }

        .width1 {
          width: 130px;
        }

        .icon {
          margin-left: 10px;
        }

        .icon1 {
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      .line1 {
        width: 82%;
      }

      .line2 {
        width: 21%;
      }

      .line3 {
        width: 23%;
      }

      .line4 {
        width: 40%;
      }

      .line5 {
        width: 26%;
      }

      .title1 {
        margin-top: 20px;
      }

      .btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 200px;
        height: 40px;
        margin: 40px auto 20px;
      }
    }
  }

  .add {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 46px;
    margin-bottom: 10px;
    color: #000;
    font-size: 16px;
  }

  .table {
    display: flex;
    flex-direction: column;
    width: 100%;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      color: #fff;
      font-size: 14px;

      .td {
        flex: 1;
        text-align: center;
      }

      .blue {
        color: #000;
      }

      .red {
        margin-left: 30px;
        color: #FF0000;
      }
    }

    .tr1 {
      height: 40px;
      background-color: #024276;
    }
  }
}
</style>

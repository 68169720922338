<template>
  <div class="page">
    <el-button type="primary" @click="openAddReport" size="small">新增</el-button>
    <div class="box">
      <!-- <div class="line line1">
        <div class="item">报告名称</div>
        <div class="item">评估时间</div>
        <div class="item">操作</div>
      </div>
      <div class="line line2">
        <div class="item">健康管理评估报告</div>
        <div class="item">2022-03-03 12：00：00</div>
        <div class="item item1">
          <div @click="openSeePopup" class="btn">查看</div>
          <div class="btn btn1">编辑</div>
          <div class="btn red">删除</div>
        </div>
      </div> -->
      <el-table :data="tableData" height="300" border>
          <el-table-column prop="date" label="报告名称">
          </el-table-column>
          <el-table-column prop="name" label="评估时间">
          </el-table-column>
          <el-table-column prop="address" label="操作" width="240">
            <el-button type="primary" size="small" @click="openSeePopup">查看</el-button>
            <el-button type="primary" size="small">编辑</el-button>
            <el-button type="danger" size="small">删除</el-button>
          </el-table-column>
        </el-table>
    </div>
    <el-button size="small" class="return">返回</el-button>
    <!-- 新增报告 -->
    <AddReport v-if="showAddReport" @closePopup="closeAddReport"></AddReport>
    <SeePopup v-if="showSeePopup" @closePopup="closeSeePopup"></SeePopup>
  </div>
</template>

<script>
import AddReport from './addReport.vue'
import SeePopup from './seePopup'
export default {
  components: {
    AddReport,
    SeePopup
  },
  data () {
    return {
      tableData: [{}],
      showAddReport: false,
      showSeePopup: false
    }
  },
  methods: {
    openAddReport () {
      this.showAddReport = true
    },
    closeAddReport () {
      this.showAddReport = false
    },
    openSeePopup () {
      this.showSeePopup = true
    },
    closeSeePopup () {
      this.showSeePopup = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;

  .box {
    width: 100%;
    height: 91px;
    margin-top: 10px;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #000;
      font-size: 14px;

      .item {
        flex: 1;
        text-align: center;
      }

      .item1 {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          color: #000;
          font-size: 14px;
          cursor: pointer;
        }

        .btn1 {
          margin-left: 30px;
          margin-right: 30px;
        }

        .red {
          color: #FF0000;
        }
      }
    }

    .line1 {
      height: 40px;
      line-height: 40px;
      background-color: #000;
      color: #fff;
    }

    .line2 {
      margin-top: 10px;
    }
  }

  .return {
    position: absolute;
    bottom: 0;
  }
}</style>

<template>
  <div class="popup">
    <el-form ref="form">
      <div class="tip">新增处方</div>
      <div class="title">居民信息</div>
      <div class="info">
        <div class="item">
          <div class="key">姓名</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div class="key">性别</div>
          <el-select size="small" v-model="region" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="key">年龄</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div class="key">联系电话</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div class="key">会员分组</div>
          <el-select size="small" v-model="region" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="key">主管医生</div>
          <el-select size="small" v-model="region" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="key">现居地</div>
          <el-input placeholder="请输入" size="small" class="input"></el-input>
        </div>
        <div class="item">
          <div class="key">门店</div>
          <el-input size="small" placeholder="请输入"></el-input>
        </div>
        <div class="item">
          <div class="key">状态</div>
          <el-radio-group v-model="radio">
            <el-radio :label="0">正常</el-radio>
            <el-radio :label="1">注销</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="title title1">新增处方</div>
      <div class="tab">
        <span @click="switchTab(index)" class="sort" :class="{ sort1: index == tabIndex }" v-for="(item, index) in list" :key="index" >{{ item }}</span>
      </div>
      <div class="bts">
        <el-button type="primary" size="small" @click="closePopup">保存</el-button>
        <el-button size="small" @click="closePopup">返回</el-button>
      </div>
    </el-form>
    <!-- 营养处方 -->
    <Nutrition v-if="showNutrition" @closePopup="closeNutrition"></Nutrition>
    <!-- 理疗处方 -->
    <Physio v-if="showPhysio" @closePopup="closePhysio"></Physio>
    <!-- 饮食处方 -->
    <Diet v-if="showDiet" @closePopup="closeDiet"></Diet>
    <!-- 运动处方 -->
    <Motion v-if="showMotion" @closePopup="closeMotion"></Motion>
    <!-- 心理处方 -->
    <Psychology v-if="showPsychology" @closePopup="closePsychology"></Psychology>
  </div>
</template>

<script>
import Nutrition from './nutritionPopup.vue'
import Physio from './physioPopup.vue'
import Diet from './dietPopup.vue'
import Motion from './motionPopup.vue'
import Psychology from './psychologyPopup.vue'
export default {
  components: {
    Nutrition,
    Physio,
    Diet,
    Motion,
    Psychology
  },
  data () {
    return {
      region: '',
      radio: 0,
      tabIndex: -1,
      list: ['营养处方', '理疗处方', '饮食处方', '运动处方', '心理处方'],
      showNutrition: false,
      showPhysio: false,
      showDiet: false,
      showMotion: false,
      showPsychology: false
    }
  },
  methods: {
    switchTab (index) {
      if (index === 0) {
        this.showNutrition = true
      } else if (index === 1) {
        this.showPhysio = true
      } else if (index === 2) {
        this.showDiet = true
      } else if (index === 3) {
        this.showMotion = true
      } else if (index === 4) {
        this.showPsychology = true
      }
      this.tabIndex = index
    },
    openNutrition () {
      this.showNutrition = true
    },
    closeNutrition () {
      this.showNutrition = false
    },
    closePhysio () {
      this.showPhysio = false
    },
    closeDiet () {
      this.showDiet = false
    },
    closeMotion () {
      this.showMotion = false
    },
    closePsychology () {
      this.showPsychology = false
    },
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .tip {
    margin-bottom: 10px;
    padding-left: 18px;
    box-sizing: border-box;
    color: #000;
    font-size: 20px;
  }

  .title {
    height: 45px;
    line-height: 45px;
    padding-left: 10px;
    border-top: 1px solid #000;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
  }

  .title1 {
    border: none;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 25%;
      margin-bottom: 20px;
      color: #000;
      font-size: 16px;

      .key {
        width: 78px;
        padding-right: 20px;
        font-size: 18px;
        text-align-last: justify;
        white-space: nowrap;
      }

      .key1 {
        width: 90px;
      }

      .key2 {
        width: 110px;
      }

      .img {
        width: 25px;
        height: 25px;
      }

      .el-input,
      .el-select {
        width: 151px;
      }

      ::v-deep .el-input__inner::placeholder {
        color: #252523;
      }

      .el-date-picker {
        width: 170px;
      }

      .input {
        width: 211px !important;
      }

      .input1 {
        width: 272px;
      }
    }
  }

  .tab {
    display: flex;
    align-items: center;
    height: 41px;
    margin-left: 10px;

    .sort {
      width: 176px;
      height: 41px;
      line-height: 41px;
      margin-right: 20px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      background-color: #D2D2D2;
      cursor: pointer;
    }

    .sort1 {
      background-color: #000;
    }
  }

  .bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 200px;
    height: 40px;

    span {
      height: 26px;
      line-height: 26px;
      padding: 0 18px;
      color: #fff;
      font-size: 14px;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .update {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>

<template>
  <div class="popup">
    <div class="tip">居民详情</div>
    <el-button type="primary" size="small" class="send" @click="showChat = true">发消息</el-button>
    <div class="tab">
      <div class="sort" :class="{ sort1: tabIndex == index }" v-for="(item, index) in tabList" :key="index"
        @click="switchTab(index)">{{ item }}</div>
    </div>
    <div class="chilre">
      <HealthArchives @openImproveArchives="openImproveArchives" @closePopup="closePopup" v-if="tabIndex == 0">
      </HealthArchives>
      <HealthRecords @closePopup="closePopup" v-else-if="tabIndex == 1"></HealthRecords>
      <SigningInfo @closePopup="closePopup" v-else-if="tabIndex == 2"></SigningInfo>
      <ServiceRecords @closePopup="closePopup" v-else-if="tabIndex == 3"></ServiceRecords>
    </div>
    <ImproveArchives v-if="showImproveArchives" @closePopup="closeImproveArchives"></ImproveArchives>
    <Chat v-if="showChat" @closePopup="closeChat"></Chat>
  </div>
</template>

<script>
import HealthArchives from './residentDetailsPopup1.vue'
import HealthRecords from './residentDetailsPopup2.vue'
import SigningInfo from './residentDetailsPopup3.vue'
import ServiceRecords from './residentDetailsPopup4.vue'
import ImproveArchives from './improveArchivesPopup'
import Chat from './chat'
export default {
  components: {
    HealthArchives,
    HealthRecords,
    SigningInfo,
    ServiceRecords,
    ImproveArchives,
    Chat
  },
  data () {
    return {
      tabIndex: 0,
      tabList: ['健康档案', '健康记录', '签约信息', '服务记录'],
      showImproveArchives: false,
      showChat: false
    }
  },
  methods: {
    switchTab (index) {
      this.tabIndex = index
    },

    openImproveArchives () {
      this.showImproveArchives = true
    },
    closeImproveArchives () {
      this.showImproveArchives = false
    },
    closePopup () {
      this.$emit('closePopup')
    },
    closeChat () {
      this.showChat = false
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .send {
    position: absolute;
    right: 60px;
    top: 20px;
  }

  .tip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 94px;
    text-align: center;
    color: #000;
    font-size: 20px;
  }

  .tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 588px;
    height: 41px;
    margin-top: 10px;

    .sort {
      width: 132px;
      height: 41px;
      line-height: 41px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      background-color: #D2D2D2;
      cursor: pointer;
    }

    .sort1 {
      background-color: #409EFF;
    }
  }

  .tip::before {
    content: '';
    width: 4px;
    height: 18px;
    background-color: #366FF7;
    border-radius: 2px;
  }

  .chilre {
    overflow-y: scroll;
    height: calc(100% - 80px);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .bts {
    position: absolute;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 240px;
    height: 26px;
    margin-top: 130px;
    margin-left: 18px;

    span {
      height: 26px;
      line-height: 26px;
      padding: 0 18px;
      color: #fff;
      font-size: 14px;
      background-color: #D5D5D5;
    }

    .update {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>

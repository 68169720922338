<template>
  <div class="page">
    <el-button @click="openAddRrescription" type="primary" size="small">新增</el-button>
    <div class="box">
      <!-- <div class="line line1">
        <div class="item">处方名称</div>
        <div class="item">评估时间</div>
        <div class="item">操作</div>
      </div>
      <div class="line line2">
        <div class="item">处方名称</div>
        <div class="item">2022-03-03 12：00：00</div>
        <div class="item item1">
          <div @click="openRrescriptionInfo" class="btn">查看</div>
          <div class="btn btn1">编辑</div>
          <div class="btn red">删除</div>
        </div>
      </div> -->
      <el-table :data="tableData" height="100%" border>
        <el-table-column prop="address" label="处方名称">
        </el-table-column>
        <el-table-column prop="name" label="评估时间">
        </el-table-column>
        <el-table-column prop="name" label="操作" width="230">
          <el-button type="primary" size="small" @click="openRrescriptionInfo">查看</el-button>
          <el-button type="primary" size="small">编辑</el-button>
          <el-button type="danger" size="small">删除</el-button>
        </el-table-column>
      </el-table>
    </div>
    <el-button size="small" class="return">返回</el-button>
    <!-- 处方详情 -->
    <RrescriptionInfo v-if="showRrescriptionInfo" @closePopup="closeRrescriptionInfo"></RrescriptionInfo>
    <!-- 新增处方 -->
    <AddRrescription v-if="showAddRrescription" @closePopup="closeAddRrescription"></AddRrescription>
  </div>
</template>

<script>
import RrescriptionInfo from './prescriptionInfo.vue'
import AddRrescription from './addRrescription.vue'
export default {
  components: {
    RrescriptionInfo,
    AddRrescription
  },
  data () {
    return {
      tableData: [{}],
      showRrescriptionInfo: false,
      showAddRrescription: false
    }
  },
  methods: {
    openRrescriptionInfo () {
      this.showRrescriptionInfo = true
    },
    closeRrescriptionInfo () {
      this.showRrescriptionInfo = false
    },
    openAddRrescription () {
      this.showAddRrescription = true
    },
    closeAddRrescription () {
      this.showAddRrescription = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;

  .box {
    width: 100%;
    height: 140px;
    margin-top: 10px;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #fff;
      font-size: 14px;

      .item {
        flex: 1;
        text-align: center;
      }

      .item1 {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          color: #000;
          font-size: 14px;
          cursor: pointer;
        }

        .btn1 {
          margin-left: 30px;
          margin-right: 30px;
        }

        .red {
          color: #FF0000;
        }
      }
    }

    .line1 {
      height: 40px;
      line-height: 40px;
      background-color: #000;
    }

    .line2 {
      margin-top: 10px;
    }
  }

  .return {
    position: absolute;
    bottom: 30px;
  }
}
</style>

<!-- 实时监控 -->
<template>
  <div class='ssjkContainer'>
    <div class="ssjkContainer-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="ssjkContainer-right">
      <div class="top">
        <div class="topTab">
          <div class="item" @click="switchTab(item.value)" :class="[currentIndex == item.value ? 'active' : 'unactive']"
            v-for="(item, index) in ssjkTabLst" :key="index">
            {{ item.label }}
          </div>
        </div>
      </div>
      <div class="tab-item">
        <!-- <keep-alive>
          <router-view></router-view>
        </keep-alive> -->
        <router-view></router-view>
      </div>
    </div>
    <BasicInfoPopup v-if="showBasicInfoPopup" @close="closeBasicInfoPopup"></BasicInfoPopup>
  </div>
</template>
<script>
import BasicInfoPopup from './basicInfoPopup.vue'
import UserBasicInfo from '../../../components/user-basic-info/user-basic-info.vue'
export default {
  components: {
    BasicInfoPopup,
    UserBasicInfo
  },
  data () {
    return {
      currentIndex: 1,
      ssjkTabLst: [
        {
          label: '基本详情',
          value: 1
        },
        {
          label: '健康档案',
          value: 2
        },
        {
          label: '处方管理',
          value: 3
        },
        {
          label: '健康干预',
          value: 4
        },
        {
          label: '健康随访',
          value: 5
        },
        {
          label: '健康评估',
          value: 6
        },
        {
          label: '健康监测',
          value: 7
        },
        {
          label: '健康报告',
          value: 8
        },
        {
          label: '签约记录',
          value: 9
        }
      ],
      moveTop: null,
      showBasicInfoPopup: true
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    switchTab (index) {
      const ulrs = ['/basicInfo', '/healtharchives', '/prescriptionrun', '/healthintervene', '/healthvisit', '/healthassess', '/healthmonitoring', '/healthreport', '/signingrecord']
      this.currentIndex = index
      this.$router.push('/smartelderlycare' + ulrs[index - 1])
      window.sessionStorage.setItem('realTimeMonitoringIndex', index)
    },
    openBasicInfoPopup () {
      this.showBasicInfoPopup = true
    },
    closeBasicInfoPopup () {
      this.showBasicInfoPopup = false
    }
  },
  created () {
    this.currentIndex = Number.parseInt(window.sessionStorage.getItem('realTimeMonitoringIndex')) || 1
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.ssjkContainer {
  position: relative;
  width: calc(100% - 10px);
  height: 100%;
  display: flex;
  justify-content: space-between;

  &-left {
    width: 15%;
    min-width: 190px;
    height: 100%;

    .title {
      width: 100%;
      height: 35px;
      line-height: 35px;
      padding-left: 34px;
      padding-top: 5px;
      box-sizing: border-box;
      background: url('../img/dialogTitleBg.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
    }

    .scroll {
      overflow: scroll;
      width: 100%;
      height: 752px;
      margin-top: 5px;
      border-radius: 6px;
      background-color: rgba(29, 64, 112, .33);

      .list {
        display: flex;
        align-items: center;
        width: 610px;
        height: 50px;
        color: #01EEFD;
        font-size: 16px;
        cursor: pointer;

        div {
          width: 60px;
          text-align: center;
        }

        .flex2 {
          width: 100px;
        }

        .flex3 {
          width: 150px;
        }

        .flex4 {
          width: 300px;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .color {
        position: sticky;
        top: 0;
        left: 0;
        padding: 4px 0;
        z-index: 88;
        background-color: rgba(14, 40, 75, 1);
      }

      .movetop {
        // animation: moveani var(--time) infinite linear normal;
      }

      @keyframes moveani {
        0% {
          transform: translateY(0);
        }

        100% {
          transform: translateY(var(--moveTop));
        }
      }

      .movetop:hover {
        animation-play-state: paused;
      }
    }
  }

  &-right {
    width: 84%;
    // min-width: 1360px;
    height: 100%;
    overflow-y: auto;

    .top {
      // position: absolute;
      height: 70px;
      background-color: #fff;

      .topTab {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;

        .item {
          width: 120px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          position: relative;
          font-size: 18px;
          cursor: pointer;
        }

        .active {
          background-color: #409EFF;
        }

        .unactive {
          background-color: #adafb1;
        }
      }
    }

    .tab-item {
      width: 99.5%;
      height: 90%;
      padding: 20px;
      background-color: #fff;
      box-sizing: border-box;
      // background: linear-gradient(114deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
      // background-color: #092B4F;
      // border: 4px solid rgba(1, 124, 219, .27);

      &::-webkit-scrollbar {
        display: none;
      }
    }

  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>

<template>
  <div class="popup">
    <el-form ref="form">
      <div class="tip">新增报告</div>
      <div class="title">居民信息</div>
      <div class="info">
        <div class="item">
          <div class="key">姓名</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div class="key">性别</div>
          <el-select size="small" v-model="region" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="key">年龄</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div class="key">联系电话</div>
          <el-input size="small"></el-input>
        </div>
        <div class="item">
          <div class="key">会员分组</div>
          <el-select size="small" v-model="region" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="key">主管医生</div>
          <el-select size="small" v-model="region" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </div>
        <div class="item">
          <div class="key">现居地</div>
          <el-input placeholder="请输入" size="small" class="input"></el-input>
        </div>
        <div class="item">
          <div class="key">门店</div>
          <el-input size="small" placeholder="请输入"></el-input>
        </div>
        <div class="item">
          <div class="key">状态</div>
          <el-radio-group v-model="radio">
            <el-radio :label="0">正常</el-radio>
            <el-radio :label="1">注销</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="family">
        <div class="news">
          <div class="key">上传报告</div>
          <div class="card">
            <span class="el-icon-plus"></span>
            <span>上传报告</span>
          </div>
        </div>
      </div>

      <div class="bts">
        <!-- <span class="update">保存</span>
        <span class="update" @click="closePopup">返回</span> -->

        <el-button type="primary" size="small" @click="closePopup">保存</el-button>
        <el-button size="small" @click="closePopup">返回</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      region: '',
      radio: 0
    }
  },
  methods: {
    openInfo () {
      this.$emit('openResidentDetails')
    },
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .tip {
    margin-bottom: 10px;
    color: #000;
    font-size: 20px;
  }

  .title {
    height: 45px;
    line-height: 45px;
    border-top: 1px solid #000;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 25%;
      margin-bottom: 20px;
      color: #000;
      font-size: 16px;

      .key {
        width: 78px;
        margin-right: 30px;
        font-size: 18px;
        text-align: justify;
        text-align-last: justify;
      }

      .key1 {
        width: 90px;
      }

      .key2 {
        width: 110px;
      }

      .img {
        width: 25px;
        height: 25px;
      }

      .el-input,
      .el-select {
        width: 151px;
      }

      ::v-deep .el-input__inner::placeholder {
        color: #252523;
      }

      .el-date-picker {
        width: 170px;
      }

      .input {
        width: 211px !important;
      }

      .input1 {
        width: 272px;
      }

      ::v-deep.el-radio__label {
        font-size: 18px;
      }
    }
  }

  .info1 {
    height: 60px;
  }

  .family {
    height: 90px;
    padding: 0 20px;
    box-sizing: border-box;

    .title {
      border: none;
    }

    .news {
      display: flex;
      height: 75px;

      .key {
        width: 78px;
        margin-right: 30px;
        color: #000;
        font-size: 18px;
        text-align: justify;
        text-align-last: justify;
      }

      .card {
        width: 157px;
        height: 77px;
        line-height: 77px;
        text-align: center;
        background-color: #FAFAFA;
        color: #B5B5B5;
        font-size: 14px;
      }
    }

    .news1 {
      flex-direction: column;
      justify-content: center;

      .key {
        width: 72px;
      }
    }
  }

  .bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 40px;
  }
}
</style>

<template>
  <div class="page">
    <div class="search">
      <div class="sort">
        <span>签约状态:</span>
        <el-select v-model="value" size="small" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="sort">
        <span>签约机构:</span>
        <el-select v-model="value" size="small" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="sort">
        <span>医生团队:</span>
        <el-select v-model="value" size="small" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="sort">
        <span>服务包:</span>
        <el-select v-model="value" size="small" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="sort sort1">
        <span>选择日期:</span>
        <el-date-picker v-model="date" size="small" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="sort sort1">
        <el-select v-model="value" size="small" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <el-button type="primary" size="small"  @click="showPopup">新增</el-button>
    <el-table ref="multipleTable" border :data="tableData" style="width: 100%" @selection-change="handleSelectionChange"
      height="356px">
      <el-table-column align="center" type="selection">
      </el-table-column>
      <el-table-column align="center" prop="id" label="编号">
      </el-table-column>
      <el-table-column align="center" prop="name" label="签约人姓名">
      </el-table-column>
      <el-table-column align="center" prop="idcard" label="身份证号" width="190">
      </el-table-column>
      <el-table-column align="center" prop="phone" label="手机号码">
      </el-table-column>
      <el-table-column align="center" label="签约状态">
        <template slot-scope="scope">
          <span class="red">{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="doctor" label="签约医生团队">
      </el-table-column>
      <el-table-column align="center" prop="service" label="签约服务包">
      </el-table-column>
      <el-table-column align="center" prop="date" label="到期时间" width="190">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <span @click="openContractDetails" class="el-icon-view"></span>
        <span @click="openUpdateSigning" class="font">续约</span>
      </el-table-column>
    </el-table>
    <el-button size="small" class="return">返回</el-button>
    <AddSigning v-if="showAddSigning" @openResidentDetails="openResidentDetails" @closePopup="closePopup"></AddSigning>
    <ResidentDetails @closePopup="closeResidentDetails" v-if="showResidentDetails"></ResidentDetails>
    <ContractDetails v-if="showContractDetails" @closePopup="closeContractDetails"></ContractDetails>
    <UpdateSigning v-if="showUpdateSigning" @closePopup="closeUpdateSigning"></UpdateSigning>
  </div>
</template>

<script>
import AddSigning from './addSigningPopup.vue'
import ResidentDetails from './residentDetailsPopup.vue'
import ContractDetails from './contractDetails.vue'
import UpdateSigning from './updateSigningPopup'
export default {
  components: {
    AddSigning,
    ResidentDetails,
    ContractDetails,
    UpdateSigning
  },
  data () {
    return {
      tableData: [{
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }],
      multipleSelection: [],
      date: '',
      options: [{
        value: '1',
        label: '111'
      }, {
        value: '2',
        label: '222'
      }],
      value: '',
      showAddSigning: false,
      showResidentDetails: false,
      showContractDetails: false,
      showUpdateSigning: false
    }
  },
  methods: {
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    tableRowClass () {
      return 'tableRowClass'
    },
    showPopup () {
      this.showAddSigning = true
    },
    closePopup () {
      this.showAddSigning = false
    },
    openResidentDetails () {
      this.showResidentDetails = true
    },
    closeResidentDetails () {
      this.showResidentDetails = false
    },
    openContractDetails () {
      this.showContractDetails = true
    },
    closeContractDetails () {
      this.showContractDetails = false
    },
    openUpdateSigning () {
      this.showUpdateSigning = true
    },
    closeUpdateSigning () {
      this.showUpdateSigning = false
    }
  },
  mounted () {
    // setTimeout(() => { debugger }, 3000)
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;

  .search {
    width: 100%;
    height: 122px;
    padding: 10px;
    margin-bottom: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    .sort {
      display: flex;
      align-items: center;
      width: 25%;
      height: 38px;
      color: #000;
      font-size: 16px;

      span {
        margin-right: 10px;
      }
    }

    .sort1 {
      width: 50%;
    }
  }

  .el-table {
    width: 100%;
    height: 456px;
    margin-top: 20px;
    background-color: transparent;
    color: #000;

    .el-icon-view {
      cursor: pointer;
      color: #0077F9 !important;
    }

    .font {
      cursor: pointer;
      margin-left: 20px;
      color: #0077F9;
      font-size: 15px;
    }

    ::v-deep.blue {
      color: #0077F9 !important;
    }

    ::v-deep.red {
      color: #FF0000 !important;
    }

    .el-table-column--selection .cell {
      padding-right: 10px;
    }

  }

  .return {
    position: absolute;
    bottom: 30px;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"scroll"},_vm._l((10),function(item,index){return _c('div',{key:index,staticClass:"list"},[_c('div',{staticClass:"title"},[_vm._m(0,true),_c('div',{staticClass:"right"},[(index == 0)?_c('img',{staticClass:"clock",attrs:{"src":require("../../img/ssjk/clock.png"),"alt":"闹钟"}}):_vm._e(),_c('div',{staticClass:"status",class:{ orange: index == 0 }},[_vm._v(_vm._s(index > 0 ? '已完成' : '待服务'))])])]),_c('div',{staticClass:"info"},[_vm._m(1,true),_c('div',{staticClass:"item"},[_c('div',{staticClass:"key"},[_vm._v("服务状态")]),_c('div',{staticClass:"value",class:{ orange: index == 0 }},[_vm._v(_vm._s(index > 0 ? '已完成' : '待服务'))])]),_vm._m(2,true),_vm._m(3,true),_vm._m(4,true),_vm._m(5,true),_vm._m(6,true),_vm._m(7,true),_vm._m(8,true),_vm._m(9,true),_vm._m(10,true),_vm._m(11,true),_vm._m(12,true),_vm._m(13,true)])])}),0),_c('div',{staticClass:"bts"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.closePopup}},[_vm._v("返回")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('span',[_vm._v("服务信息")]),_c('span',{staticClass:"update"},[_vm._v("编辑")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"key"},[_vm._v("服务编号")]),_c('div',{staticClass:"value"},[_vm._v("2091900120622")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"key"},[_vm._v("服务机构")]),_c('div',{staticClass:"value"},[_vm._v("桥西区社区服务中心")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"key"},[_vm._v("服务医生")]),_c('div',{staticClass:"value"},[_vm._v("李明明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"key"},[_vm._v("服务包")]),_c('div',{staticClass:"value"},[_vm._v("老年人服务包")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"key"},[_vm._v("服务项目")]),_c('div',{staticClass:"value"},[_vm._v("高血压随访（剩余2次）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"key"},[_vm._v("服务地点")]),_c('div',{staticClass:"value"},[_vm._v("机构门诊")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"key"},[_vm._v("提交时间")]),_c('div',{staticClass:"value purple"},[_vm._v("2022/03/22 10:00:00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"key"},[_vm._v("审核时间")]),_c('div',{staticClass:"value purple"},[_vm._v("2022/03/22 10:00:00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"key"},[_vm._v("服务时间")]),_c('div',{staticClass:"value purple"},[_vm._v("2022/03/22 10:00:00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"key"},[_vm._v("服务团队")]),_c('div',{staticClass:"value"},[_vm._v("李明明团队")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"key"},[_vm._v("服务来源")]),_c('div',{staticClass:"value"},[_vm._v("居民申请")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"key"},[_vm._v("审核人")]),_c('div',{staticClass:"value"},[_vm._v("李明明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item item1"},[_c('div',{staticClass:"key"},[_vm._v("服务备注")]),_c('div',{staticClass:"value"},[_vm._v("上次的药已经吃完了！")])])
}]

export { render, staticRenderFns }
<template>
  <div class="box">
    <div class="scroll">
      <div class="list" v-for="(item, index) in 10" :key="index">
        <div class="title">
          <div class="left">
            <span>服务信息</span>
            <span class="update">编辑</span>
          </div>
          <div class="right">
            <img v-if="index == 0" class="clock" src="../../img/ssjk/clock.png" alt="闹钟">
            <div class="status" :class="{ orange: index == 0 }">{{ index > 0 ? '已完成' : '待服务' }}</div>
          </div>
        </div>
        <div class="info">
          <div class="item">
            <div class="key">服务编号</div>
            <div class="value">2091900120622</div>
          </div>
          <div class="item">
            <div class="key">服务状态</div>
            <div class="value" :class="{ orange: index == 0 }">{{ index > 0 ? '已完成' : '待服务' }}</div>
          </div>
          <div class="item">
            <div class="key">服务机构</div>
            <div class="value">桥西区社区服务中心</div>
          </div>

          <div class="item">
            <div class="key">服务医生</div>
            <div class="value">李明明</div>
          </div>
          <div class="item">
            <div class="key">服务包</div>
            <div class="value">老年人服务包</div>
          </div>
          <div class="item">
            <div class="key">服务项目</div>
            <div class="value">高血压随访（剩余2次）</div>
          </div>

          <div class="item">
            <div class="key">服务地点</div>
            <div class="value">机构门诊</div>
          </div>
          <div class="item">
            <div class="key">提交时间</div>
            <div class="value purple">2022/03/22 10:00:00</div>
          </div>
          <div class="item">
            <div class="key">审核时间</div>
            <div class="value purple">2022/03/22 10:00:00</div>
          </div>

          <div class="item">
            <div class="key">服务时间</div>
            <div class="value purple">2022/03/22 10:00:00</div>
          </div>
          <div class="item">
            <div class="key">服务团队</div>
            <div class="value">李明明团队</div>
          </div>
          <div class="item">
            <div class="key">服务来源</div>
            <div class="value">居民申请</div>
          </div>

          <div class="item">
            <div class="key">审核人</div>
            <div class="value">李明明</div>
          </div>
          <div class="item item1">
            <div class="key">服务备注</div>
            <div class="value">上次的药已经吃完了！</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bts">
      <el-button size="small" @click="closePopup">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }],
      multipleSelection: []
    }
  },
  methods: {
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    tableRowClass () {
      return 'tableRowClass'
    },
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  position: relative;
  width: 100%;
  margin-top: 10px;
  height: calc(100% - 20px);
  border-top: 1px solid #000;
  box-sizing: border-box;

  .scroll {
    overflow-y: scroll;
    width: 90%;
    height: calc(100% - 40px);

    &::-webkit-scrollbar {
      display: none;
    }

    .list {
      height: 274px;
      margin-top: 20px;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        font-size: 20px;

        .update {
          margin-left: 14px;
          color: #0077F9;
          font-size: 16px;
        }

        .right {
          display: flex;
          align-items: center;

          .clock {
            width: 21px;
            height: 21px;
          }

          .status {
            width: 92px;
            height: 35px;
            line-height: 35px;
            margin-left: 10px;
            text-align: center;
            color: #fff;
            font-size: 18px;
            background-color: #00BB7E;
            border-top-right-radius: 6px;
          }

          .orange {
            background-color: #FF7600;
          }
        }
      }

      .info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        height: 205px;

        .item {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          align-items: center;
          width: 32%;
          color: #000;

          .key {
            width: 77px;
            text-align-last: justify;
            font-size: 18px;
          }

          .value {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: 10px;
            font-size: 16px;
          }

          .orange {
            color: #FF7600;
          }

          .purple {
            color: #3676F6;
          }
        }

        .item1 {
          width: 66%;
        }
      }
    }
  }

  .bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  }
}
</style>

<template>
  <div class="popup">
    <div class="form">
      <el-form ref="form">
        <div class="tip">干预详情</div>
        <div class="title">基本信息</div>
        <div class="info">
          <div class="item">
            <div class="key">姓名</div>
            <span>李明明</span>
          </div>
          <div class="item">
            <div class="key">性别</div>
            <span>男</span>
          </div>
          <div class="item">
            <div class="key">年龄</div>
            <span>40</span>
          </div>
          <div class="item">
            <div class="key">疾病史</div>
            <span>无</span>
          </div>
          <div class="item">
            <div class="key">遗传史</div>
            <span>无</span>
          </div>
          <div class="item">
            <div class="key">家族史</div>
            <span>无</span>
          </div>
          <div class="item">
            <div class="key">血压</div>
            <span>120/80/mmHg</span>
          </div>
          <div class="item">
            <div class="key">血糖</div>
            <span>8.3mmol/L</span>
          </div>
          <div class="item">
            <div class="key">吸烟</div>
            <span>不吸烟</span>
          </div>
          <div class="item">
            <div class="key">饮酒</div>
            <span>适量</span>
          </div>
          <div class="item">
            <div class="key">运动</div>
            <span>适量</span>
          </div>
          <div class="item">
            <div class="key">评估结果</div>
            <span>血压正常，血糖偏高</span>
          </div>
        </div>
        <div class="title">综合处方</div>
        <div class="box">
          <el-table :data="tableData" height="200" border>
            <el-table-column prop="address" label="干预类型">
            </el-table-column>
            <el-table-column prop="name" label="干预措施">
            </el-table-column>
            <el-table-column prop="name" label="用法">
            </el-table-column>
            <el-table-column prop="name" label="频次">
            </el-table-column>
            <el-table-column prop="name" label="用量">
            </el-table-column>
            <el-table-column prop="name" label="开始时间">
            </el-table-column>
            <el-table-column prop="name" label="结束时间">
            </el-table-column>
            <el-table-column prop="name" label="是否检测项">
            </el-table-column>
            <el-table-column prop="name" label="备注">
            </el-table-column>
          </el-table>
          <!-- <div class="line line1">
            <div class="item">干预类型</div>
            <div class="item">干预措施</div>
            <div class="item">用法</div>
            <div class="item">频次</div>
            <div class="item">用量</div>
            <div class="item">开始时间</div>
            <div class="item">结束时间</div>
            <div class="item">是否检测项</div>
            <div class="item">备注</div>
          </div>
          <div class="scroll">
            <div v-for="(item, index) in 10" :key="index" class="line line2">
              <div class="item">糖尿病干预方案</div>
              <div class="item">适量饮水，健康饮食</div>
              <div class="item">无</div>
              <div class="item">长期</div>
              <div class="item">每天20分钟</div>
              <div class="item">2022-12-18</div>
              <div class="item">2022-12-18</div>
              <div class="item">否</div>
              <div class="item">无</div>
            </div>
          </div> -->
        </div>
        <div class="title title1">饮食处方</div>
        <div class="box">
          <el-table :data="tableData" height="200" border>
            <el-table-column prop="address" label="餐次">
            </el-table-column>
            <el-table-column prop="name" label="食物分类">
            </el-table-column>
            <el-table-column prop="address" label="食物名称">
            </el-table-column>
            <el-table-column prop="name" label="重量(g）">
            </el-table-column>
            <el-table-column prop="address" label="热量(kCal)">
            </el-table-column>
            <el-table-column prop="name" label="碳水化合物(g)">
            </el-table-column>
            <el-table-column prop="name" label="脂肪(g)">
            </el-table-column>
            <el-table-column prop="address" label="天数">
            </el-table-column>
          </el-table>
          <!-- <div class="line line1">
            <div class="item">餐次</div>
            <div class="item">食物分类</div>
            <div class="item">食物名称</div>
            <div class="item">重量(g）</div>
            <div class="item">热量(kCal) </div>
            <div class="item">碳水化合物(g)</div>
            <div class="item">脂肪(g)</div>
            <div class="item">天数</div>
          </div>
          <div class="scroll">
            <div v-for="(item, index) in 10" :key="index" class="line line2">
              <div class="item">餐次</div>
              <div class="item">食物分类</div>
              <div class="item">食物名称</div>
              <div class="item">重量(g）</div>
              <div class="item">热量(kCal) </div>
              <div class="item">碳水化合物(g)</div>
              <div class="item">脂肪(g)</div>
              <div class="item">天数</div>
            </div>
          </div> -->
        </div>
        <div class="title title1">运动处方</div>
        <div class="box">
          <el-table :data="tableData" height="200" border>
            <el-table-column prop="address" label="时间">
            </el-table-column>
            <el-table-column prop="name" label="运动项目">
            </el-table-column>
            <el-table-column prop="address" label="运动类型">
            </el-table-column>
            <el-table-column prop="name" label="单位时间(分钟)">
            </el-table-column>
            <el-table-column prop="address" label="能量消耗(kcal)">
            </el-table-column>
          </el-table>
          <!-- <div class="line line1">
            <div class="item">时间</div>
            <div class="item">运动项目</div>
            <div class="item">运动类型</div>
            <div class="item">单位时间(分钟)</div>
            <div class="item">能量消耗(kcal)</div>
          </div>
          <div class="scroll">
            <div v-for="(item, index) in 10" :key="index" class="line line2">
              <div class="item">时间</div>
              <div class="item">运动项目</div>
              <div class="item">运动类型</div>
              <div class="item">单位时间(分钟)</div>
              <div class="item">能量消耗(kcal)</div>
            </div>
          </div> -->
        </div>
        <div class="title title1">心理处方</div>
        <div class="font">无</div>

      </el-form>
    </div>
    <div class="bts">
      <el-button size="small" @click="closePopup">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{}]
    }
  },
  methods: {
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .form {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .icon {
    position: absolute;
    top: 10px;
    left: 140px;
    width: 82px;
    height: 66px;
  }

  .tip {
    padding-left: 18px;
    margin-bottom: 10px;
    box-sizing: border-box;
    color: #000;
    font-size: 20px;
  }

  .title {
    height: 45px;
    line-height: 45px;
    border-top: 1px solid #000;
    padding-left: 10px;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
  }

  .title1 {
    border: none;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 25%;
      margin-bottom: 20px;
      color: #000;
      font-size: 16px;

      .key {
        width: 76px;
        padding-right: 10px;
        font-size: 18px;
        text-align-last: justify;
        white-space: nowrap;
      }
    }

    .item1 {
      width: 50%;
    }
  }

  .box {
    width: 100%;
    // height: 91px;
    margin-top: 10px;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .scroll {
      overflow-y: scroll;
      max-height: 100px;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #000;
      font-size: 14px;

      .item {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
    }

    .line1 {
      height: 40px;
      line-height: 40px;
      background-color: #000;
      color: #fff;
    }

    .line2 {
      height: 40px;
      line-height: 40px;
    }
  }

  .font {
    margin-left: 50px;
    color: #000;
    font-size: 12px;
  }

  .bts {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 40px;
  }
}
</style>

import { render, staticRenderFns } from "./queryXl.vue?vue&type=template&id=66836c35&scoped=true&"
import script from "./queryXl.vue?vue&type=script&lang=js&"
export * from "./queryXl.vue?vue&type=script&lang=js&"
import style0 from "./queryXl.vue?vue&type=style&index=0&id=66836c35&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66836c35",
  null
  
)

export default component.exports
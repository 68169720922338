<template>
  <div class="popup1">
    <div class="title">
      <span>查询</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="flex">
        <div class="item">
          <div class="key">检测日期</div>
          <el-date-picker type="date" size="small"  placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>
      <div class="submit">
        <el-button type="primary">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.popup1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: calc(100% - 20px);
    height: 350px;
    margin: 0 auto;
    margin-top: 10px;
    padding: 50px 60px;
    background-color: #fff;
    // background-image: url('../../img/ssjk/writeImg.png');
    // background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 22px;
      right: 30px;
      color: #000;
      font-size: 24px;
      cursor: pointer;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        color: #000;
        font-size: 18px;
      }

      .item1 {
        width: 62%;
      }

      .el-input {
        width: 293px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .submit {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
</style>

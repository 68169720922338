<template>
  <div class="monitoring">
    <div @click="close" class="close">
      <span class="el-icon-close"></span>
    </div>
    <div class="monitor-top">
      <div class="left">
        <div class="title">您的体温结果为：</div>
        <div class="title title1">本次测量结果</div>
        <div class="title">体温：36.0 ℃</div>
        <div class="title">测量时间：2021-06-29 08:29:26</div>
        <div class="title title2">评估建议</div>
        <div class="title">您本次测量的体温正常。请注意身体健康</div>
      </div>
      <div class="right">
        <div class="title">信息图</div>
        <div class="unit">
          <span>体温</span>
          <span class="span">36℃</span>
          <span>37℃</span>
          <span class="span1">38℃</span>
          <span>39℃</span>
          <span class="span2">41℃</span>
        </div>
        <div class="chart"></div>
        <div class="tip">
          <div class="line">
            <div class="div"></div>
            <span class="font">超高热</span>
          </div>
          <div class="line">
            <div class="div font2"></div>
            <span class="font">高热</span>
          </div>
          <div class="line">
            <div class="div font6"></div>
            <span class="font">中度发热</span>
          </div>
          <div class="line">
            <div class="div font4"></div>
            <span class="font">低热</span>
          </div>
          <div class="line">
            <div class="div font3"></div>
            <span class="font">正常</span>
          </div>
          <div class="line">
            <div class="div font5"></div>
            <span class="font">低温</span>
          </div>
        </div>
      </div>
    </div>
    <div class="news">
      <div class="news-left">
        <div class="tab">
          <span>体温趋势</span>
        </div>
        <div class="echarts" id="echarts"></div>
      </div>
      <div class="news-right right1">
        <div class="tab">
          <div>体温记录</div>
          <div class="btns">
            <!-- <div @click="openWrite" class="btn">输入体温</div>
            <div @click="openQuery" class="btn">查询</div> -->
            <el-button type="primary" size="small" @click="openWrite">输入体温</el-button>
            <el-button type="primary" size="small" @click="openQuery">查询</el-button>
          </div>
        </div>
        <div class="table">
          <!-- <div class="head">
            <div class="flex flex1">测量时间</div>
            <div class="flex">数值（℃)</div>
            <div class="flex flex2">评估</div>
            <div class="flex flex5">操作</div>
          </div>
          <div class="scroll">
            <div class="line">
              <div class="flex flex1">2021/05/09 10:45</div>
              <div class="flex">4.2</div>
              <div class="flex flex2 red">偏高</div>
              <div class="flex flex3 flex5">
                <div>编辑</div>
                <div class="color">删除</div>
              </div>
            </div>
          </div> -->
          <el-table :data="tableData" height="100%" border>
            <el-table-column prop="date" label="测量时间">
            </el-table-column>
            <el-table-column prop="name" label="数值（℃)">
            </el-table-column>
            <el-table-column prop="address" label="评估">
            </el-table-column>
            <el-table-column prop="address" label="操作" width="160">
              <el-button type="primary" size="small">编辑</el-button>
              <el-button type="danger" size="small">删除</el-button>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 体温输入 -->
    <WriteTw v-if="showWriteTw" @closePopup="closeWrite"></WriteTw>
    <!-- 体温查询 -->
    <QueryTw v-if="showQueryTw" @closePopup="closeQuery"></QueryTw>
  </div>
</template>

<script>
import WriteTw from './writeTw.vue'
import QueryTw from './queryTw.vue'
export default {
  components: {
    WriteTw,
    QueryTw
  },
  data () {
    return {
      myChart: null,
      data: '',
      tableData: [{}],
      showWriteTw: false,
      showQueryTw: false
    }
  },
  mounted () {
    this.canvas()
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      this.myChart.resize()
    },
    openWrite () {
      this.showWriteTw = true
    },
    closeWrite () {
      this.showWriteTw = false
    },
    openQuery () {
      this.showQueryTw = true
    },
    closeQuery () {
      this.showQueryTw = false
    },
    close () {
      this.$emit('close')
    },
    canvas () {
      // console.log('--------------------')
      const chartDom = document.getElementById('echarts')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#000',
              fontSize: 15
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '单位：℃',
          nameTextStyle: {
            color: '#000',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#000',
              fontSize: 15
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#000']
            }
          }
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            itemStyle: {
              color: '#88F8FF', // 改变折线点的颜色
              lineStyle: {
                color: '#88F8FF' // 改变折线颜色
              }
            },
            label: {
              show: true,
              textStyle: {
                color: '#000'
              }
            }
          }
        ]
      }

      option && this.myChart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.monitoring {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 10px 20px;
  border: 1px solid #000;
  box-sizing: border-box;

  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #000;
    font-size: 20px;
    cursor: pointer;
  }

  .monitor-top {
    display: flex;
    align-items: center;
    width: 100%;
    height: 340px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .left,
    .right {
      height: 320px;
    }

    .left {
      flex: 5;
      height: 322px;
      padding: 34px;
      box-sizing: border-box;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 18px;

      .title1 {
        margin-top: 16px;
      }

      .title2 {
        margin-top: 34px;
      }
    }

    .right {
      flex: 4;
      height: 352px;
      padding: 11px 0;
      padding-left: 63px;
      box-sizing: border-box;
      color: #000;
      font-size: 18px;

      .unit {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 8px;
        color: #000;
        font-size: 16px;

        .span {
          margin-left: 37px;
          margin-right: 17px;
        }

        .span1 {
          margin: 0 20px;
        }

        .span2 {
          margin-left: 84px;
        }
      }

      .chart {
        display: flex;
        align-items: center;
        width: 443px;
        height: 113px;
        background-image: url('../../img/ssjk/chart7.png');
        background-size: contain;
      }

      .tip {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        width: 443px;
        margin-top: 30px;

        .line {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 70px;

          .div {
            width: 16px;
            height: 16px;
            margin-bottom: 6px;
            background-color: #E62E29;
          }

          .font {
            color: #000;
            font-size: 16px;
          }

          .font1 {
            background-color: #EF5450;
          }

          .font2 {
            background-color: #F27744;
          }

          .font3 {
            background-color: #1CC434;
          }

          .font4 {
            background-color: #93FF92;
          }

          .font5 {
            background-color: #29EBC9;
          }

          .font6 {
            background-color: #FFC78E;
          }
        }
      }
    }
  }

  .news {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 99%;
    height: calc(100% - 340px);

    .news-left,
    .news-right {
      width: 40%;
      height: 100%;
      padding-right: 17px;
      box-sizing: border-box;

      .echarts {
        width: 100%;
        height: 100%;
      }
    }

    .news-right {
      width: 60%;
    }

    .tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000;
      height: 26px;
      margin-top: 10px;
      font-size: 18px;

      .btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 162px;
        height: 26px;
        color: #FFFFFF;
        font-size: 14px;

        .btn {
          cursor: pointer;
          height: 26px;
          line-height: 26px;
          padding: 0 7px;
          text-align: center;
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }

    .table {
      width: 100%;
      height: calc(100% - 40px);
      margin-top: 10px;

      /deep/.el-table__cell {
        text-align: center;
        color: #000;
      }

      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        background-color: #0000;
        color: #fff;
        font-size: 14px;
      }

      .flex {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
        height: 40px;
        line-height: 40px;
        text-align: center;
      }

      .flex1 {
        width: 142px;
      }

      .flex2 {
        width: 40px;
      }

      .flex3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .flex4 {
        width: 220px;
      }

      .flex5 {
        width: 76px;
      }

      .scroll {
        width: 100%;
        height: calc(100% - 40px);

        .line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          color: #000;
          font-size: 14px;

          .color {
            cursor: pointer;
            color: #FF0000;
            font-size: 14px;
          }
        }

        .line2 {
          justify-content: center;
        }

        .line3,
        .line2 {
          color: #fff;
        }
      }
    }
  }
}
</style>

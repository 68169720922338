<template>
  <div class="box">
    <div class="title">签约信息</div>
    <div class="info">
      <div class="item">
        <div class="key">服务编号</div>
        <div class="value">20200908900</div>
      </div>
      <div class="item">
        <div class="key">服务状态</div>
        <div class="value green">待审核</div>
      </div>
      <div class="item">
        <div class="key">签约机构</div>
        <div class="value">桥西区社区服务中心</div>
      </div>

      <div class="item">
        <div class="key">签约团队</div>
        <div class="value">李明明团队</div>
      </div>
      <div class="item">
        <div class="key">签约医生</div>
        <div class="value">李明明</div>
      </div>
      <div class="item">
        <div class="key">服务包</div>
        <div class="value">老年人服务包</div>
      </div>
      <div class="item">
        <div class="key">签约周期</div>
        <div class="value">1年</div>
      </div>
      <div class="item">
        <div class="key">服务来源</div>
        <div class="value">居民申请</div>
      </div>
      <div class="item">
        <div class="key">签约类型</div>
        <div class="value">首次签约</div>
      </div>
      <div class="item">
        <div class="key">申请时间</div>
        <div class="value purple">2022/03/22 10:00:00</div>
      </div>
      <div class="item">
        <div class="key">生效日期</div>
        <div class="value purple">2022/03/22 10:00:00</div>
      </div>
      <div class="item item1">
        <div class="key">签约备注</div>
        <div class="value">上次的药已经吃完了！</div>
      </div>
    </div>

    <div class="title">签约记录</div>
    <div class="table">
      <el-table ref="multipleTable" border :data="tableData" height="230">
        <el-table-column align="center" prop="id" label="编号">
        </el-table-column>
        <el-table-column align="center" prop="name" label="签约机构">
        </el-table-column>
        <el-table-column align="center" prop="name" label="签约医生团队">
        </el-table-column>
        <el-table-column align="center" prop="name" label="签约医生">
        </el-table-column>
        <el-table-column align="center" prop="doctor" label="服务包">
        </el-table-column>
        <el-table-column align="center" prop="date" label="申请时间" width="190">
        </el-table-column>
        <el-table-column align="center" prop="date" label="到期时间" width="190">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <el-button type="primary" size="small">查看</el-button>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination :current-page="1" background :page-sizes="[100, 200, 300, 400]" :page-size="50"
        layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
      </div>
    </div>

    <div class="bts">
      <el-button size="small" @click="closePopup">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }, {
        id: '2021340001',
        name: '王小虎',
        idcard: '130730200003020812',
        phone: '15803339266',
        status: '生效中',
        doctor: '李明明团队',
        service: '老年人服务包',
        date: '2022/03/02 18:00:00'
      }],
      multipleSelection: []
    }
  },
  methods: {
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    tableRowClass () {
      return 'tableRowClass'
    },
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  position: relative;
  width: 100%;
  height: calc(100% - 10px);

  .title {
    height: 45px;
    line-height: 45px;
    margin-top: 10px;
    border-top: 1px solid #000;
    box-sizing: border-box;
    color: #000;
    font-size: 18;
  }

  .info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 18px;
    box-sizing: border-box;

    .item {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;
      width: 25%;
      height: 40px;
      color: #000;
      font-size: 16px;
      box-sizing: border-box;

      .key {
        width: 70px;
        margin-right: 30px;
        text-align: justify;
        text-align-last: justify;
      }

      .value {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .green {
        color: #0BBB0B;
      }

      .purple {
        color: #3676F6;
      }

      .key1 {
        width: 90px;
      }

      .key2 {
        width: 110px;
      }

      .img {
        width: 25px;
        height: 25px;
      }

      .label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 116px;
        height: 22px;

        span {
          width: 51px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          color: #fff;
          font-size: 16px;
          background-color: #0170C2;
        }
      }
    }

    .item1 {
      width: 80%;
    }
  }

  .table {
    .blue {
      color: rgb(64, 158, 255);
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
  }

  .bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  }
}
</style>
